import React from 'react';
import { Email, Item, Span, renderEmail } from 'react-html-email';

const css = `
@media only screen {
body {font-family: Helvetica,serif
font-size: 12px;
}}`.trim();

const textStyles = {
  fontSize: 12,
  fontFamily: 'Helvetica,serif'
};

const ItemRow = ({ label, children }) => {
  return (
    <tr>
      <td>
        <Span {...textStyles}>{label}</Span>
      </td>
      <td>
        <Span {...textStyles}>{children}</Span>
      </td>
    </tr>
  );
};

const EmailHTML = (data) => {
  const emailBody = renderEmail(
    <Email title="Plan Submission" headCSS={css}>
      <Item align="center">
        <p>
          <Span {...textStyles}>New Order Submission</Span>
        </p>
      </Item>
      <Item>
        <table>
          <tbody>
            <ItemRow label="Contact">{data.contact}</ItemRow>
            <ItemRow label="Phone">{data.phone}</ItemRow>
            <ItemRow label="Email">{data.email}</ItemRow>
            <ItemRow label="Canadian Address">{data.address}</ItemRow>
            <ItemRow label="Insurance">{data.insuranceType}</ItemRow>
            <ItemRow label="Coverage">{data.coverageType}</ItemRow>
            <ItemRow label="PlanId">{data.planId}</ItemRow>
            <ItemRow label="Plan">{data.planName}</ItemRow>
            <ItemRow label="Deductible">{data.deductible}</ItemRow>
            <ItemRow label="Applicant Name (1)">{data.applicantName1}</ItemRow>
            <ItemRow label="Age (1)">{data.age1}</ItemRow>
            <ItemRow label="DOB (1)">{data.dob1}</ItemRow>
            <ItemRow label="Coverage (1)">{data.coverage1}</ItemRow>
            <ItemRow label="Coverage for (1)">{data.days1}days</ItemRow>
            <ItemRow label="Start date (1)">{data.startDate1}</ItemRow>
            <ItemRow label="End date (1)">{data.endDate1}</ItemRow>
            <ItemRow label="Pre-existing conditions (1)">
              {data.havePreConditions1 === 'true'
                ? 'coverage required'
                : 'coverage NOT required'}
            </ItemRow>
            <ItemRow label="Applicant Name (2)">{data.applicantName2}</ItemRow>
            <ItemRow label="Age (2)">{data.age2}</ItemRow>
            <ItemRow label="DOB (2)">{data.dob2}</ItemRow>
            <ItemRow label="Coverage (2)">{data.coverage2}</ItemRow>
            <ItemRow label="Coverage for (2)">{data.days2}days</ItemRow>
            <ItemRow label="Start date (2)">{data.startDate2}</ItemRow>
            <ItemRow label="End date (2)">{data.endDate2}</ItemRow>
            <ItemRow label=" Pre-existing conditions (2)">
              {data.havePreConditions2 === 'true'
                ? 'coverage required'
                : 'coverage NOT required'}
            </ItemRow>
          </tbody>
        </table>
      </Item>
    </Email>
  );

  return emailBody;
};

export default EmailHTML;
