import React, { useState, useEffect } from 'react';

const RadioGroup = ({ error: hasError, defaultValue, onSelect, children }) => {
  const [selectedValue, setSelectedValue] = useState('');
  const [error, setError] = useState(false);

  useEffect(() => {
    setSelectedValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    setError(hasError);
  }, [hasError]);

  const handleSelect = (value) => {
    setSelectedValue(value);
    onSelect(value);
  };

  return <>{children({ selectedValue, handleSelect, error })}</>;
};

export default RadioGroup;
