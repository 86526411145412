import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';

const OrderConfirmation = () => {
  const [contact, setContact] = useState(
    JSON.parse(window.localStorage.getItem('contact')) || {
      name: '',
      email: '',
      phone: ''
    }
  );

  return (
    <>
      <Typography variant="h2" gutterBottom>
        Thank you!
      </Typography>
      <Typography variant="body1" gutterBottom>
        Dear {contact && contact.name}{' '}
      </Typography>
      <Typography variant="body1" gutterBottom>
        We have received your Insurance policy request.
        <br />
        We will get in touch with you soon.
      </Typography>
    </>
  );
};

export default OrderConfirmation;
