import { handleResponse, handleError } from './apiUtils';

const baseUrl = process.env.REACT_APP_LIFE_CI_API_URL;

export function getLifeCIQuote(params) {
  return fetch(
    `${baseUrl}?dc= -qt0 -ccca -pc${
      process.env.REACT_APP_LCI_API_AC
    } -rt0 ${encodeURIComponent(params)} -fmt -skiplegend -langen -ofjson`
  )
    .then(handleResponse)
    .catch(handleError);
}

/*
-qt
The type of Quote being performed. Depending on the level of service you subscribe to we offer different quote types.

0 Single Life : Independent Life
1 Single Life : Additional Life
2 Joint Life : First to Die
3 Joint Life : Last to Die 1st Life
4 Joint Life : Last to Die 2nd Life
5 MultiLife®

-cc countryCode

-pc countryCode + accountId
* */

/*
-rt
The WinQuote® Service provides several types of results depending on the Report Type value.
In addition, some other parameters may need to be provided to further filter these reports.
Depending on the level of service you subscribe to we offer different Report Types.

0 The Rank Survey lists qualifying Products from Companies sorted by Premium. This report returns the necessary tags to request the other reports described below.
1 The Detail Renewal Schedule can be requested from a Rank Survey item. This report contains the Renewal Schedule for a Rank Survey item. For this Report Type, parameters '-fn', 'fp' & '-fyq' must be provided from the Rank Survey item 'recordSetHeading' tag values.
2 The Comparison Detail Renewal Schedule can be requested from a Rank Survey item. This report contains the collection of Renewal Schedules for Rank Survey items. For this Report Type, parameters '-fn', 'fp' & '-fyq' must be provided from the Rank Survey item 'recordSetHeading' tag values in a ':' (colon) seperated list for each parameter.
3 The Medical (Underwriting) Report can be requested from a Rank Survey item. For this Report Type, parameters '-fn', 'fp' & '-fyq' must be provided from the Rank Survey item 'recordSetHeading' tag values.
P Plan Description
C Company Description
* */
