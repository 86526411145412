import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { fade, IconButton } from '@material-ui/core';
import {
  HelpOutline as HelpIcon,
  Cancel as CancelIcon
} from '@material-ui/icons';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  active: {
    display: 'block'
  },
  inActive: {
    display: 'none'
  },
  btn: {
    position: 'absolute',
    right: 0,
    top: theme.spacing(1)
  },
  content: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    backgroundColor: fade(theme.palette.primary.light, 0.3),
    color: theme.palette.black
  }
}));

const Toggler = ({ children }) => {
  const classes = useStyles();
  const [isActive, setIsActive] = useState(false);

  const handleActivate = () => {
    setIsActive(!isActive);
  };

  return (
    <>
      <IconButton
        aria-label="Help"
        tabIndex="100"
        className={clsx(classes.btn, 'toggleBtn')}
        onClick={handleActivate}>
        {isActive ? <CancelIcon /> : <HelpIcon />}
      </IconButton>
      <div
        className={clsx(
          classes.content,
          isActive ? classes.active : classes.inActive
        )}>
        {children}
      </div>
    </>
  );
};

export default Toggler;
