export const STORAGE_URL = process.env.REACT_APP_STORAGE_URL;
export const DATE_FORMAT = process.env.REACT_APP_DATE_FORMAT;
export const CLIENT_PHONE = '+1-647-640-2222';
export const DEDUCTIBLE_TYPES = ['claim', 'policy'];
export const AGE_CALCULATION_TYPES = ['EFFECTIVE_DATE', 'APPLICATION_DATE'];
export const NO_OF_DEPENDENTS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
export const INSURANCE_TYPES = ['super', 'visitor'];
export const COVERAGES_VISITOR = [
  {
    value: 10000,
    label: '10,000'
  },
  {
    value: 15000,
    label: '15,000'
  },
  {
    value: 20000,
    label: '20,000'
  },
  {
    value: 25000,
    label: '25,000'
  },
  {
    value: 30000,
    label: '30,000'
  },
  {
    value: 50000,
    label: '50,000'
  },
  {
    value: 100000,
    label: '100,000'
  },
  {
    value: 150000,
    label: '150,000'
  },
  {
    value: 200000,
    label: '200,000'
  },
  {
    value: 250000,
    label: '250,000'
  },
  {
    value: 300000,
    label: '300,000'
  },
  {
    value: 500000,
    label: '500,000',
    labelSm: '500K'
  },
  {
    value: 1000000,
    label: '1,000,000',
    labelSm: '1 million'
  }
];

export const COVERAGES_SUPER = [
  {
    value: 100000,
    label: '100,000',
    labelSm: '100K'
  },
  {
    value: 150000,
    label: '150,000',
    labelSm: '150K'
  },
  {
    value: 200000,
    label: '200,000',
    labelSm: '200K'
  },
  {
    value: 250000,
    label: '250,000',
    labelSm: '250K'
  },
  {
    value: 300000,
    label: '300,000',
    labelSm: '300K'
  },
  {
    value: 500000,
    label: '500,000',
    labelSm: '500K'
  },
  {
    value: 1000000,
    label: '1,000,000',
    labelSm: '1 million'
  }
];

export const COVERAGES_STUDENT_INDEX = {
  1000000: '1 Million',
  2000000: '2 Million',
  5000000: '5 Million',
  10000000: '10 Million'
};

export const DEDUCTIBLES = [0, 100, 250, 500, 1000, 2500, 5000, 10000];

export const NO_OF_CHILDREN = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

export const PROVINCES = [
  {
    value: 'BC',
    label: 'British Columbia'
  },
  {
    value: 'ON',
    label: 'Ontario'
  },
  {
    value: 'NF',
    label: 'Newfoundland'
  },
  {
    value: 'NS',
    label: 'Nova Scotia'
  },
  {
    value: 'PE',
    label: 'Prince Edward Island'
  },
  {
    value: 'NB',
    label: 'New Brunswick'
  },
  {
    value: 'QC',
    label: 'Quebec'
  },
  {
    value: 'MB',
    label: 'Manitoba'
  },
  {
    value: 'SK',
    label: 'Saskatchewan'
  },
  {
    value: 'AB',
    label: 'Alberta'
  },
  {
    value: 'NT',
    label: 'Northwest Territories'
  },
  {
    value: 'NU',
    label: 'Nunavut'
  },
  {
    value: 'YT',
    label: 'Yukon Territory'
  }
];

export const COUNTRIES = [
  {
    label: 'Afghanistan',
    value: 'AF'
  },
  {
    label: 'Åland Islands',
    value: 'AX'
  },
  {
    label: 'Albania',
    value: 'AL'
  },
  {
    label: 'Algeria',
    value: 'DZ'
  },
  {
    label: 'American Samoa',
    value: 'AS'
  },
  {
    label: 'AndorrA',
    value: 'AD'
  },
  {
    label: 'Angola',
    value: 'AO'
  },
  {
    label: 'Anguilla',
    value: 'AI'
  },
  {
    label: 'Antarctica',
    value: 'AQ'
  },
  {
    label: 'Antigua and Barbuda',
    value: 'AG'
  },
  {
    label: 'Argentina',
    value: 'AR'
  },
  {
    label: 'Armenia',
    value: 'AM'
  },
  {
    label: 'Aruba',
    value: 'AW'
  },
  {
    label: 'Australia',
    value: 'AU'
  },
  {
    label: 'Austria',
    value: 'AT'
  },
  {
    label: 'Azerbaijan',
    value: 'AZ'
  },
  {
    label: 'Bahamas',
    value: 'BS'
  },
  {
    label: 'Bahrain',
    value: 'BH'
  },
  {
    label: 'Bangladesh',
    value: 'BD'
  },
  {
    label: 'Barbados',
    value: 'BB'
  },
  {
    label: 'Belarus',
    value: 'BY'
  },
  {
    label: 'Belgium',
    value: 'BE'
  },
  {
    label: 'Belize',
    value: 'BZ'
  },
  {
    label: 'Benin',
    value: 'BJ'
  },
  {
    label: 'Bermuda',
    value: 'BM'
  },
  {
    label: 'Bhutan',
    value: 'BT'
  },
  {
    label: 'Bolivia',
    value: 'BO'
  },
  {
    label: 'Bosnia and Herzegovina',
    value: 'BA'
  },
  {
    label: 'Botswana',
    value: 'BW'
  },
  {
    label: 'Bouvet Island',
    value: 'BV'
  },
  {
    label: 'Brazil',
    value: 'BR'
  },
  {
    label: 'British Indian Ocean Territory',
    value: 'IO'
  },
  {
    label: 'Brunei Darussalam',
    value: 'BN'
  },
  {
    label: 'Bulgaria',
    value: 'BG'
  },
  {
    label: 'Burkina Faso',
    value: 'BF'
  },
  {
    label: 'Burundi',
    value: 'BI'
  },
  {
    label: 'Cambodia',
    value: 'KH'
  },
  {
    label: 'Cameroon',
    value: 'CM'
  },
  {
    label: 'Canada',
    value: 'CA'
  },
  {
    label: 'Cape Verde',
    value: 'CV'
  },
  {
    label: 'Cayman Islands',
    value: 'KY'
  },
  {
    label: 'Central African Republic',
    value: 'CF'
  },
  {
    label: 'Chad',
    value: 'TD'
  },
  {
    label: 'Chile',
    value: 'CL'
  },
  {
    label: 'China',
    value: 'CN'
  },
  {
    label: 'Christmas Island',
    value: 'CX'
  },
  {
    label: 'Cocos (Keeling) Islands',
    value: 'CC'
  },
  {
    label: 'Colombia',
    value: 'CO'
  },
  {
    label: 'Comoros',
    value: 'KM'
  },
  {
    label: 'Congo',
    value: 'CG'
  },
  {
    label: 'Congo, The Democratic Republic of the',
    value: 'CD'
  },
  {
    label: 'Cook Islands',
    value: 'CK'
  },
  {
    label: 'Costa Rica',
    value: 'CR'
  },
  {
    label: 'Cote D"Ivoire',
    value: 'CI'
  },
  {
    label: 'Croatia',
    value: 'HR'
  },
  {
    label: 'Cuba',
    value: 'CU'
  },
  {
    label: 'Cyprus',
    value: 'CY'
  },
  {
    label: 'Czech Republic',
    value: 'CZ'
  },
  {
    label: 'Denmark',
    value: 'DK'
  },
  {
    label: 'Djibouti',
    value: 'DJ'
  },
  {
    label: 'Dominica',
    value: 'DM'
  },
  {
    label: 'Dominican Republic',
    value: 'DO'
  },
  {
    label: 'Ecuador',
    value: 'EC'
  },
  {
    label: 'Egypt',
    value: 'EG'
  },
  {
    label: 'El Salvador',
    value: 'SV'
  },
  {
    label: 'Equatorial Guinea',
    value: 'GQ'
  },
  {
    label: 'Eritrea',
    value: 'ER'
  },
  {
    label: 'Estonia',
    value: 'EE'
  },
  {
    label: 'Ethiopia',
    value: 'ET'
  },
  {
    label: 'Falkland Islands (Malvinas)',
    value: 'FK'
  },
  {
    label: 'Faroe Islands',
    value: 'FO'
  },
  {
    label: 'Fiji',
    value: 'FJ'
  },
  {
    label: 'Finland',
    value: 'FI'
  },
  {
    label: 'France',
    value: 'FR'
  },
  {
    label: 'French Guiana',
    value: 'GF'
  },
  {
    label: 'French Polynesia',
    value: 'PF'
  },
  {
    label: 'French Southern Territories',
    value: 'TF'
  },
  {
    label: 'Gabon',
    value: 'GA'
  },
  {
    label: 'Gambia',
    value: 'GM'
  },
  {
    label: 'Georgia',
    value: 'GE'
  },
  {
    label: 'Germany',
    value: 'DE'
  },
  {
    label: 'Ghana',
    value: 'GH'
  },
  {
    label: 'Gibraltar',
    value: 'GI'
  },
  {
    label: 'Greece',
    value: 'GR'
  },
  {
    label: 'Greenland',
    value: 'GL'
  },
  {
    label: 'Grenada',
    value: 'GD'
  },
  {
    label: 'Guadeloupe',
    value: 'GP'
  },
  {
    label: 'Guam',
    value: 'GU'
  },
  {
    label: 'Guatemala',
    value: 'GT'
  },
  {
    label: 'Guernsey',
    value: 'GG'
  },
  {
    label: 'Guinea',
    value: 'GN'
  },
  {
    label: 'Guinea-Bissau',
    value: 'GW'
  },
  {
    label: 'Guyana',
    value: 'GY'
  },
  {
    label: 'Haiti',
    value: 'HT'
  },
  {
    label: 'Heard Island and Mcdonald Islands',
    value: 'HM'
  },
  {
    label: 'Holy See (Vatican City State)',
    value: 'VA'
  },
  {
    label: 'Honduras',
    value: 'HN'
  },
  {
    label: 'Hong Kong',
    value: 'HK'
  },
  {
    label: 'Hungary',
    value: 'HU'
  },
  {
    label: 'Iceland',
    value: 'IS'
  },
  {
    label: 'India',
    value: 'IN'
  },
  {
    label: 'Indonesia',
    value: 'ID'
  },
  {
    label: 'Iran, Islamic Republic Of',
    value: 'IR'
  },
  {
    label: 'Iraq',
    value: 'IQ'
  },
  {
    label: 'Ireland',
    value: 'IE'
  },
  {
    label: 'Isle of Man',
    value: 'IM'
  },
  {
    label: 'Israel',
    value: 'IL'
  },
  {
    label: 'Italy',
    value: 'IT'
  },
  {
    label: 'Jamaica',
    value: 'JM'
  },
  {
    label: 'Japan',
    value: 'JP'
  },
  {
    label: 'Jersey',
    value: 'JE'
  },
  {
    label: 'Jordan',
    value: 'JO'
  },
  {
    label: 'Kazakhstan',
    value: 'KZ'
  },
  {
    label: 'Kenya',
    value: 'KE'
  },
  {
    label: 'Kiribati',
    value: 'KI'
  },
  {
    label: 'Korea, Democratic People"S Republic of',
    value: 'KP'
  },
  {
    label: 'Korea, Republic of',
    value: 'KR'
  },
  {
    label: 'Kuwait',
    value: 'KW'
  },
  {
    label: 'Kyrgyzstan',
    value: 'KG'
  },
  {
    label: 'Lao People"S Democratic Republic',
    value: 'LA'
  },
  {
    label: 'Latvia',
    value: 'LV'
  },
  {
    label: 'Lebanon',
    value: 'LB'
  },
  {
    label: 'Lesotho',
    value: 'LS'
  },
  {
    label: 'Liberia',
    value: 'LR'
  },
  {
    label: 'Libyan Arab Jamahiriya',
    value: 'LY'
  },
  {
    label: 'Liechtenstein',
    value: 'LI'
  },
  {
    label: 'Lithuania',
    value: 'LT'
  },
  {
    label: 'Luxembourg',
    value: 'LU'
  },
  {
    label: 'Macao',
    value: 'MO'
  },
  {
    label: 'Macedonia, The Former Yugoslav Republic of',
    value: 'MK'
  },
  {
    label: 'Madagascar',
    value: 'MG'
  },
  {
    label: 'Malawi',
    value: 'MW'
  },
  {
    label: 'Malaysia',
    value: 'MY'
  },
  {
    label: 'Maldives',
    value: 'MV'
  },
  {
    label: 'Mali',
    value: 'ML'
  },
  {
    label: 'Malta',
    value: 'MT'
  },
  {
    label: 'Marshall Islands',
    value: 'MH'
  },
  {
    label: 'Martinique',
    value: 'MQ'
  },
  {
    label: 'Mauritania',
    value: 'MR'
  },
  {
    label: 'Mauritius',
    value: 'MU'
  },
  {
    label: 'Mayotte',
    value: 'YT'
  },
  {
    label: 'Mexico',
    value: 'MX'
  },
  {
    label: 'Micronesia, Federated States of',
    value: 'FM'
  },
  {
    label: 'Moldova, Republic of',
    value: 'MD'
  },
  {
    label: 'Monaco',
    value: 'MC'
  },
  {
    label: 'Mongolia',
    value: 'MN'
  },
  {
    label: 'Montserrat',
    value: 'MS'
  },
  {
    label: 'Morocco',
    value: 'MA'
  },
  {
    label: 'Mozambique',
    value: 'MZ'
  },
  {
    label: 'Myanmar',
    value: 'MM'
  },
  {
    label: 'Namibia',
    value: 'NA'
  },
  {
    label: 'Nauru',
    value: 'NR'
  },
  {
    label: 'Nepal',
    value: 'NP'
  },
  {
    label: 'Netherlands',
    value: 'NL'
  },
  {
    label: 'Netherlands Antilles',
    value: 'AN'
  },
  {
    label: 'New Caledonia',
    value: 'NC'
  },
  {
    label: 'New Zealand',
    value: 'NZ'
  },
  {
    label: 'Nicaragua',
    value: 'NI'
  },
  {
    label: 'Niger',
    value: 'NE'
  },
  {
    label: 'Nigeria',
    value: 'NG'
  },
  {
    label: 'Niue',
    value: 'NU'
  },
  {
    label: 'Norfolk Island',
    value: 'NF'
  },
  {
    label: 'Northern Mariana Islands',
    value: 'MP'
  },
  {
    label: 'Norway',
    value: 'NO'
  },
  {
    label: 'Oman',
    value: 'OM'
  },
  {
    label: 'Pakistan',
    value: 'PK'
  },
  {
    label: 'Palau',
    value: 'PW'
  },
  {
    label: 'Palestinian Territory, Occupied',
    value: 'PS'
  },
  {
    label: 'Panama',
    value: 'PA'
  },
  {
    label: 'Papua New Guinea',
    value: 'PG'
  },
  {
    label: 'Paraguay',
    value: 'PY'
  },
  {
    label: 'Peru',
    value: 'PE'
  },
  {
    label: 'Philippines',
    value: 'PH'
  },
  {
    label: 'Pitcairn',
    value: 'PN'
  },
  {
    label: 'Poland',
    value: 'PL'
  },
  {
    label: 'Portugal',
    value: 'PT'
  },
  {
    label: 'Puerto Rico',
    value: 'PR'
  },
  {
    label: 'Qatar',
    value: 'QA'
  },
  {
    label: 'Reunion',
    value: 'RE'
  },
  {
    label: 'Romania',
    value: 'RO'
  },
  {
    label: 'Russian Federation',
    value: 'RU'
  },
  {
    label: 'RWANDA',
    value: 'RW'
  },
  {
    label: 'Saint Helena',
    value: 'SH'
  },
  {
    label: 'Saint Kitts and Nevis',
    value: 'KN'
  },
  {
    label: 'Saint Lucia',
    value: 'LC'
  },
  {
    label: 'Saint Pierre and Miquelon',
    value: 'PM'
  },
  {
    label: 'Saint Vincent and the Grenadines',
    value: 'VC'
  },
  {
    label: 'Samoa',
    value: 'WS'
  },
  {
    label: 'San Marino',
    value: 'SM'
  },
  {
    label: 'Sao Tome and Principe',
    value: 'ST'
  },
  {
    label: 'Saudi Arabia',
    value: 'SA'
  },
  {
    label: 'Senegal',
    value: 'SN'
  },
  {
    label: 'Serbia and Montenegro',
    value: 'CS'
  },
  {
    label: 'Seychelles',
    value: 'SC'
  },
  {
    label: 'Sierra Leone',
    value: 'SL'
  },
  {
    label: 'Singapore',
    value: 'SG'
  },
  {
    label: 'Slovakia',
    value: 'SK'
  },
  {
    label: 'Slovenia',
    value: 'SI'
  },
  {
    label: 'Solomon Islands',
    value: 'SB'
  },
  {
    label: 'Somalia',
    value: 'SO'
  },
  {
    label: 'South Africa',
    value: 'ZA'
  },
  {
    label: 'South Georgia and the South Sandwich Islands',
    value: 'GS'
  },
  {
    label: 'Spain',
    value: 'ES'
  },
  {
    label: 'Sri Lanka',
    value: 'LK'
  },
  {
    label: 'Sudan',
    value: 'SD'
  },
  {
    label: 'Suriname',
    value: 'SR'
  },
  {
    label: 'Svalbard and Jan Mayen',
    value: 'SJ'
  },
  {
    label: 'Swaziland',
    value: 'SZ'
  },
  {
    label: 'Sweden',
    value: 'SE'
  },
  {
    label: 'Switzerland',
    value: 'CH'
  },
  {
    label: 'Syrian Arab Republic',
    value: 'SY'
  },
  {
    label: 'Taiwan, Province of China',
    value: 'TW'
  },
  {
    label: 'Tajikistan',
    value: 'TJ'
  },
  {
    label: 'Tanzania, United Republic of',
    value: 'TZ'
  },
  {
    label: 'Thailand',
    value: 'TH'
  },
  {
    label: 'Timor-Leste',
    value: 'TL'
  },
  {
    label: 'Togo',
    value: 'TG'
  },
  {
    label: 'Tokelau',
    value: 'TK'
  },
  {
    label: 'Tonga',
    value: 'TO'
  },
  {
    label: 'Trinidad and Tobago',
    value: 'TT'
  },
  {
    label: 'Tunisia',
    value: 'TN'
  },
  {
    label: 'Turkey',
    value: 'TR'
  },
  {
    label: 'Turkmenistan',
    value: 'TM'
  },
  {
    label: 'Turks and Caicos Islands',
    value: 'TC'
  },
  {
    label: 'Tuvalu',
    value: 'TV'
  },
  {
    label: 'Uganda',
    value: 'UG'
  },
  {
    label: 'Ukraine',
    value: 'UA'
  },
  {
    label: 'United Arab Emirates',
    value: 'AE'
  },
  {
    label: 'United Kingdom',
    value: 'GB'
  },
  {
    label: 'United States',
    value: 'US'
  },
  {
    label: 'United States Minor Outlying Islands',
    value: 'UM'
  },
  {
    label: 'Uruguay',
    value: 'UY'
  },
  {
    label: 'Uzbekistan',
    value: 'UZ'
  },
  {
    label: 'Vanuatu',
    value: 'VU'
  },
  {
    label: 'Venezuela',
    value: 'VE'
  },
  {
    label: 'Viet Nam',
    value: 'VN'
  },
  {
    label: 'Virgin Islands, British',
    value: 'VG'
  },
  {
    label: 'Virgin Islands, U.S.',
    value: 'VI'
  },
  {
    label: 'Wallis and Futuna',
    value: 'WF'
  },
  {
    label: 'Western Sahara',
    value: 'EH'
  },
  {
    label: 'Yemen',
    value: 'YE'
  },
  {
    label: 'Zambia',
    value: 'ZM'
  },
  {
    label: 'Zimbabwe',
    value: 'ZW'
  }
];

export const DEFAULT_FORM_URLS = {
  super: '/super-visa-insurance',
  visitor: '/visitors-insurance',
  student: '/student-insurance'
};
