import React, { useState, useEffect, useRef } from 'react';
import { STORAGE_URL } from 'common/Constants';
import { Quote } from 'components';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  CircularProgress,
  Container,
  IconButton
} from '@material-ui/core';
import { getPlansMeta } from 'api/planMetaApi';
import debounce from 'debounce';
import { useSwipeable } from 'react-swipeable';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { fade } from '@material-ui/core/styles/colorManipulator';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles((theme) => ({
  back: {
    paddingBottom: theme.spacing(2)
  },
  loading: {
    textAlign: 'center'
  },
  img: {
    maxWidth: '100%',
    height: 'auto'
  },
  compare: {
    display: 'flex',
    overflow: 'hidden'
  },
  labels: {
    flex: '0 0 20%',
    borderRight: 'solid 1px #eee',
    borderLeft: 'solid 1px #eee',
    marginRight: '4px',

    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  labelsTitle: {
    fontSize: '12px',
    textTransform: 'uppercase',
    color: '#999'
  },
  compareItems: {
    flex: '1 1 100%',
    position: 'relative',
    overflow: 'hidden',

    [theme.breakpoints.up('sm')]: {
      flex: '1 1 80%'
    }
  },
  compareItemsContainer: {
    display: 'flex',
    position: 'relative',
    transition: 'all 1s ease',
    left: '0'
  },
  compareItem: {
    flex: '0 0 47%',
    borderRight: 'solid 1px #eee',
    borderLeft: 'solid 1px #eee',
    marginRight: '4px',

    [theme.breakpoints.up('sm')]: {
      '& .label-title': {
        display: 'none'
      }
    },
    [theme.breakpoints.up('md')]: {
      flex: '0 0 32%'
    },
    [theme.breakpoints.up('lg')]: {
      flex: '0 0 24%'
    }
  },
  itemCell: {
    borderBottom: 'solid 1px #eee',
    padding: '8px'
  },
  groupCell: {
    borderBottom: 'solid 4px #eee',
    padding: '20px 8px 3px 8px'
  },
  compareContainer: {
    display: 'flex'
  },
  compareNav: {
    position: 'fixed',
    width: '100%',
    left: '0',
    top: '50%',
    zIndex: '10'
  },
  compareNavContainer: {
    position: 'relative'
  },
  compareNavBack: {
    left: '0',
    zIndex: '5',
    position: 'absolute',
    backgroundColor: fade(theme.palette.primary.main, 0.5)
  },
  compareNavNext: {
    position: 'absolute',
    right: '0',
    zIndex: '5',
    backgroundColor: fade(theme.palette.primary.main, 0.5)
  },
  compareNavDisabled: {
    display: 'none'
  },
  itemLogoFixed: {
    position: 'relative'
  },
  itemLogoFixedInner: {
    borderRight: 'solid 1px #eee',
    borderLeft: 'solid 1px #eee',
    borderBottom: 'solid 3px #ccc',
    position: 'absolute',
    transform: 'translateY(-350px)',
    transition: 'transform .5s ease',
    width: '100%',
    backgroundColor: '#fff',
    zIndex: '100',
    height: '50px',

    '& img': {
      width: 'auto',
      maxHeight: '50px'
    }
  }
}));

const SetHtml = ({ item }) => (
  <div dangerouslySetInnerHTML={{ __html: item }} />
);

const Comparison = ({
  compareList,
  coverageType,
  insuranceType,
  onCompareBackClick,
  onRateSelect
}) => {
  const onNextClick = () => {
    if (currentItem < items.length - 2) {
      let offset = itemWidth * (currentItem + 1);
      compareItemsContainer.current.style.left = `-${offset}px`;
      setCurrentItem(currentItem + 1);
    }
  };

  const onBackClick = () => {
    if (currentItem > 0) {
      let offset = itemWidth * (currentItem - 1);
      compareItemsContainer.current.style.left = `-${offset}px`;
      setCurrentItem(currentItem - 1);
    }
  };

  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [itemWidth, setItemWidth] = useState();
  const [currentItem, setCurrentItem] = useState(0);
  const [logosOffset, _setLogosOffset] = useState(0);
  const handlers = useSwipeable({
    onSwipedRight: () => onBackClick(),
    onSwipedLeft: () => onNextClick()
  });
  const compareContainer = useRef();
  let cellHeights = {};
  const logoHeightRef = useRef();
  const compareItemsContainer = useRef();
  const compareItemsContainerSwipeHandler = (el) => {
    handlers.ref(el);
    compareItemsContainer.current = el;
  };
  const logosOffsetRef = useRef(logosOffset);

  // need this state (updated) in event handler
  // need to set it through useRef, for the same reason
  const setLogosOffset = (x) => {
    logosOffsetRef.current = x;
    _setLogosOffset(x);
  };

  const getLogoOffset = () => {
    const logoCells = document.getElementsByClassName('item-logo-fixed');
    if (logoCells.length) {
      if (logoCells[0] instanceof HTMLElement) {
        return logoCells[0].getBoundingClientRect().top + window.pageYOffset;
      }
    }

    return 0;
  };

  const setItemCellHeights = () => {
    //assuming the DOM is ready
    const cells = document.getElementsByClassName('item-cell');

    // get item width;
    let itemCollection = document.getElementsByClassName('compare-item');
    if (itemCollection && itemCollection.length) {
      setItemWidth(itemCollection[0].offsetWidth + 4);
    }

    // remove style properties
    for (let e of cells) {
      if (e instanceof HTMLElement) {
        e.removeAttribute('style');
      }
    }

    // reset previous height;
    cellHeights = {};

    // get max heights
    for (let node of cells) {
      let nodeKey = node.dataset.key;
      if (cellHeights.hasOwnProperty(nodeKey)) {
        if (cellHeights[nodeKey] < node.clientHeight) {
          cellHeights[nodeKey] = node.clientHeight;
        }
      } else {
        cellHeights[nodeKey] = node.clientHeight;
      }
    }

    //set heights
    for (const prop in cellHeights) {
      if (cellHeights.hasOwnProperty(prop)) {
        let propCollection = document.getElementsByClassName(prop);
        for (let e of propCollection) {
          if (e instanceof HTMLElement) {
            e.style.height = `${cellHeights[prop]}px`;
          }
        }
      }
    }

    // get logo cell offset
    setLogosOffset(getLogoOffset());
  };

  useEffect(() => {
    getPlansMeta(
      { planIds: compareList.map((item) => item.plan._id) },
      insuranceType === 'student' ? 'student' : 'visitor'
    )
      .then((data) => {
        // set for useEffect;
        setItems(data);
        for (let i = 0; i < compareList.length; i++) {
          for (let j = 0; j < data.length; j++) {
            if (compareList[i].plan._id === data[j].planId) {
              compareList[i].plan.meta = data[j];
              break;
            }
          }
        }

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);

  const watchScroll = () => {
    let top = window.pageYOffset;
    const logoCells = document.getElementsByClassName('item-logo-fixed-inner');

    if (top > logosOffsetRef.current) {
      for (let e of logoCells) {
        if (e instanceof HTMLElement) {
          e.style.transform = `translateY(${top - logosOffsetRef.current}px)`;
        }
      }
    } else {
      for (let e of logoCells) {
        if (e instanceof HTMLElement) {
          e.style.transform = 'translateY(-250px)';
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener('resize', debounce(setItemCellHeights, 400));
    window.addEventListener('scroll', watchScroll);

    return () => {
      window.removeEventListener('resize', debounce(setItemCellHeights, 400));
      window.removeEventListener('scroll', watchScroll);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setItemCellHeights();
    }, 400);
  }, [items.length]);

  return (
    <>
      <div className={classes.back}>
        <Button
          color="primary"
          onClick={onCompareBackClick}
          startIcon={<ArrowBackIcon />}
          variant="contained">
          Back to Home
        </Button>
      </div>
      {loading ? (
        <div className={classes.loading}>
          <CircularProgress variant="indeterminate" />
        </div>
      ) : (
        <>
          <div className={clsx(classes.compareNav)}>
            <Container classes={{ root: classes.compareNavContainer }} fixed>
              <IconButton
                aria-label="Next"
                className={clsx(classes.compareNavBack, 'compare-nav-back')}
                classes={{ disabled: classes.compareNavDisabled }}
                disabled={currentItem === 0}
                onClick={onBackClick}>
                <NavigateBeforeIcon />
              </IconButton>
              <IconButton
                aria-label="Next"
                className={clsx(classes.compareNavNext, 'compare-nav-next')}
                classes={{ disabled: classes.compareNavDisabled }}
                color="primary"
                disabled={currentItem === items.length - 2}
                onClick={onNextClick}>
                <NavigateNextIcon />
              </IconButton>
            </Container>
          </div>
          <div
            className={classes.compare}
            ref={compareContainer}
            id="compareContainer">
            <div className={classes.labels}>
              <div
                className={clsx(classes.itemCell, 'item-cell', 'itemLogo')}
                data-key="itemLogo"
                id="itemLogo"
                ref={logoHeightRef}>
                &nbsp;
              </div>

              <div className={clsx(classes.itemLogoFixed)}>
                <div
                  className={clsx(
                    classes.itemLogoFixedInner,
                    'item-logo-fixed-inner'
                  )}>
                  {' '}
                </div>
              </div>

              <div
                className={clsx(classes.itemCell, 'item-cell', 'itemQuote')}
                data-key="itemQuote">
                &nbsp;
              </div>
              <div
                className={clsx(classes.itemCell, 'item-cell', 'itemCta')}
                data-key="itemCta">
                &nbsp;
              </div>
              <div
                className={clsx(
                  classes.itemCell,
                  'item-cell',
                  'covid19Coverage'
                )}
                data-key="covid19Coverage">
                <div className={clsx(classes.labelsTitle, 'label-title')}>
                  Covid 19 Coverage
                </div>
              </div>

              <div
                className={clsx(
                  classes.itemCell,
                  'item-cell',
                  'ambulanceTransportation'
                )}
                data-key="ambulanceTransportation">
                <div className={clsx(classes.labelsTitle, 'label-title')}>
                  Ambulance Services / Transportation
                </div>
              </div>

              <div
                className={clsx(
                  classes.itemCell,
                  'item-cell',
                  'emergencyHospitalization'
                )}
                data-key="emergencyHospitalization">
                <div className={clsx(classes.labelsTitle, 'label-title')}>
                  Emergency Hospitalization
                </div>
              </div>

              <div
                className={clsx(
                  classes.itemCell,
                  'item-cell',
                  'physicianSurgeon'
                )}
                data-key="physicianSurgeon">
                <div className={clsx(classes.labelsTitle, 'label-title')}>
                  Services of a Physician, Surgeon, etc.
                </div>
              </div>

              <div
                className={clsx(
                  classes.itemCell,
                  'item-cell',
                  'emergencyMedicalCare'
                )}
                data-key="emergencyMedicalCare">
                <div className={clsx(classes.labelsTitle, 'label-title')}>
                  Emergency Medical Care
                </div>
              </div>

              <div
                className={clsx(
                  classes.itemCell,
                  'item-cell',
                  'walkInClinicVisit'
                )}
                data-key="walkInClinicVisit">
                <div className={clsx(classes.labelsTitle, 'label-title')}>
                  Walk-in Clinic Visits
                </div>
              </div>

              <div
                className={clsx(
                  classes.itemCell,
                  'item-cell',
                  'followUpTreatment'
                )}
                data-key="followUpTreatment">
                <div className={clsx(classes.labelsTitle, 'label-title')}>
                  Follow Up Treatment
                </div>
              </div>

              <div
                className={clsx(
                  classes.itemCell,
                  'item-cell',
                  'laboratoryXRay'
                )}
                data-key="laboratoryXRay">
                <div className={clsx(classes.labelsTitle, 'label-title')}>
                  Laboratory Diagnostics / X-Ray
                </div>
              </div>

              <div
                className={clsx(
                  classes.itemCell,
                  'item-cell',
                  'prescriptionMedication'
                )}
                data-key="prescriptionMedication">
                <div className={clsx(classes.labelsTitle, 'label-title')}>
                  Prescription Medications
                </div>
              </div>

              <div
                className={clsx(
                  classes.itemCell,
                  'item-cell',
                  'dentalPainRelief'
                )}
                data-key="dentalPainRelief">
                <div className={clsx(classes.labelsTitle, 'label-title')}>
                  Dental Pain Relief
                </div>
              </div>

              <div
                className={clsx(
                  classes.itemCell,
                  'item-cell',
                  'emergencyDentalRepair'
                )}
                data-key="emergencyDentalRepair">
                <div className={clsx(classes.labelsTitle, 'label-title')}>
                  Emergency Dental Repair
                </div>
              </div>

              <div
                className={clsx(
                  classes.itemCell,
                  'item-cell',
                  'emergencyAssistance24Hours'
                )}
                data-key="emergencyAssistance24Hours">
                <div className={clsx(classes.labelsTitle, 'label-title')}>
                  24-hour Emergency Assistance Center
                </div>
              </div>

              <div
                className={clsx(
                  classes.itemCell,
                  'item-cell',
                  'emergencyHomeReturnEvacuation'
                )}
                data-key="emergencyHomeReturnEvacuation">
                <div className={clsx(classes.labelsTitle, 'label-title')}>
                  Emergency Home Return / Evacuation
                </div>
              </div>

              <div
                className={clsx(
                  classes.itemCell,
                  'item-cell',
                  'repatriationOfRemains'
                )}
                data-key="repatriationOfRemains">
                <div className={clsx(classes.labelsTitle, 'label-title')}>
                  Repatriation of Remains
                </div>
              </div>

              <div
                className={clsx(
                  classes.itemCell,
                  'item-cell',
                  'cremationBurial'
                )}
                data-key="cremationBurial">
                <div className={clsx(classes.labelsTitle, 'label-title')}>
                  Cremation / Burial
                </div>
              </div>

              <div
                className={clsx(
                  classes.itemCell,
                  'item-cell',
                  'stablePreExistingConditionsCoverage'
                )}
                data-key="stablePreExistingConditionsCoverage">
                <div className={clsx(classes.labelsTitle, 'label-title')}>
                  Stable Pre-existing Medical Conditions Coverage
                </div>
              </div>

              <div
                className={clsx(
                  classes.itemCell,
                  'item-cell',
                  'nurseMedicalAttendant'
                )}
                data-key="nurseMedicalAttendant">
                <div className={clsx(classes.labelsTitle, 'label-title')}>
                  Private Duty Nurse / Medical Attendant
                </div>
              </div>

              <div
                className={clsx(
                  classes.itemCell,
                  'item-cell',
                  'rentalPurchaseMedicalAppliances'
                )}
                data-key="rentalPurchaseMedicalAppliances">
                <div className={clsx(classes.labelsTitle, 'label-title')}>
                  Rental / Purchase of Medical Appliances
                </div>
              </div>

              <div
                className={clsx(
                  classes.itemCell,
                  'item-cell',
                  'sideTripBenefits'
                )}
                data-key="sideTripBenefits">
                <div className={clsx(classes.labelsTitle, 'label-title')}>
                  Side-Trips Benefit (with in Canada and outside of Canada)
                </div>
              </div>

              <div
                className={clsx(
                  classes.groupCell,
                  'item-cell',
                  'groupEnhanced'
                )}
                data-key="groupEnhanced">
                <div className={clsx(classes.labelsTitle, 'label-title')}>
                  Enhanced Services
                </div>
              </div>

              <div
                className={clsx(
                  classes.itemCell,
                  'item-cell',
                  'emergencySpecialistService'
                )}
                data-key="emergencySpecialistService">
                <div className={clsx(classes.labelsTitle, 'label-title')}>
                  Emergency Services of Chiropractor, Chiropodist,
                  Physiotherapist, Osteopath, or Podiatrist
                </div>
              </div>

              <div
                className={clsx(classes.itemCell, 'item-cell', 'acupuncture')}
                data-key="acupuncture">
                <div className={clsx(classes.labelsTitle, 'label-title')}>
                  acupuncture
                </div>
              </div>

              <div
                className={clsx(
                  classes.itemCell,
                  'item-cell',
                  'accidentalDeath'
                )}
                data-key="accidentalDeath">
                <div className={clsx(classes.labelsTitle, 'label-title')}>
                  Accidental Death
                </div>
              </div>

              <div
                className={clsx(
                  classes.itemCell,
                  'item-cell',
                  'doubleDismemberment'
                )}
                data-key="doubleDismemberment">
                <div className={clsx(classes.labelsTitle, 'label-title')}>
                  Double Dismembernent
                </div>
              </div>

              <div
                className={clsx(
                  classes.itemCell,
                  'item-cell',
                  'singleDismemberment'
                )}
                data-key="singleDismemberment">
                <div className={clsx(classes.labelsTitle, 'label-title')}>
                  Single Dismembernent
                </div>
              </div>

              <div
                className={clsx(
                  classes.itemCell,
                  'item-cell',
                  'bedsideCompanionAccommodation'
                )}
                data-key="bedsideCompanionAccommodation">
                <div className={clsx(classes.labelsTitle, 'label-title')}>
                  Bedside Companion Accommodation / Transportation
                </div>
              </div>

              <div
                className={clsx(
                  classes.itemCell,
                  'item-cell',
                  'mealsHospitalAllowance'
                )}
                data-key="mealsHospitalAllowance">
                <div className={clsx(classes.labelsTitle, 'label-title')}>
                  Meals and Accommodation / Hospital Allowance / Out-of-pocket
                  Expenses
                </div>
              </div>

              <div
                className={clsx(
                  classes.itemCell,
                  'item-cell',
                  'maternityBenefits'
                )}
                data-key="maternityBenefits">
                <div className={clsx(classes.labelsTitle, 'label-title')}>
                  Maternity Benefits / Delivery Coverage
                </div>
              </div>

              <div
                className={clsx(
                  classes.itemCell,
                  'item-cell',
                  'physicalExamination'
                )}
                data-key="physicalExamination">
                <div className={clsx(classes.labelsTitle, 'label-title')}>
                  Physical Examination (Non-emergency)
                </div>
              </div>

              <div
                className={clsx(
                  classes.itemCell,
                  'item-cell',
                  'eyeExamination'
                )}
                data-key="eyeExamination">
                <div className={clsx(classes.labelsTitle, 'label-title')}>
                  Eye Examination (Non-emergency)
                </div>
              </div>

              <div
                className={clsx(classes.itemCell, 'item-cell', 'vaccines')}
                data-key="vaccines">
                <div className={clsx(classes.labelsTitle, 'label-title')}>
                  Vaccines (Non-emergency)
                </div>
              </div>

              <div
                className={clsx(
                  classes.itemCell,
                  'item-cell',
                  'childCareEscortExpenses'
                )}
                data-key="childCareEscortExpenses">
                <div className={clsx(classes.labelsTitle, 'label-title')}>
                  Child Care / Escort Expenses
                </div>
              </div>

              <div
                className={clsx(
                  classes.itemCell,
                  'item-cell',
                  'psychiatricPsychological'
                )}
                data-key="psychiatricPsychological">
                <div className={clsx(classes.labelsTitle, 'label-title')}>
                  Psychiatric / Psychological
                </div>
              </div>

              <div
                className={clsx(
                  classes.itemCell,
                  'item-cell',
                  'returnOfaVehicle'
                )}
                data-key="returnOfaVehicle">
                <div className={clsx(classes.labelsTitle, 'label-title')}>
                  Return of a Vehicle
                </div>
              </div>

              <div
                className={clsx(
                  classes.itemCell,
                  'item-cell',
                  'sportsInjuriesCoverage'
                )}
                data-key="sportsInjuriesCoverage">
                <div className={clsx(classes.labelsTitle, 'label-title')}>
                  Sports Injuries Coverage
                </div>
              </div>

              <div
                className={clsx(
                  classes.itemCell,
                  'item-cell',
                  'flightTravelAccident'
                )}
                data-key="flightTravelAccident">
                <div className={clsx(classes.labelsTitle, 'label-title')}>
                  Flight / Travel Accident
                </div>
              </div>

              <div
                className={clsx(
                  classes.itemCell,
                  'item-cell',
                  'tripBreakBenefit'
                )}
                data-key="tripBreakBenefit">
                <div className={clsx(classes.labelsTitle, 'label-title')}>
                  Trip-Break Benefit
                </div>
              </div>

              {insuranceType === 'student' && (
                <>
                  <div
                    className={clsx(
                      classes.itemCell,
                      'item-cell',
                      'commonCarrier'
                    )}
                    data-key="commonCarrier">
                    <div className={clsx(classes.labelsTitle, 'label-title')}>
                      Common Carrier
                    </div>
                  </div>

                  <div
                    className={clsx(
                      classes.itemCell,
                      'item-cell',
                      'correctiveDevices'
                    )}
                    data-key="correctiveDevices">
                    <div className={clsx(classes.labelsTitle, 'label-title')}>
                      Corrective Devices
                    </div>
                  </div>

                  <div
                    className={clsx(
                      classes.itemCell,
                      'item-cell',
                      'prescriptionGlassesLensesHearingAids'
                    )}
                    data-key="prescriptionGlassesLensesHearingAids">
                    <div className={clsx(classes.labelsTitle, 'label-title')}>
                      Prescription Glasses / Contact lenses / Hearing Aids
                    </div>
                  </div>

                  <div
                    className={clsx(
                      classes.itemCell,
                      'item-cell',
                      'sexualHealthConsultation'
                    )}
                    data-key="sexualHealthConsultation">
                    <div className={clsx(classes.labelsTitle, 'label-title')}>
                      Sexual Health Consultation
                    </div>
                  </div>

                  <div
                    className={clsx(
                      classes.itemCell,
                      'item-cell',
                      'terrorismCoverage'
                    )}
                    data-key="terrorismCoverage">
                    <div className={clsx(classes.labelsTitle, 'label-title')}>
                      Terrorism Coverage( Only for Outbound Insured)
                    </div>
                  </div>

                  <div
                    className={clsx(
                      classes.itemCell,
                      'item-cell',
                      'traumaCounselling'
                    )}
                    data-key="traumaCounselling">
                    <div className={clsx(classes.labelsTitle, 'label-title')}>
                      Trauma Counselling
                    </div>
                  </div>

                  <div
                    className={clsx(
                      classes.itemCell,
                      'item-cell',
                      'tuberculosisTestingVaccination'
                    )}
                    data-key="tuberculosisTestingVaccination">
                    <div className={clsx(classes.labelsTitle, 'label-title')}>
                      Tuberculosis Testing and Vaccination
                    </div>
                  </div>

                  <div
                    className={clsx(
                      classes.itemCell,
                      'item-cell',
                      'tuitionReimbursement'
                    )}
                    data-key="tuitionReimbursement">
                    <div className={clsx(classes.labelsTitle, 'label-title')}>
                      Tuition Reimbursement in Relation to Emergency
                    </div>
                  </div>

                  <div
                    className={clsx(
                      classes.itemCell,
                      'item-cell',
                      'tutorialServices'
                    )}
                    data-key="tutorialServices">
                    <div className={clsx(classes.labelsTitle, 'label-title')}>
                      Tutorial Services
                    </div>
                  </div>

                  <div
                    className={clsx(
                      classes.itemCell,
                      'item-cell',
                      'wisdomTeethRemoval'
                    )}
                    data-key="wisdomTeethRemoval">
                    <div className={clsx(classes.labelsTitle, 'label-title')}>
                      Wisdom Teeth Removal
                    </div>
                  </div>
                </>
              )}

              <div
                className={clsx(
                  classes.itemCell,
                  'item-cell',
                  'underwrittenBy'
                )}
                data-key="underwrittenBy">
                <div className={clsx(classes.labelsTitle, 'label-title')}>
                  Underwritten By
                </div>
              </div>
            </div>
            <div className={classes.compareItems}>
              <div
                className={clsx(
                  classes.compareItemsContainer,
                  'compare-items-container'
                )}
                {...handlers}
                ref={compareItemsContainerSwipeHandler}>
                {compareList.map((item) => (
                  <div
                    className={clsx(classes.compareItem, 'compare-item')}
                    key={item.plan._id}>
                    {item.plan.meta ? (
                      <>
                        <div
                          className={clsx(
                            classes.itemCell,
                            'item-cell',
                            'itemLogo'
                          )}
                          data-key="itemLogo">
                          <img
                            alt={item.name}
                            className={clsx(classes.img)}
                            src={`${STORAGE_URL}/images/partners/${item.plan.slug}.png`}
                          />
                        </div>
                        <div
                          className={clsx(
                            classes.itemLogoFixed,
                            'item-logo-fixed'
                          )}>
                          <div
                            className={clsx(
                              classes.itemLogoFixedInner,
                              'item-logo-fixed-inner'
                            )}>
                            <img
                              alt={item.name}
                              className={clsx(classes.img)}
                              src={`${STORAGE_URL}/images/partners/${item.plan.slug}.png`}
                            />
                          </div>
                        </div>
                        <div
                          className={clsx(
                            classes.itemCell,
                            'item-cell',
                            'itemQuote'
                          )}
                          data-key="itemQuote">
                          <Quote
                            coverageType={coverageType}
                            insuranceType={insuranceType}
                            {...item}
                          />
                        </div>
                        <div
                          className={clsx(
                            classes.itemCell,
                            'item-cell',
                            'itemCta'
                          )}
                          data-key="itemCta">
                          <Button
                            color="secondary"
                            onClick={() => {
                              onRateSelect(item);
                            }}
                            variant="contained">
                            Buy Now
                          </Button>
                        </div>
                        <div
                          className={clsx(
                            classes.itemCell,
                            'item-cell',
                            'covid19Coverage'
                          )}
                          data-key="covid19Coverage">
                          <div
                            className={clsx(
                              classes.labelsTitle,
                              'label-title'
                            )}>
                            Covid 19 Coverage
                          </div>
                          <SetHtml
                            item={item.plan.meta.basic.covid19Coverage}
                          />
                        </div>

                        <div
                          className={clsx(
                            classes.itemCell,
                            'item-cell',
                            'ambulanceTransportation'
                          )}
                          data-key="ambulanceTransportation">
                          <div
                            className={clsx(
                              classes.labelsTitle,
                              'label-title'
                            )}>
                            Ambulance Services / Transportation
                          </div>
                          <SetHtml
                            item={item.plan.meta.basic.ambulanceTransportation}
                          />
                        </div>

                        <div
                          className={clsx(
                            classes.itemCell,
                            'item-cell',
                            'emergencyHospitalization'
                          )}
                          data-key="emergencyHospitalization">
                          <div
                            className={clsx(
                              classes.labelsTitle,
                              'label-title'
                            )}>
                            Emergency Hospitalization
                          </div>
                          <SetHtml
                            item={item.plan.meta.basic.emergencyHospitalization}
                          />
                        </div>

                        <div
                          className={clsx(
                            classes.itemCell,
                            'item-cell',
                            'physicianSurgeon'
                          )}
                          data-key="physicianSurgeon">
                          <div
                            className={clsx(
                              classes.labelsTitle,
                              'label-title'
                            )}>
                            Services of a Physician, Surgeon, etc.
                          </div>
                          <SetHtml
                            item={item.plan.meta.basic.physicianSurgeon}
                          />
                        </div>

                        <div
                          className={clsx(
                            classes.itemCell,
                            'item-cell',
                            'emergencyMedicalCare'
                          )}
                          data-key="emergencyMedicalCare">
                          <div
                            className={clsx(
                              classes.labelsTitle,
                              'label-title'
                            )}>
                            Emergency Medical Care
                          </div>
                          <SetHtml
                            item={item.plan.meta.basic.emergencyMedicalCare}
                          />
                        </div>

                        <div
                          className={clsx(
                            classes.itemCell,
                            'item-cell',
                            'walkInClinicVisit'
                          )}
                          data-key="walkInClinicVisit">
                          <div
                            className={clsx(
                              classes.labelsTitle,
                              'label-title'
                            )}>
                            Walk-in Clinic Visits
                          </div>
                          <SetHtml
                            item={item.plan.meta.basic.walkInClinicVisit}
                          />
                        </div>

                        <div
                          className={clsx(
                            classes.itemCell,
                            'item-cell',
                            'followUpTreatment'
                          )}
                          data-key="followUpTreatment">
                          <div
                            className={clsx(
                              classes.labelsTitle,
                              'label-title'
                            )}>
                            Follow Up Treatment
                          </div>
                          <SetHtml
                            item={item.plan.meta.basic.followUpTreatment}
                          />
                        </div>

                        <div
                          className={clsx(
                            classes.itemCell,
                            'item-cell',
                            'laboratoryXRay'
                          )}
                          data-key="laboratoryXRay">
                          <div
                            className={clsx(
                              classes.labelsTitle,
                              'label-title'
                            )}>
                            Laboratory Diagnostics / X-Ray
                          </div>
                          <SetHtml item={item.plan.meta.basic.laboratoryXRay} />
                        </div>

                        <div
                          className={clsx(
                            classes.itemCell,
                            'item-cell',
                            'prescriptionMedication'
                          )}
                          data-key="prescriptionMedication">
                          <div
                            className={clsx(
                              classes.labelsTitle,
                              'label-title'
                            )}>
                            Prescription Medications
                          </div>
                          <SetHtml
                            item={item.plan.meta.basic.prescriptionMedication}
                          />
                        </div>

                        <div
                          className={clsx(
                            classes.itemCell,
                            'item-cell',
                            'dentalPainRelief'
                          )}
                          data-key="dentalPainRelief">
                          <div
                            className={clsx(
                              classes.labelsTitle,
                              'label-title'
                            )}>
                            Dental Pain Relief
                          </div>
                          <SetHtml
                            item={item.plan.meta.basic.dentalPainRelief}
                          />
                        </div>

                        <div
                          className={clsx(
                            classes.itemCell,
                            'item-cell',
                            'emergencyDentalRepair'
                          )}
                          data-key="emergencyDentalRepair">
                          <div
                            className={clsx(
                              classes.labelsTitle,
                              'label-title'
                            )}>
                            Emergency Dental Repair
                          </div>
                          <SetHtml
                            item={item.plan.meta.basic.emergencyDentalRepair}
                          />
                        </div>

                        <div
                          className={clsx(
                            classes.itemCell,
                            'item-cell',
                            'emergencyAssistance24Hours'
                          )}
                          data-key="emergencyAssistance24Hours">
                          <div
                            className={clsx(
                              classes.labelsTitle,
                              'label-title'
                            )}>
                            24-hour Emergency Assistance Center
                          </div>
                          <SetHtml
                            item={
                              item.plan.meta.basic.emergencyAssistance24Hours
                            }
                          />
                        </div>

                        <div
                          className={clsx(
                            classes.itemCell,
                            'item-cell',
                            'emergencyHomeReturnEvacuation'
                          )}
                          data-key="emergencyHomeReturnEvacuation">
                          <div
                            className={clsx(
                              classes.labelsTitle,
                              'label-title'
                            )}>
                            Emergency Home Return / Evacuation
                          </div>
                          <SetHtml
                            item={
                              item.plan.meta.basic.emergencyHomeReturnEvacuation
                            }
                          />
                        </div>

                        <div
                          className={clsx(
                            classes.itemCell,
                            'item-cell',
                            'repatriationOfRemains'
                          )}
                          data-key="repatriationOfRemains">
                          <div
                            className={clsx(
                              classes.labelsTitle,
                              'label-title'
                            )}>
                            Repatriation of Remains
                          </div>
                          <SetHtml
                            item={item.plan.meta.basic.repatriationOfRemains}
                          />
                        </div>

                        <div
                          className={clsx(
                            classes.itemCell,
                            'item-cell',
                            'cremationBurial'
                          )}
                          data-key="cremationBurial">
                          <div
                            className={clsx(
                              classes.labelsTitle,
                              'label-title'
                            )}>
                            Cremation / Burial
                          </div>
                          <SetHtml
                            item={item.plan.meta.basic.cremationBurial}
                          />
                        </div>

                        <div
                          className={clsx(
                            classes.itemCell,
                            'item-cell',
                            'stablePreExistingConditionsCoverage'
                          )}
                          data-key="stablePreExistingConditionsCoverage">
                          <div
                            className={clsx(
                              classes.labelsTitle,
                              'label-title'
                            )}>
                            Stable Pre-existing Medical Conditions Coverage
                          </div>
                          <SetHtml
                            item={
                              item.plan.meta.basic
                                .stablePreExistingConditionsCoverage
                            }
                          />
                        </div>

                        <div
                          className={clsx(
                            classes.itemCell,
                            'item-cell',
                            'nurseMedicalAttendant'
                          )}
                          data-key="nurseMedicalAttendant">
                          <div
                            className={clsx(
                              classes.labelsTitle,
                              'label-title'
                            )}>
                            Private Duty Nurse / Medical Attendant
                          </div>
                          <SetHtml
                            item={item.plan.meta.basic.nurseMedicalAttendant}
                          />
                        </div>

                        <div
                          className={clsx(
                            classes.itemCell,
                            'item-cell',
                            'rentalPurchaseMedicalAppliances'
                          )}
                          data-key="rentalPurchaseMedicalAppliances">
                          <div
                            className={clsx(
                              classes.labelsTitle,
                              'label-title'
                            )}>
                            Rental / Purchase of Medical Appliances
                          </div>
                          <SetHtml
                            item={
                              item.plan.meta.basic
                                .rentalPurchaseMedicalAppliances
                            }
                          />
                        </div>

                        <div
                          className={clsx(
                            classes.itemCell,
                            'item-cell',
                            'sideTripBenefits'
                          )}
                          data-key="sideTripBenefits">
                          <div
                            className={clsx(
                              classes.labelsTitle,
                              'label-title'
                            )}>
                            Side-Trips Benefit (with in Canada and outside of
                            Canada)
                          </div>
                          <SetHtml
                            item={item.plan.meta.basic.sideTripBenefits}
                          />
                        </div>

                        <div
                          className={clsx(
                            classes.groupCell,
                            'item-cell',
                            'groupEnhanced'
                          )}
                          data-key="groupEnhanced">
                          <div
                            className={clsx(
                              classes.labelsTitle,
                              'label-title'
                            )}>
                            Enhanced Services
                          </div>
                        </div>

                        <div
                          className={clsx(
                            classes.itemCell,
                            'item-cell',
                            'emergencySpecialistService'
                          )}
                          data-key="emergencySpecialistService">
                          <div
                            className={clsx(
                              classes.labelsTitle,
                              'label-title'
                            )}>
                            Emergency Services of Chiropractor, Chiropodist,
                            Physiotherapist, Osteopath, or Podiatrist
                          </div>
                          <SetHtml
                            item={
                              item.plan.meta.enhanced.emergencySpecialistService
                            }
                          />
                        </div>

                        <div
                          className={clsx(
                            classes.itemCell,
                            'item-cell',
                            'acupuncture'
                          )}
                          data-key="acupuncture">
                          <div
                            className={clsx(
                              classes.labelsTitle,
                              'label-title'
                            )}>
                            Acupuncture
                          </div>
                          <SetHtml item={item.plan.meta.enhanced.acupuncture} />
                        </div>

                        <div
                          className={clsx(
                            classes.itemCell,
                            'item-cell',
                            'accidentalDeath'
                          )}
                          data-key="accidentalDeath">
                          <div
                            className={clsx(
                              classes.labelsTitle,
                              'label-title'
                            )}>
                            Accidental Death
                          </div>
                          <SetHtml
                            item={item.plan.meta.enhanced.accidentalDeath}
                          />
                        </div>

                        <div
                          className={clsx(
                            classes.itemCell,
                            'item-cell',
                            'doubleDismemberment'
                          )}
                          data-key="doubleDismemberment">
                          <div
                            className={clsx(
                              classes.labelsTitle,
                              'label-title'
                            )}>
                            Double Dismembernent
                          </div>
                          <SetHtml
                            item={item.plan.meta.enhanced.doubleDismemberment}
                          />
                        </div>
                        <div
                          className={clsx(
                            classes.itemCell,
                            'item-cell',
                            'singleDismemberment'
                          )}
                          data-key="singleDismemberment">
                          <div
                            className={clsx(
                              classes.labelsTitle,
                              'label-title'
                            )}>
                            Single Dismembernent
                          </div>
                          <SetHtml
                            item={item.plan.meta.enhanced.singleDismemberment}
                          />
                        </div>

                        <div
                          className={clsx(
                            classes.itemCell,
                            'item-cell',
                            'bedsideCompanionAccommodation'
                          )}
                          data-key="bedsideCompanionAccommodation">
                          <div
                            className={clsx(
                              classes.labelsTitle,
                              'label-title'
                            )}>
                            Bedside Companion Accommodation / Transportation
                          </div>
                          <SetHtml
                            item={
                              item.plan.meta.enhanced
                                .bedsideCompanionAccommodation
                            }
                          />
                        </div>

                        <div
                          className={clsx(
                            classes.itemCell,
                            'item-cell',
                            'mealsHospitalAllowance'
                          )}
                          data-key="mealsHospitalAllowance">
                          <div
                            className={clsx(
                              classes.labelsTitle,
                              'label-title'
                            )}>
                            Meals and Accommodation / Hospital Allowance /
                            Out-of-pocket Expenses
                          </div>
                          <SetHtml
                            item={
                              item.plan.meta.enhanced.mealsHospitalAllowance
                            }
                          />
                        </div>

                        <div
                          className={clsx(
                            classes.itemCell,
                            'item-cell',
                            'maternityBenefits'
                          )}
                          data-key="maternityBenefits">
                          <div
                            className={clsx(
                              classes.labelsTitle,
                              'label-title'
                            )}>
                            Maternity Benefits / Delivery Coverage
                          </div>
                          <SetHtml
                            item={item.plan.meta.enhanced.maternityBenefits}
                          />
                        </div>

                        <div
                          className={clsx(
                            classes.itemCell,
                            'item-cell',
                            'physicalExamination'
                          )}
                          data-key="physicalExamination">
                          <div
                            className={clsx(
                              classes.labelsTitle,
                              'label-title'
                            )}>
                            Physical Examination (Non-emergency)
                          </div>
                          <SetHtml
                            item={item.plan.meta.enhanced.physicalExamination}
                          />
                        </div>

                        <div
                          className={clsx(
                            classes.itemCell,
                            'item-cell',
                            'eyeExamination'
                          )}
                          data-key="eyeExamination">
                          <div
                            className={clsx(
                              classes.labelsTitle,
                              'label-title'
                            )}>
                            Eye Examination (Non-emergency)
                          </div>
                          <SetHtml
                            item={item.plan.meta.enhanced.eyeExamination}
                          />
                        </div>

                        <div
                          className={clsx(
                            classes.itemCell,
                            'item-cell',
                            'vaccines'
                          )}
                          data-key="vaccines">
                          <div
                            className={clsx(
                              classes.labelsTitle,
                              'label-title'
                            )}>
                            Vaccines (Non-emergency)
                          </div>
                          <SetHtml item={item.plan.meta.enhanced.vaccines} />
                        </div>

                        <div
                          className={clsx(
                            classes.itemCell,
                            'item-cell',
                            'childCareEscortExpenses'
                          )}
                          data-key="childCareEscortExpenses">
                          <div
                            className={clsx(
                              classes.labelsTitle,
                              'label-title'
                            )}>
                            Child Care / Escort Expenses
                          </div>
                          <SetHtml
                            item={
                              item.plan.meta.enhanced.childCareEscortExpenses
                            }
                          />
                        </div>

                        <div
                          className={clsx(
                            classes.itemCell,
                            'item-cell',
                            'psychiatricPsychological'
                          )}
                          data-key="psychiatricPsychological">
                          <div
                            className={clsx(
                              classes.labelsTitle,
                              'label-title'
                            )}>
                            Psychiatric / Psychological
                          </div>
                          <SetHtml
                            item={
                              item.plan.meta.enhanced.psychiatricPsychological
                            }
                          />
                        </div>

                        <div
                          className={clsx(
                            classes.itemCell,
                            'item-cell',
                            'returnOfaVehicle'
                          )}
                          data-key="returnOfaVehicle">
                          <div
                            className={clsx(
                              classes.labelsTitle,
                              'label-title'
                            )}>
                            Return of a Vehicle
                          </div>
                          <SetHtml
                            item={item.plan.meta.enhanced.returnOfaVehicle}
                          />
                        </div>

                        <div
                          className={clsx(
                            classes.itemCell,
                            'item-cell',
                            'sportsInjuriesCoverage'
                          )}
                          data-key="sportsInjuriesCoverage">
                          <div
                            className={clsx(
                              classes.labelsTitle,
                              'label-title'
                            )}>
                            Sports Injuries Coverage
                          </div>
                          <SetHtml
                            item={
                              item.plan.meta.enhanced.sportsInjuriesCoverage
                            }
                          />
                        </div>

                        <div
                          className={clsx(
                            classes.itemCell,
                            'item-cell',
                            'flightTravelAccident'
                          )}
                          data-key="flightTravelAccident">
                          <div
                            className={clsx(
                              classes.labelsTitle,
                              'label-title'
                            )}>
                            Flight / Travel Accident
                          </div>
                          <SetHtml
                            item={item.plan.meta.enhanced.flightTravelAccident}
                          />
                        </div>

                        <div
                          className={clsx(
                            classes.itemCell,
                            'item-cell',
                            'tripBreakBenefit'
                          )}
                          data-key="tripBreakBenefit">
                          <div
                            className={clsx(
                              classes.labelsTitle,
                              'label-title'
                            )}>
                            Trip-Break Benefit
                          </div>
                          <SetHtml
                            item={item.plan.meta.enhanced.tripBreakBenefit}
                          />
                        </div>

                        {insuranceType === 'student' && (
                          <>
                            <div
                              className={clsx(
                                classes.itemCell,
                                'item-cell',
                                'commonCarrier'
                              )}
                              data-key="commonCarrier">
                              <div
                                className={clsx(
                                  classes.labelsTitle,
                                  'label-title'
                                )}>
                                Common Carrier
                              </div>
                              <SetHtml
                                item={item.plan.meta.enhanced.commonCarrier}
                              />
                            </div>

                            <div
                              className={clsx(
                                classes.itemCell,
                                'item-cell',
                                'correctiveDevices'
                              )}
                              data-key="correctiveDevices">
                              <div
                                className={clsx(
                                  classes.labelsTitle,
                                  'label-title'
                                )}>
                                Corrective Devices
                              </div>
                              <SetHtml
                                item={item.plan.meta.enhanced.correctiveDevices}
                              />
                            </div>

                            <div
                              className={clsx(
                                classes.itemCell,
                                'item-cell',
                                'prescriptionGlassesLensesHearingAids'
                              )}
                              data-key="prescriptionGlassesLensesHearingAids">
                              <div
                                className={clsx(
                                  classes.labelsTitle,
                                  'label-title'
                                )}>
                                Prescription Glasses / Contact lenses / Hearing
                                Aids
                              </div>
                              <SetHtml
                                item={
                                  item.plan.meta.enhanced
                                    .prescriptionGlassesLensesHearingAids
                                }
                              />
                            </div>

                            <div
                              className={clsx(
                                classes.itemCell,
                                'item-cell',
                                'sexualHealthConsultation'
                              )}
                              data-key="sexualHealthConsultation">
                              <div
                                className={clsx(
                                  classes.labelsTitle,
                                  'label-title'
                                )}>
                                Sexual Health Consultation
                              </div>
                              <SetHtml
                                item={
                                  item.plan.meta.enhanced
                                    .sexualHealthConsultation
                                }
                              />
                            </div>

                            <div
                              className={clsx(
                                classes.itemCell,
                                'item-cell',
                                'terrorismCoverage'
                              )}
                              data-key="terrorismCoverage">
                              <div
                                className={clsx(
                                  classes.labelsTitle,
                                  'label-title'
                                )}>
                                Terrorism Coverage( Only for Outbound Insured)
                              </div>
                              <SetHtml
                                item={item.plan.meta.enhanced.terrorismCoverage}
                              />
                            </div>

                            <div
                              className={clsx(
                                classes.itemCell,
                                'item-cell',
                                'traumaCounselling'
                              )}
                              data-key="traumaCounselling">
                              <div
                                className={clsx(
                                  classes.labelsTitle,
                                  'label-title'
                                )}>
                                Trauma Counselling
                              </div>
                              <SetHtml
                                item={item.plan.meta.enhanced.traumaCounselling}
                              />
                            </div>

                            <div
                              className={clsx(
                                classes.itemCell,
                                'item-cell',
                                'tuberculosisTestingVaccination'
                              )}
                              data-key="tuberculosisTestingVaccination">
                              <div
                                className={clsx(
                                  classes.labelsTitle,
                                  'label-title'
                                )}>
                                Tuberculosis Testing and Vaccination
                              </div>
                              <SetHtml
                                item={
                                  item.plan.meta.enhanced
                                    .tuberculosisTestingVaccination
                                }
                              />
                            </div>

                            <div
                              className={clsx(
                                classes.itemCell,
                                'item-cell',
                                'tuitionReimbursement'
                              )}
                              data-key="tuitionReimbursement">
                              <div
                                className={clsx(
                                  classes.labelsTitle,
                                  'label-title'
                                )}>
                                Tuition Reimbursement in Relation to Emergency
                              </div>
                              <SetHtml
                                item={
                                  item.plan.meta.enhanced.tuitionReimbursement
                                }
                              />
                            </div>

                            <div
                              className={clsx(
                                classes.itemCell,
                                'item-cell',
                                'tutorialServices'
                              )}
                              data-key="tutorialServices">
                              <div
                                className={clsx(
                                  classes.labelsTitle,
                                  'label-title'
                                )}>
                                Tutorial Services
                              </div>
                              <SetHtml
                                item={item.plan.meta.enhanced.tutorialServices}
                              />
                            </div>

                            <div
                              className={clsx(
                                classes.itemCell,
                                'item-cell',
                                'wisdomTeethRemoval'
                              )}
                              data-key="wisdomTeethRemoval">
                              <div
                                className={clsx(
                                  classes.labelsTitle,
                                  'label-title'
                                )}>
                                Wisdom Teeth Removal
                              </div>
                              <SetHtml
                                item={
                                  item.plan.meta.enhanced.wisdomTeethRemoval
                                }
                              />
                            </div>
                          </>
                        )}

                        <div
                          className={clsx(
                            classes.itemCell,
                            'item-cell',
                            'underwrittenBy'
                          )}
                          data-key="underwrittenBy">
                          <div
                            className={clsx(
                              classes.labelsTitle,
                              'label-title'
                            )}>
                            Underwritten By
                          </div>
                          <SetHtml item={item.plan.meta.underwrittenBy} />
                        </div>
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Comparison;
