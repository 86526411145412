import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 0,
    marginRight: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.white
    }
  },
  outlinedPrimary: {
    backgroundColor: theme.palette.primary.light,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.white
    }
  }
}));

const Radio = ({
  className,
  error,
  selectedValue,
  handleSelect,
  value,
  children
}) => {
  const classes = useStyles();
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    setIsActive(selectedValue === value);
  }, [selectedValue, value]);

  return (
    <>
      <Button
        className={className}
        style={error ? { borderColor: 'red', color: 'red' } : {}}
        classes={{
          root: classes.root, // class name, e.g. `classes-nesting-root-x`,
          outlinedPrimary: classes.outlinedPrimary
        }}
        color={isActive ? 'primary' : 'default'}
        onClick={() => {
          handleSelect(value);
        }}
        variant="outlined">
        {children}
      </Button>
    </>
  );
};

export default Radio;
