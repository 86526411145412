import React, { useEffect, useRef } from 'react';
import InputMask from 'react-input-mask';
import moment from 'moment';
import { TextField as MuiTextField, Typography } from '@material-ui/core';
import { Radio, RadioGroup, Toggler } from 'components';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { NO_OF_CHILDREN } from 'common/Constants';
import useStyles from '../../formStyles';
import clsx from 'clsx';
import { dobToAge } from 'common/Helper';

const Form2 = ({
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  handleChange,
  handleBlur,
  validateField,
  values
}) => {
  const classes = useStyles();
  const age1Input = useRef(null);

  useEffect(() => {
    age1Input.current.focus();
  }, []);

  return (
    <>
      <Typography className={classes.titleInline} variant="h4" component="h2">
        Primary applicant details
      </Typography>
      <div className={classes.formRow}>
        <div className={classes.dFlex}>
          <Field
            inputRef={age1Input}
            className={classes.age}
            component={TextField}
            label="Age"
            name="age1"
            InputProps={{
              onChange: (e) => {
                handleChange(e);
                setFieldTouched('dob1', true);
                setFieldValue('dob1', '');
              }
            }}
            variant="outlined"
          />

          <div className={classes.or}>or</div>

          <InputMask
            className={classes.dob}
            error={errors.dob1 && touched.dob1}
            fullWidth
            helperText={errors.dob1 && touched.dob1 && errors.dob1}
            label="Date of Birth"
            mask={process.env.REACT_APP_DATE_MASK_FORMAT}
            maskPlaceholder={process.env.REACT_APP_DATE_FORMAT}
            name="dob1"
            onBlur={handleBlur}
            onChange={(e) => {
              handleChange(e);
              //validateField(e.target.name);
              if (
                moment(
                  e.target.value,
                  process.env.REACT_APP_DATE_FORMAT,
                  true
                ).isValid()
              ) {
                setFieldTouched('age1', true);
                setFieldValue('age1', dobToAge(e.target.value));
                validateField('age1');
              }
            }}
            value={values.dob1}
            variant="outlined">
            <MuiTextField
              InputLabelProps={{ shrink: true }}
              placeholder={process.env.REACT_APP_DATE_FORMAT}
            />
          </InputMask>
        </div>

        <Toggler>
          <Typography variant="body2">
            Age is the major factor in calculating super visa quotes.
          </Typography>
        </Toggler>
      </div>

      <Typography className={classes.titleInline} variant="h4" component="h2">
        Spouse details
      </Typography>

      <div className={classes.formRow}>
        <div className={classes.dFlex}>
          <Field
            className={classes.age}
            component={TextField}
            label="Age"
            name="age2"
            InputProps={{
              onChange: (e) => {
                handleChange(e);
                setFieldTouched('dob2', true);
                setFieldValue('dob2', '');
              }
            }}
            variant="outlined"
          />

          <div className={classes.or}>or</div>

          <InputMask
            className={classes.dob}
            error={errors.dob2 && touched.dob2}
            fullWidth
            helperText={errors.dob2 && touched.dob2 && errors.dob2}
            label="Date of Birth"
            mask={process.env.REACT_APP_DATE_MASK_FORMAT}
            maskPlaceholder={process.env.REACT_APP_DATE_FORMAT}
            name="dob2"
            onBlur={handleBlur}
            onChange={(e) => {
              handleChange(e);
              //validateField(e.target.name);
              if (
                moment(
                  e.target.value,
                  process.env.REACT_APP_DATE_FORMAT,
                  true
                ).isValid()
              ) {
                setFieldTouched('age2', true);
                setFieldValue('age2', dobToAge(e.target.value));
                validateField('age2');
              }
            }}
            value={values.dob2}
            variant="outlined">
            <MuiTextField
              InputLabelProps={{ shrink: true }}
              placeholder={process.env.REACT_APP_DATE_FORMAT}
            />
          </InputMask>
        </div>

        <Toggler>
          <Typography variant="body2">
            Age is the major factor in calculating super visa quotes.
          </Typography>
        </Toggler>
      </div>

      <Typography className={classes.titleInline} variant="h4" component="h2">
        Misc. details
      </Typography>

      <div className={classes.formRow}>
        <Field
          component={TextField}
          fullWidth
          label="No. of children?"
          name="children"
          onChange={handleChange}
          select
          // eslint-disable-next-line react/jsx-sort-props
          SelectProps={{ native: true }}
          value={values.children}
          variant="outlined">
          {NO_OF_CHILDREN.map((item) => (
            <option value={item} key={item}>
              {item}
            </option>
          ))}
        </Field>
        <Toggler>
          <Typography variant="body2">Total number of children.</Typography>
        </Toggler>
      </div>

      <div className={clsx(classes.formRow)}>
        <RadioGroup
          error={!!(errors.havePreConditions1 && touched.havePreConditions1)}
          defaultValue={values.havePreConditions1}
          onSelect={(value) => {
            setFieldValue('havePreConditions1', value);
          }}>
          {(props) => (
            <>
              <Typography gutterBottom>
                Would you like to cover pre-existing medical conditions?
              </Typography>
              <Radio value="true" {...props}>
                Yes
              </Radio>
              <Radio value="false" {...props}>
                No
              </Radio>
              <Typography
                variant="body2"
                color={
                  touched.havePreConditions1 && errors.havePreConditions1
                    ? 'error'
                    : 'textPrimary'
                }>
                {errors.havePreConditions1 &&
                  touched.havePreConditions1 &&
                  errors.havePreConditions1}
              </Typography>
            </>
          )}
        </RadioGroup>
        <Toggler>
          <Typography variant="body2">
            A pre-existing medical condition usually means any medical condition
            or symptoms for which medical advice, diagnosis, or treatment was
            recommended or received by a doctor or other practitioner at any
            time prior to your departure date. These medical conditions or
            symptoms are described in the insurance policy.
          </Typography>
        </Toggler>
      </div>
    </>
  );
};

export default Form2;
