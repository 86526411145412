import Yup from 'schemas/Yup';
import moment from 'moment';

const age = Yup.number()
  .required('Required')
  .min(10, 'Age should be minimum 0 years')
  .max(70, 'Age should not be greater than 70');

const days = Yup.number().max(365, 'Days should not be greater than 365');

const startDate = Yup.date()
  .format(process.env.REACT_APP_DATE_FORMAT)
  .required('Enter a coverage start date')
  .max(
    moment().add(2, 'years'),
    `Please enter a date less than or equal to ${moment()
      .add(2, 'years')
      .format(process.env.REACT_APP_DATE_FORMAT)}`
  )
  .typeError(
    `Please enter a valid start date in ${process.env.REACT_APP_DATE_FORMAT} format`
  );

const endDate = Yup.date()
  .format(process.env.REACT_APP_DATE_FORMAT)
  .required('Enter a coverage end date')
  .typeError(
    `Please enter a valid end date in ${process.env.REACT_APP_DATE_FORMAT} format`
  );

export const studentSchema = Yup.object().shape({
  coverageType: Yup.string().required(),
  startDate1: startDate,
  endDate1: endDate.when('startDate1', (startDate1, schema) => {
    if (startDate1) {
      return schema.min(
        startDate1,
        'Should be greater than or equals to start date'
      );
    } else {
      return schema;
    }
  }),
  days1: days,
  age1: age,
  age2: Yup.number().when('coverageType', (coverageType, schema) => {
    if (coverageType === 'couple' || coverageType === 'family') {
      return schema.required('Spouse age is required');
    } else {
      return schema.nullable();
    }
  }),
  dependents: Yup.number()
    .min(0)
    .max(15)
    .when('coverageType', (coverageType, schema) => {
      if (coverageType === 'family') {
        return schema.required('dependents is required');
      } else {
        return schema.nullable();
      }
    })
});
