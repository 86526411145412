import React, { useState, useEffect, useRef, useMemo } from 'react';
import useStyles from './styles';
import { Field, Form, Formik } from 'formik';
import {
  Button,
  Grid,
  TextField as MuiTextField,
  Typography
} from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from 'formik-material-ui';
import FormikErrorFocus from 'formik-error-focus';
import {
  superVisitorSingleSchema,
  superVisitorCoupleSchema
} from 'schemas/order';
import InputMask from 'react-input-mask';
import moment from 'moment';
import { COUNTRIES } from 'common/Constants';
import { Toggler } from 'components';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import { Email, Couple, Single } from './components';
import * as userApi from 'api/userApi';
import queryString from 'query-string';
import { toast } from 'react-toastify';

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

const scrollToContentTop = (ref) => {
  if (window) {
    window.scrollTo(0, ref.current.offsetTop);
  }
};

const Order = ({ location }) => {
  const classes = useStyles();
  const [contact, setContact] = useState({ name: '', phone: '', email: '' });
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const mainContent = useRef(null);
  const loaded = useRef(false);
  const [query, setQuery] = useState({});

  useEffect(() => {
    scrollToContentTop(mainContent);
    setQuery(queryString.parse(window.location.search));
  }, []);

  useEffect(() => {
    setContact(JSON.parse(localStorage.getItem('contact') || ''));
    scrollToContentTop(mainContent);
  }, []);

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_PUBLIC_GOOGLE_API_KEY}&libraries=places&type=(regions)`,
        document.querySelector('head'),
        'google-maps'
      );
    }

    loaded.current = true;
  }

  const handleAddressChange = (event) => {
    setInputValue(event.target.value);
  };

  const fetch = useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions([]);
      return undefined;
    }

    fetch(
      {
        input: inputValue,
        types: ['address'],
        componentRestrictions: { country: 'ca' }
      },
      (results) => {
        if (active) {
          setOptions(results || []);
        }
      }
    );

    return () => {
      active = false;
    };
  }, [inputValue, fetch]);

  return (
    <div ref={mainContent}>
      <Formik
        enableReinitialize
        initialValues={(() => {
          let values = {
            ...query,
            origin: query.origin === 'CA' ? '' : query.origin,
            applicantName1: '',
            dob1: query.dob1
              ? moment(query.dob1, 'DD-MM-YYYY').format('DD-MM-YYYY')
              : '',
            address: '',
            arrivalDate:
              moment(query.startDate1, 'DD-MM-YYYY') > moment()
                ? query.startDate1
                : '',
            beneficiary: ''
          };

          if (query.coverageType && query.coverageType === 'couple') {
            return {
              ...values,
              applicantName2: '',
              dob2: query.dob2
                ? moment(query.dob2, 'DD-MM-YYYY').format('DD-MM-YYYY')
                : ''
            };
          }

          return values;
        })()}
        validationSchema={(() => {
          if (query.coverageType && query.coverageType === 'couple') {
            return superVisitorCoupleSchema;
          } else {
            return superVisitorSingleSchema;
          }
        })()}
        onSubmit={(values, { setSubmitting }) => {
          const emailBody = Email({ ...values, ...contact });
          userApi
            .sendEmailAdmin({
              subject: `New ${values.insuranceType} insurance order submission`,
              message: emailBody,
              messageType: 'html'
            })
            .then(() => {
              setSubmitting(false);
              window.location.href = '/order-confirmation';
            })
            .catch((err) => {
              setSubmitting(false);
              console.log(err);
              toast.error(
                'Network error: Order form submission failed, please try again or contact support!'
              );
            });
        }}>
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          setFieldValue
        }) => (
          <Form>
            <Typography variant="h3" className={classes.title}>
              Applicant Details
            </Typography>
            {query.coverageType && query.coverageType === 'couple' ? (
              <Couple
                {...{
                  query,
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange
                }}
              />
            ) : (
              <Single
                {...{
                  query,
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange
                }}
              />
            )}

            <Typography variant="h3" className={classes.title}>
              Destination Details
            </Typography>

            <div className={classes.formRowHelp}>
              <Autocomplete
                getOptionLabel={(option) => {
                  // additional logic to get complete 100% accurate details (if required)
                  return typeof option === 'string'
                    ? option
                    : option.description;
                }}
                name="address"
                filterOptions={(x) => x}
                autoComplete
                includeInputInList
                options={options}
                onChange={(e, v, reason) => {
                  if (reason === 'select-option') {
                    setFieldValue('address', v.description);
                  }

                  if (reason === 'clear') {
                    setFieldValue('address', '');
                  }
                }}
                renderInput={(params) => (
                  <MuiTextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password'
                    }}
                    error={errors.address && touched.address}
                    helperText={
                      errors.address && touched.address && errors.address
                    }
                    label="Canadian Address"
                    variant="outlined"
                    onChange={handleAddressChange}
                  />
                )}
                renderOption={(option) => {
                  const matches =
                    option.structured_formatting.main_text_matched_substrings;
                  const parts = parse(
                    option.structured_formatting.main_text,
                    matches.map((match) => [
                      match.offset,
                      match.offset + match.length
                    ])
                  );

                  return (
                    <Grid container alignItems="center">
                      <Grid item>
                        <LocationOnIcon className={classes.icon} />
                      </Grid>
                      <Grid item xs>
                        {parts.map((part, index) => (
                          <span
                            key={index}
                            style={{ fontWeight: part.highlight ? 700 : 400 }}>
                            {part.text}
                          </span>
                        ))}

                        <Typography variant="body2" color="textSecondary">
                          {option.structured_formatting.secondary_text}
                        </Typography>
                      </Grid>
                    </Grid>
                  );
                }}
              />
              <Toggler>
                <Typography variant="body2">
                  Enter the primary Canadian address where the applicants will
                  be staying during their stay.
                </Typography>
              </Toggler>
            </div>

            <div className={classes.formRowHelp}>
              <InputMask
                className={classes.arrivalDate}
                error={errors.arrivalDate && touched.arrivalDate}
                fullWidth
                helperText={
                  errors.arrivalDate &&
                  touched.arrivalDate &&
                  errors.arrivalDate
                }
                label="Arrival/Expected Date"
                mask={process.env.REACT_APP_DATE_MASK_FORMAT}
                maskPlaceholder={process.env.REACT_APP_DATE_FORMAT}
                name="arrivalDate"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.arrivalDate}
                variant="outlined">
                <MuiTextField />
              </InputMask>
              <Toggler>
                <Typography variant="body2">
                  If the applicant is yet not landed in Canada, please enter an
                  expected future date.
                </Typography>
              </Toggler>
            </div>

            <Typography variant="h3" className={classes.title}>
              Misc. Information
            </Typography>

            <div className={classes.formRowHelp}>
              <Field
                component={TextField}
                fullWidth
                label="Country of Origin"
                name="origin"
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true
                }}
                select
                // eslint-disable-next-line react/jsx-sort-props
                SelectProps={{ native: true }}
                value={values.coverage}
                variant="outlined">
                <option key="0" value="">
                  select
                </option>
                {COUNTRIES.map((item) => (
                  <option value={item.value} key={item.value}>
                    {item.label}
                  </option>
                ))}
              </Field>
              <Toggler>
                <Typography variant="body2">
                  Country the applicant will be coming from.
                </Typography>
              </Toggler>
            </div>

            <div className={classes.formRowHelp}>
              <Field
                component={TextField}
                fullWidth
                label="Beneficiary Name"
                name="beneficiary"
                variant="outlined"
              />
              <Toggler>
                <Typography variant="body2">
                  Enter the name of the person who will be taking care of the
                  applicants in case of a medical emergency.
                </Typography>
              </Toggler>
            </div>

            <div className={classes.formRow}>
              <Button
                color="secondary"
                disableElevation
                size="large"
                style={{ width: '100%' }}
                type="submit"
                variant="contained">
                Submit
              </Button>
            </div>
            <FormikErrorFocus
              // See scroll-to-element for configuration options: https://www.npmjs.com/package/scroll-to-element
              offset={0}
              align={'top'}
              focusDelay={200}
              ease={'linear'}
              duration={500}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Order;
