import React, { useEffect, useRef } from 'react';
import InputMask from 'react-input-mask';
import moment from 'moment';
import {
  TextField as MuiTextField,
  Typography,
  InputAdornment
} from '@material-ui/core';
import { Radio, RadioGroup, Toggler } from 'components';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { COVERAGES_SUPER } from 'common/Constants';
import useStyles from '../../formStyles';
import clsx from 'clsx';
import { dobToAge } from 'common/Helper';

const Form2 = ({
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  handleChange,
  handleBlur,
  validateField,
  values
}) => {
  const classes = useStyles();
  const age2Input = useRef(null);

  useEffect(() => {
    age2Input.current.focus();
  }, []);

  return (
    <>
      <div className={classes.formRow}>
        <InputMask
          error={errors.startDate2 && touched.startDate2}
          fullWidth
          helperText={
            errors.startDate2 && touched.startDate2 && errors.startDate2
          }
          label="Start coverage from"
          mask={process.env.DATE_MASK_FORMAT}
          maskPlaceholder={process.env.DATE_FORMAT}
          id="startDate2"
          name="startDate2"
          onBlur={(e) => {
            handleBlur(e);
            if (!errors.startDate2 && !errors.endDate2) {
              const totalDays = moment(
                values.endDate2,
                process.env.REACT_APP_DATE_FORMAT
              ).diff(
                moment(values.startDate2, process.env.REACT_APP_DATE_FORMAT),
                'days'
              );

              if (totalDays !== 365) {
                setFieldValue(
                  'endDate2',
                  moment(values.startDate2, process.env.REACT_APP_DATE_FORMAT)
                    .add(364, 'days')
                    .format(process.env.REACT_APP_DATE_FORMAT)
                );
              }
            }
          }}
          onChange={handleChange}
          value={values.startDate2}
          variant="outlined">
          <MuiTextField />
        </InputMask>
        <Toggler>
          <Typography variant="body2">
            Coverage will start from this date. There will be a waiting period
            if the applicant has already landed in Canada.
          </Typography>
        </Toggler>
      </div>

      <div className={classes.formRow}>
        <Field
          disabled
          fullWidth
          component={TextField}
          id="endDate2"
          name="endDate2"
          label="Coverage up-to"
          value={values.endDate2}
          variant="outlined"
        />
        <Toggler>
          <Typography variant="body2">
            Minimum 365 days, i.e 1 year of coverage is required for super visa
            application. You may extend/cancel your policy anytime. Balance is
            100% refundable.
          </Typography>
        </Toggler>
      </div>

      <div className={classes.formRow}>
        <Field
          component={TextField}
          fullWidth
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>
          }}
          label="Total coverage for 2nd applicant?"
          name="coverage2"
          //onChange={e => setFieldValue('province', e.target.value)}
          onChange={handleChange}
          select
          // eslint-disable-next-line react/jsx-sort-props
          SelectProps={{ native: true }}
          value={values.coverage2}
          variant="outlined">
          <option value="" key="0">
            select
          </option>
          {COVERAGES_SUPER.map((item) => (
            <option value={item.value} key={item.value}>
              {item.label}
            </option>
          ))}
        </Field>
        <Toggler>
          <Typography variant="body2">
            This will be the total coverage amount. Benefit details differ from
            company to company. Details available on quotes page.
          </Typography>
        </Toggler>
      </div>

      <div className={classes.formRow}>
        <div className={classes.dFlex}>
          <Field
            inputRef={age2Input}
            className={classes.age}
            component={TextField}
            label="Age - 2"
            name="age2"
            InputProps={{
              onChange: (e) => {
                handleChange(e);
                setFieldTouched('dob2', true);
                setFieldValue('dob2', '');
              }
            }}
            variant="outlined"
          />

          <div className={classes.or}>or</div>

          <InputMask
            className={classes.dob}
            error={errors.dob2 && touched.dob2}
            fullWidth
            helperText={errors.dob2 && touched.dob2 && errors.dob2}
            label="Date of Birth"
            mask={process.env.REACT_APP_DATE_MASK_FORMAT}
            maskPlaceholder={process.env.REACT_APP_DATE_FORMAT}
            name="dob2"
            onBlur={handleBlur}
            onChange={(e) => {
              handleChange(e);
              //validateField(e.target.name);
              if (
                moment(
                  e.target.value,
                  process.env.REACT_APP_DATE_FORMAT,
                  true
                ).isValid()
              ) {
                setFieldTouched('age2', true);
                setFieldValue('age2', dobToAge(e.target.value));
                validateField('age2');
              }
            }}
            value={values.dob2}
            variant="outlined">
            <MuiTextField
              InputLabelProps={{ shrink: true }}
              placeholder={process.env.REACT_APP_DATE_FORMAT}
            />
          </InputMask>
        </div>

        <Toggler>
          <Typography variant="body2">
            Age is the major factor in calculating super visa quotes.
          </Typography>
        </Toggler>
      </div>

      <div className={clsx(classes.formRow)}>
        <RadioGroup
          error={!!(errors.havePreConditions2 && touched.havePreConditions2)}
          defaultValue={values.havePreConditions2}
          onSelect={(value) => {
            setFieldValue('havePreConditions2', value);
          }}>
          {(props) => (
            <>
              <Typography gutterBottom>
                Cover pre-existing medical conditions for 2nd applicant?
              </Typography>
              <Radio value="true" {...props}>
                Yes
              </Radio>
              <Radio value="false" {...props}>
                No
              </Radio>
              <Typography
                variant="body2"
                color={
                  errors.havePreConditions2 && touched.havePreConditions2
                    ? 'error'
                    : 'textPrimary'
                }>
                {errors.havePreConditions2 &&
                  touched.havePreConditions2 &&
                  errors.havePreConditions2}
              </Typography>
            </>
          )}
        </RadioGroup>
        <Toggler>
          <Typography variant="body2">
            A pre-existing medical condition usually means any medical condition
            or symptoms for which medical advice, diagnosis, or treatment was
            recommended or received by a doctor or other practitioner at any
            time prior to your departure date. These medical conditions or
            symptoms are described in the insurance policy.
          </Typography>
        </Toggler>
      </div>
    </>
  );
};

export default Form2;
