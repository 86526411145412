import React from 'react';
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize: '14px'
  },
  disclaimerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  }
}));

const Disclaimer = ({ disclaimer, children }) => {
  const classes = useStyles();

  if (disclaimer) {
    return (
      <Tooltip
        classes={{
          tooltip: classes.tooltip
        }}
        disableFocusListener
        disableTouchListener
        placement="bottom"
        title={disclaimer}>
        <span className={classes.disclaimerWrapper}>{children}</span>
      </Tooltip>
    );
  } else {
    return <>{children}</>;
  }
};

export default Disclaimer;
