import React from "react";
import { FormLabel } from "@material-ui/core";
import { IconButton, Popover, Typography, Link } from "@material-ui/core";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

const CoverMedicalConditionsTitle = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <FormLabel component="legend">
      Would you like to cover pre-existing medical conditions?
      <IconButton
        aria-describedby={id}
        color="primary"
        aria-label="Help"
        component="span"
        onClick={handleClick}
        size="small"
      >
        <HelpOutlineIcon />
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        id={id}
        onClose={handleClose}
        open={open}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className="pop-over">
          <Typography variant="body1">
            What is a pre-existing medical condition?
          </Typography>
          <Typography variant="body2">
            A pre-existing medical condition usually means any medical condition
            or symptoms for which medical advice, diagnosis, or treatment was
            recommended or received by a doctor or other practitioner at any
            time prior to your departure date. These medical conditions or
            symptoms are described in the insurance policy.{" "}
            <Link href="/guide/pre-existing-medical-conditions" variant="body2">
              learn more
            </Link>
          </Typography>
        </div>
      </Popover>
    </FormLabel>
  );
};

export default CoverMedicalConditionsTitle;
