import React from "react";
import { FormControl, InputLabel, Select } from "@material-ui/core";
import clsx from "clsx";

const Coverage = ({ formik, name, fieldNo = "1", options }) => {
  const { values, setFieldTouched, handleChange } = formik;

  return (
    <div className="frm-grp">
      <div className="fld-row">
        <div
          className={clsx(
            "fld-no",
            `fld-no-${fieldNo}`,
            values.policy_type === "c" && values.differentCoverages && "active"
          )}
        >
          {fieldNo}
        </div>
        <FormControl variant="outlined" className="fld-min-150">
          <InputLabel htmlFor="filled-age-native-simple">Coverage</InputLabel>
          <Select
            native
            value={values[name]}
            label="Coverage"
            name={name}
            onBlur={(_) => {
              setFieldTouched(name, true);
            }}
            onChange={(e) => {
              if (
                formik.values.policy_type === "c" &&
                name === "coverage" &&
                !formik.values.differentCoverages
              ) {
                formik.setFieldValue("coverage_2", e.target.value);
              }
              handleChange(e);
            }}
            inputProps={{
              name: name,
              id: "filled-age-native-simple",
            }}
          >
            {Object.keys(options).map((key) => (
              <option key={key} value={key}>
                {options[key]}
              </option>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );
};

export default Coverage;
