import React from 'react';
import {
  Radio,
  RadioGroup,
  FormLabel,
  FormControlLabel
} from '@material-ui/core';
import { FamilyHelp } from './components';

const CoverageType = ({ formik, options, insuranceType = 'visitor' }) => (
  <>
    <FormLabel component="legend">What type of policy do you want?</FormLabel>
    <RadioGroup
      row
      aria-label="coverageType"
      name="coverageType"
      defaultValue="right"
      value={formik.values.coverageType}
      onChange={formik.handleChange}>
      {Object.keys(options).map((key) => (
        <span key={key}>
          <FormControlLabel
            control={<Radio color="primary" />}
            label={
              <strong style={{ textTransform: 'uppercase' }}>
                {options[key]}
              </strong>
            }
            value={key}
          />
          {key === 'f' && <FamilyHelp insuranceType={insuranceType} />}
        </span>
      ))}
    </RadioGroup>
  </>
);

export default CoverageType;
