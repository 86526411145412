import Yup from 'schemas/Yup';

const planSubmitSchema = Yup.object().shape({
  contact: Yup.string().required('Your name is required'),
  phone: Yup.string()
    .checkNullPhone()
    .required('Phone is required')
    .matches('\\d{3}-\\d{3}-\\d{4}', {
      message: 'Enter a valid phone number',
      excludeEmptyString: true
    }),
  email: Yup.string().nullable().email('Please enter a valid email address')
});

export default planSubmitSchema;
