import { handleResponse, handleError } from './apiUtils';

const baseUrl = process.env.REACT_APP_API_URL + '/util';

export function sendEmailAdmin(data) {
  return fetch(`${baseUrl}/email/admin`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(handleResponse)
    .catch(handleError);
}

export function sendEmail(data) {
  return fetch(`${baseUrl}/email`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(handleResponse)
    .catch(handleError);
}

export function sendSmsAdmin(data) {
  return fetch(`${baseUrl}/sms/admin`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(handleResponse)
    .catch(handleError);
}
