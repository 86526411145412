import * as Yup from 'yup';
import moment from 'moment';

// date transformations and formatters
Yup.addMethod(Yup.date, 'format', function (formats, parseStrict = true) {
  return this.transform(function (value, originalValue) {
    if (
      originalValue === '' ||
      originalValue === process.env.REACT_APP_DATE_FORMAT
    ) {
      return '';
    }

    value = moment(originalValue, formats, parseStrict);

    return value.isValid() ? value.toDate() : '';
  });
});

Yup.addMethod(Yup.date, 'checkNull', function () {
  return this.transform(function (value, originalValue) {
    if (
      originalValue === null ||
      originalValue === '' ||
      originalValue === process.env.REACT_APP_DATE_FORMAT
    ) {
      return null;
    } else {
      return value;
    }
  });
});

Yup.addMethod(Yup.string, 'checkNullPhone', function () {
  return this.transform(function (value, originalValue) {
    return originalValue === process.env.REACT_APP_PHONE_FORMAT ? '' : value;
  });
});

export default Yup;
