import React, { useState } from 'react';
import { useFormik } from 'formik';
import { schema } from './schema';
import config from '../../common/config';
import { KeyboardDatePicker } from '@material-ui/pickers';
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@material-ui/core';
import { getController, setQS, getQS } from '../../helpers';
import { parse, format, isValid, startOfToday } from 'date-fns';
import {
  companiesList,
  lifeInsuranceGroupItemOptions,
  lifeInsuranceGroupItemList,
  optiTermItems,
  criticalIllnessItemsOptions,
  criticalIllnessItemsList,
  criticalIllnessOptions,
  faceAmountsOptionsPopular,
  faceAmountOptions,
  provinceOptions,
  tubaccoGroup,
  tobaccoOptions,
  paymentModeOptions,
  riskOptionsCI,
  riskOptionsLife
} from './config';

const LifeCriticalInsurance = ({ insuranceType, quotePath }) => {
  const [allIllnessesSelected, setAllIllnessesSelected] = useState(false);
  const calculateAge = (d) => {
    let years = 0;

    if (isValid(d)) {
      const today = startOfToday();
      let months = 0;
      let yearToday = Number.parseInt(format(today, 'yyyy'));
      let monthToday = Number.parseInt(format(today, 'M'));
      let dateToday = Number.parseInt(format(today, 'd'));
      let yearDOB = Number.parseInt(format(d, 'yyyy'));
      let monthDOB = Number.parseInt(format(d, 'M'));
      let dateDOB = Number.parseInt(format(d, 'd'));
      years = yearToday - yearDOB;

      if (monthToday >= monthDOB) {
        months = monthToday - monthDOB;
      } else {
        years--;
        months = 12 + monthToday - monthDOB;
      }

      if (dateToday >= dateDOB) {
        // do nothing
      } else {
        months--;

        if (months < 0) {
          months = 11;
          years--;
        }
      }

      //console.log(`Age: ${years} years and ${months} months`);

      if (months >= 6) {
        years++;
      }
    }

    return years;
  };

  const query = getQS();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      age: query.dob
        ? calculateAge(parse(query.dob, 'MMddyyyy', new Date()))
        : '',
      dob: query.dob ? parse(query.dob, 'MMddyyyy', new Date()) : null,
      g: query.g ? query.g : '1',
      tobacco: query.tobacco ? query.tobacco : '0',
      tucgt: query.tucgt ? query.tucgt : '100',
      tucgr: query.tucgr ? query.tucgr : '100',
      tucgl: query.tucgl ? query.tucgl : '100',
      tupip: query.tupip ? query.tupip : '100',
      tuchw: query.tuchw ? query.tuchw : '100',
      tumrj: query.tumrj ? query.tumrj : '100',
      tuvap: query.tuvap ? query.tuvap : '100',
      tuwat: query.tuwat ? query.tuwat : '100',
      tuoth: query.tuoth ? query.tuoth : '100',
      lc: query.lc ? query.lc : '13',
      fa: query.fa ? query.fa : '100000',
      pm: query.pm ? query.pm : '3',
      productType: query.productType
        ? query.productType
        : insuranceType === 'lifeInsurance'
        ? '0-2'
        : '5', // lifeInsurance => 02 | criticalIllness => 5
      pg: query.pg ? query.pg : insuranceType === 'lifeInsurance' ? '0' : '5',
      pi: query.pi ? query.pi : insuranceType === 'lifeInsurance' ? '2' : '0',
      r: query.r ? query.r : 'R',
      ciOp: query.ciOp
        ? query.ciOp.split(':')
        : [] /*Object.keys(criticalIllnessOptions).map((k) => k)*/
    },
    validationSchema: schema,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);

      // set value for smoker
      if (values.tobacco === '1') {
        values.tucgt = '0';
      } else {
        values.tucgt = '100';
      }

      window.location.href = `${
        quotePath
          ? quotePath
          : getController(window.location.pathname) + '/quotes'
      }?${setQS({
        ...values,
        fn: companiesList.join(':'),
        dob: format(values.dob, 'MMddyyyy'),
        ciOp: values.ciOp.join(':')
      })}`;
    }
  });

  const handleProductType = (value, setFieldValue) => {
    // this is only called from termLife & wholeLife
    // CI doesn't have this field;
    const [pg, pi] = value.split('-');

    if (insuranceType === 'lifeInsurance') {
      // if pg:1 (OptiLife) is selected,
      if (pg === '1') {
        setFieldValue('pg', pg);
        setFieldValue('pi', '0'); // set pi:0 (5 year term)
      } else {
        setFieldValue('pg', pg);
        setFieldValue('pi', pi);
      }
    }
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    handleChange,
    setFieldValue,
    isSubmitting
  } = formik;

  return (
    <form onSubmit={handleSubmit}>
      <div className="frm-grp frm-grp-dob">
        <div className="d-flex align-items-center">
          <div className="mr-1 fld-dt fld-dob">
            <KeyboardDatePicker
              autoOk
              maxDate={new Date()}
              format="dd-MM-yyyy"
              error={errors['dob'] && touched['dob']}
              helperText={
                errors['dob'] && touched['dob']
                  ? errors['dob']
                  : `${config.dateFormatDisplay} format`
              }
              label="Date of Birth"
              inputVariant="outlined"
              name="dob"
              onBlur={handleBlur}
              onChange={(d) => {
                setFieldValue('dob', d);
                let age = calculateAge(d);
                setFieldValue('age', age);
              }}
              value={values['dob']}
              variant="inline"
            />
          </div>

          <Typography className="fld-age">
            <span className="fld-age-text">Age</span>{' '}
            <strong className="fld-age-value">{values.age}</strong>
          </Typography>
        </div>
      </div>
      {/* dob */}

      <div className="frm-grp frm-grp-g">
        <FormLabel component="legend" className="mb-1">
          Gender
        </FormLabel>
        <RadioGroup
          aria-label="Gender"
          defaultValue="right"
          name="g"
          row={true}
          value={values.g}
          onChange={handleChange}>
          <FormControlLabel value="1" control={<Radio />} label="Male" />
          <FormControlLabel value="2" control={<Radio />} label="Female" />
        </RadioGroup>
      </div>
      {/* Gender */}

      <div className="frm-grp frm-grp-tobacco">
        <FormLabel component="legend" className="mb-1">
          Have you used Tobacco/Cigarette in the last 12 months?
        </FormLabel>
        <RadioGroup
          aria-label="Tobacco use"
          defaultValue="right"
          name="tobacco"
          row={true}
          value={values.tobacco}
          onChange={handleChange}>
          <FormControlLabel value="1" control={<Radio />} label="Yes" />
          <FormControlLabel value="0" control={<Radio />} label="No" />
        </RadioGroup>
      </div>
      {/* tobacco*/}

      {/*<>
        {values.tobacco === '1' && (
          <div className="frm-grp frm-grp-tobacco-options">
            <Typography variant="body1" className="mb-3">
              Tobacco Use / Habits
            </Typography>

            <div className="mb-3 d-flex">
              {Object.keys(tubaccoGroup).map((key) => (
                <div className="mr-1" key={key}>
                  <div className="frm-grp">
                    <TextField
                      select
                      label={tubaccoGroup[key]}
                      onChange={(e) => {
                        console.log('setting value', key, e.target.value);
                        setFieldValue(key, e.target.value);
                      }}
                      SelectProps={{
                        native: true
                      }}
                      variant="outlined"
                      value={values[key]}>
                      {Object.keys(tobaccoOptions).map((k) => (
                        <option key={k} value={k}>
                          {tobaccoOptions[k]}
                        </option>
                      ))}
                    </TextField>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </>*/}
      {/* TobaccoOptions */}

      <input type="hidden" name="lc" value="13" />
      {/*<div className="frm-grp frm-grp-lc">
        <TextField
          select
          label="Province"
          onChange={(e) => {
            setFieldValue('lc', e.target.value);
          }}
          SelectProps={{
            native: true
          }}
          variant="outlined"
          value={values.lc}>
          {Object.keys(provinceOptions).map((k) => (
            <option key={k} value={k}>
              {provinceOptions[k]}
            </option>
          ))}
        </TextField>
      </div>*/}
      {/* province */}

      <div className="d-flex">
        <div className="mr-1">
          <div className="frm-grp frm-grp-fa">
            <TextField
              select
              label="Coverage Amount"
              onChange={(e) => {
                setFieldValue('fa', e.target.value);
              }}
              SelectProps={{
                native: true
              }}
              variant="outlined"
              value={values.fa}>
              {[
                { value: '0', title: 'Face Amount' },
                ...(faceAmountsOptionsPopular[insuranceType] || []),
                ...faceAmountOptions
              ].map((item, idx) => (
                <option
                  key={`faceAmount-${item.value}-${idx}`}
                  value={item.value}>
                  {item.title}
                </option>
              ))}
            </TextField>
          </div>
          {/* faceAmount */}
        </div>

        <div className="mr-1">
          <div className="frm-grp frm-grp-pm">
            <TextField
              select
              label="Premium Mode"
              onChange={(e) => {
                setFieldValue('pm', e.target.value);
              }}
              SelectProps={{
                native: true
              }}
              variant="outlined"
              value={values.pm}>
              {Object.keys(paymentModeOptions).map((k) => (
                <option key={k} value={k}>
                  {paymentModeOptions[k]}
                </option>
              ))}
            </TextField>
          </div>
          {/* paymentMode */}
        </div>

        <div className="mr-1">
          {insuranceType === 'lifeInsurance' && (
            <>
              <div className="frm-grp frm-grp-product-type">
                <TextField
                  select
                  label="Plan Type"
                  onChange={(e) => {
                    setFieldValue('productType', e.target.value);
                    handleProductType(e.target.value, setFieldValue);
                  }}
                  SelectProps={{
                    native: true
                  }}
                  variant="outlined"
                  value={values.productType}>
                  {lifeInsuranceGroupItemList.map((k) => (
                    <option key={k} value={k}>
                      {lifeInsuranceGroupItemOptions[k]}
                    </option>
                  ))}
                </TextField>
              </div>

              <>
                {values.productType === '1' && (
                  <div className="frm-grp frm-grp-pi">
                    <TextField
                      select
                      label="OptiTerm Plan"
                      onChange={(e) => {
                        setFieldValue('pi', e.target.value);
                      }}
                      SelectProps={{
                        native: true
                      }}
                      variant="outlined"
                      value={values.optiTerm}>
                      {Object.keys(optiTermItems).map((k) => (
                        <option key={k} value={k}>
                          {optiTermItems[k]}
                        </option>
                      ))}
                    </TextField>
                  </div>
                )}
              </>
              {/* OptiTerm Items */}
            </>
          )}
        </div>

        <div className="mr-1">
          <div className="frm-grp frm-grp-r">
            <TextField
              select
              label="Product Type"
              onChange={(e) => {
                setFieldValue('r', e.target.value);
              }}
              SelectProps={{
                native: true
              }}
              variant="outlined"
              value={values.r}>
              {Object.keys(
                insuranceType === 'criticalIllness'
                  ? riskOptionsCI
                  : riskOptionsLife
              ).map((k) => (
                <option key={k} value={k}>
                  {insuranceType === 'criticalIllness'
                    ? riskOptionsCI[k]
                    : riskOptionsLife[k]}
                </option>
              ))}
            </TextField>
          </div>
          {/* risk */}
        </div>
      </div>
      {/* row */}

      <>
        {insuranceType === 'criticalIllness' && (
          <>
            <div className="frm-grp frm-grp-pi">
              <TextField
                select
                label="CI Plan"
                onChange={(e) => {
                  setFieldValue('pi', e.target.value);
                }}
                SelectProps={{
                  native: true
                }}
                variant="outlined"
                value={values.pi}>
                {criticalIllnessItemsList.map((k) => (
                  <option key={k} value={k}>
                    {criticalIllnessItemsOptions[k]}
                  </option>
                ))}
              </TextField>
            </div>

            {/*<div className="frm-grp frm-grp-illnesses">
              <Typography variant="body1" gutterBottom>
                Select the Critical Illnesses that need to be covered by the
                quoted products
              </Typography>

              <div className="mb-1">
                <FormControlLabel
                  checked={allIllnessesSelected}
                  control={
                    <Checkbox
                      inputProps={{ 'aria-label': 'Select All Illnesses' }}
                    />
                  }
                  label="Select All Illnesses"
                  onChange={() => {
                    if (allIllnessesSelected) {
                      setFieldValue('ciOp', []);
                      setAllIllnessesSelected(false);
                    } else {
                      setFieldValue(
                        'ciOp',
                        Object.keys(criticalIllnessOptions)
                      );
                      setAllIllnessesSelected(true);
                    }
                  }}
                />
              </div>

              <>
                {Object.keys(criticalIllnessOptions).map((key) => (
                  <FormControlLabel
                    key={key}
                    checked={values.ciOp.includes(key)}
                    name={`ciOp[${key}]`}
                    control={
                      <Checkbox
                        inputProps={{
                          'aria-label': criticalIllnessOptions[key]
                        }}
                      />
                    }
                    label={criticalIllnessOptions[key]}
                    onChange={(e) => {
                      if (values.ciOp.includes(e.target.value)) {
                        let idx = values.ciOp.indexOf(e.target.value);
                        if (idx > -1) {
                          console.log('idx', idx);

                          setFieldValue(
                            'ciOp',
                            values.ciOp
                              .slice(0, idx)
                              .concat(values.ciOp.slice(idx + 1))
                          );
                        }
                      } else {
                        setFieldValue('ciOp', [...values.ciOp, e.target.value]);
                      }
                    }}
                    value={key}
                  />
                ))}
              </>
            </div>*/}
          </>
        )}
      </>
      {/* criticalIllness Items*/}

      <div className="frm-grp frm-grp-submit">
        <Button type="submit" variant="contained" color="secondary">
          {isSubmitting ? 'SUBMITTING' : 'GET QUOTE'}
        </Button>
      </div>

      {/*<pre>{JSON.stringify(values, null, 2)}</pre>
      <pre>{JSON.stringify(errors, null, 2)}</pre>*/}
    </form>
  );
};

export default LifeCriticalInsurance;
