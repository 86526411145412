import React, { useEffect, useState } from 'react';
import {
  Button,
  FormControlLabel,
  Checkbox,
  Grid,
  Menu,
  MenuItem,
  Typography,
  Link
} from '@material-ui/core';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  STORAGE_URL,
  DEDUCTIBLE_TYPES,
  COVERAGES_STUDENT_INDEX
} from 'common/Constants';
import { Quote } from 'components';
import { Disclaimer, DetailsBtn, Summary } from './components';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    borderLeft: 'solid 1px #eee',
    borderRight: 'solid 1px #eee',
    borderTop: 'solid 1px #eee',

    '&.selected': {
      backgroundColor: '#f9f9f9'
    }
  },
  logo: {
    textAlign: 'center'
  },
  quote: {
    textAlign: 'center'
  },
  ctas: {
    textAlign: 'center'
  },
  ctaPrimary: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  ctaCompare: {
    marginRight: theme.spacing(1)
  },
  ctaRow2: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  imgFluid: {
    maxWidth: '100%',
    height: 'auto'
  }
}));

const Item = ({
  client,
  coverageType,
  insuranceType,
  onAddToCompare,
  onCompareClick,
  onRateSelect,
  query,
  record,
  totalComparePlans
}) => {
  const classes = useStyles();
  const [detailsIsOpen, setDetailsIsOpen] = useState(false);
  const [anchorBuyEl, setAnchorBuyEl] = useState(null);
  const [selected, setSelected] = useState(false);
  const [affiliateURL, setAffiliateURL] = useState(null);

  const handleBuyClick = (event) => {
    setAnchorBuyEl(event.currentTarget);
  };

  const handleBuyClose = () => {
    setAnchorBuyEl(null);
  };

  const toggleDetails = () => {
    setDetailsIsOpen(!detailsIsOpen);
  };

  useEffect(() => {
    if (client && client.affiliates && record.plan._id) {
      client.affiliates.forEach((affiliate) => {
        if (affiliate.planId === record.plan._id) {
          setAffiliateURL(affiliate.url);
        }
      });
    }
  }, []);

  return (
    <div className={clsx('quotes-item', classes.root, selected && 'selected')}>
      <Disclaimer disclaimer={record.disclaimer}>
        <Grid alignItems="center" container spacing={2}>
          <Grid
            item
            xs={6}
            md={4}
            className={clsx('quotes-item-logo', classes.logo)}>
            <div className={clsx('quotes-item-img', classes.imgWrapper)}>
              <img
                src={`${STORAGE_URL}/images/partners/${record.plan.slug}.png`}
                alt={record.plan.name}
                className={classes.imgFluid}
              />
            </div>
          </Grid>
          {/* logo */}

          <Grid
            className={clsx('quotes-item-quote', classes.quote)}
            item
            xs={6}
            md={4}>
            <Quote
              cost={record.cost}
              cost1={record.cost1}
              cost2={record.cost2}
              costMonthly={record.costMonthly}
              coverageType={query.coverageType}
              days1={query.days1}
              days2={query.days2}
              deductible={record.deductible}
              disclaimer={record.disclaimer}
              haveMonthlyPlans={record.plan.haveMonthlyPlans}
              insuranceType={insuranceType}
              slug={record.plan.slug}
            />

            <div className={clsx('quotes-item-deductible')}>
              {insuranceType === 'student' ? (
                <Typography variant="body2">
                  <strong>
                    {COVERAGES_STUDENT_INDEX[record.coverage]
                      ? COVERAGES_STUDENT_INDEX[record.coverage]
                      : record.coverage}
                  </strong>{' '}
                  coverage
                </Typography>
              ) : (
                <Typography variant="body2">
                  Deductible <strong>{record.deductible}</strong> per{' '}
                  {DEDUCTIBLE_TYPES[record.plan.deductibleType]}
                </Typography>
              )}
            </div>
          </Grid>
          {/* quote */}

          <Grid
            className={clsx('quotes-item-ctas', classes.ctas)}
            item
            xs={12}
            md={4}>
            {affiliateURL ? (
              <>
                <Button
                  aria-controls="buy-menu"
                  aria-haspopup="true"
                  className={clsx(
                    'quotes-item-details-buy-cta',
                    classes.ctaPrimary
                  )}
                  color="secondary"
                  disableElevation
                  onClick={handleBuyClick}
                  variant="contained">
                  Buy Now
                </Button>
                <Menu
                  id="buy-now-menu"
                  anchorEl={anchorBuyEl}
                  keepMounted
                  open={Boolean(anchorBuyEl)}
                  onClose={handleBuyClose}>
                  <MenuItem
                    onClick={() => {
                      window.open(affiliateURL, '_blank');
                      handleBuyClose();
                    }}>
                    Buy Directly
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      onRateSelect(record);
                      handleBuyClose();
                    }}>
                    Start Application
                  </MenuItem>
                </Menu>
              </>
            ) : (
              <Button
                className={clsx(
                  'quotes-item-details-buy-cta',
                  classes.ctaPrimary
                )}
                color="secondary"
                disableElevation
                onClick={() => {
                  onRateSelect(record);
                }}
                variant="contained">
                Buy Now
              </Button>
            )}
            <DetailsBtn
              className="quotes-item-details-cta"
              details={record.plan.summary}
              detailsIsOpen={detailsIsOpen}
              toggleDetails={toggleDetails}
            />
            <div className={classes.ctaRow2}>
              {totalComparePlans > 1 && selected && (
                <Button
                  className={classes.ctaCompare}
                  color="secondary"
                  onClick={onCompareClick}
                  variant="outlined">
                  Compare
                </Button>
              )}
              <FormControlLabel
                className="quotes-item-compare-cta"
                control={<Checkbox name="checkedC" />}
                label={totalComparePlans > 1 && selected ? '' : 'Compare'}
                onChange={(e) => {
                  setSelected(e.target.checked);
                  onAddToCompare(record, e.target.checked);
                }}
              />
              <Link
                href="#"
                color="secondary"
                onClick={() => {
                  onRateSelect(record);
                }}>
                Benefit Summary
              </Link>
            </div>
            {totalComparePlans === 1 && selected && (
              <Typography variant="body2">
                Select at least one more plan to compare
              </Typography>
            )}
            {totalComparePlans > 1 && selected && (
              <Typography variant="body2">
                To compare, click the <strong>COMPARE</strong> button
              </Typography>
            )}
          </Grid>

          {detailsIsOpen && (
            <>
              <Summary
                affiliateURL={affiliateURL}
                client={client}
                coverageType={coverageType}
                insuranceType={insuranceType}
                onRateSelect={onRateSelect}
                query={query}
                record={record}
              />
            </>
          )}
        </Grid>
      </Disclaimer>
    </div>
  );
};

export default Item;
