import { makeStyles } from '@material-ui/styles';
import { fade } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  dFlex: {
    display: 'flex'
  },
  age: {
    flex: '0 0 80px'
  },
  dob: {
    flex: '1 1 auto'
  },
  or: {
    flex: '0 0 20px',
    textAlign: 'center',
    marginTop: theme.spacing(2),
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),

      '&:before,&:after': {
        position: 'absolute',
        top: '10px',
        content: '""',
        width: '8px',
        height: '2px',
        backgroundColor: theme.palette.primary.light
      },
      '&:before': {
        right: '100%'
      },
      '&:after': {
        left: '100%'
      }
    }
  },
  formRow: {
    paddingBottom: theme.spacing(5),
    paddingRight: theme.spacing(6),
    position: 'relative'
  },
  formRowLast: {
    paddingBottom: theme.spacing(0)
  },
  title: {
    marginBottom: theme.spacing(5)
  },
  titleInline: {
    marginBottom: theme.spacing(2)
  },
  helpBtn: {
    position: 'absolute',
    right: 0,
    top: theme.spacing(2)
  },
  helpContent: {
    padding: theme.spacing(1),
    backgroundColor: fade(theme.palette.primary.light, 0.4),
    color: theme.palette.black
  }
}));

export default useStyles;
