import * as yup from 'yup';
import config from 'common/config';
import subYears from 'date-fns/subYears';
import format from 'date-fns/format';
import addDays from 'date-fns/addDays';
import subDays from 'date-fns/subDays';
import startOfDay from 'date-fns/startOfDay';
import isValid from 'date-fns/isValid';

const minDOBDate = subYears(new Date(), config.visitors.age.lessThan);
const maxDOBDate = subDays(new Date(), config.visitors.age.minDays);

const minStartDate = startOfDay(new Date());

const single = yup.object().shape({
  coverageType: yup.string().required(),
  dob: yup
    .date()
    .nullable()
    .min(
      minDOBDate,
      `must be later than ${format(minDOBDate, config.dateFormat)}`
    )
    .max(
      maxDOBDate,
      `must be less than ${format(maxDOBDate, config.dateFormat)}`
    )
    .typeError('Enter a valid date in DD-MM-YYYY format'),
  age1: yup
    .number()
    .required('age is required')
    .moreThan(config.visitors.age.moreThan)
    .lessThan(config.visitors.age.lessThan),
  startDate1: yup
    .date()
    .required()
    .min(
      minStartDate,
      `min date should be ${format(minStartDate, config.dateFormat)}`
    )
    .typeError('Enter a valid date in DD-MM-YYYY format'),
  endDate1: yup
    .date()
    .required('end date is required')
    .when('start_date', (start_date, schema) => {
      if (start_date && isValid(start_date)) {
        let minEndDate = addDays(start_date, config.visitors.days.min - 1);
        return schema.min(
          minEndDate,
          `End date should be greater than or equal to ${format(
            minEndDate,
            config.dateFormat
          )}`
        );
      }
    })
    .typeError('Enter a valid date in DD-MM-YYYY format'),
  days1: yup
    .number()
    .required()
    .min(config.visitors.days.min)
    .typeError('Invalid days')
});

const person2couple = yup.object().shape({
  differentDates: yup.boolean().required('differentDays is required'),
  differentCoverages: yup.boolean().required('differentCoverages is required'),
  dob2: yup
    .date()
    .nullable()
    .min(
      minDOBDate,
      `must be later than ${format(minDOBDate, config.dateFormat)}`
    )
    .max(
      maxDOBDate,
      `must be less than ${format(maxDOBDate, config.dateFormat)}`
    )
    .typeError('Enter a valid date in DD-MM-YYYY format'),
  age2: yup
    .number()
    .required('age(2) is required')
    .moreThan(config.visitors.age.moreThan)
    .lessThan(config.visitors.age.lessThan),
  startDate2: yup.date().when('differentDates', {
    is: (differentDates) => !!differentDates,
    then: yup
      .date()
      .min(minStartDate)
      .required('Start date(20 is required')
      .typeError('Enter a valid start date(2) in DD-MM-YYYY format')
  }),
  endDate2: yup
    .date()
    .when('startDate2', (differentDates, startDate2, schema) => {
      if (differentDates && startDate2 && isValid(startDate2)) {
        let minEndDate2 = addDays(startDate2, config.visitors.days.min - 1);
        return schema
          .required('End date (2) is required')
          .min(
            minEndDate2,
            `End date should be greater than or equal to ${format(
              minEndDate2,
              config.dateFormat
            )}`
          );
      }
    })
    .typeError('Enter a valid end date (2)in DD-MM-YYYY format'),
  days2: yup.number().when('differentDates', {
    is: (differentDates) => !!differentDates,
    then: yup
      .number()
      .required('days (2) is required')
      .min(config.visitors.days.min)
      .typeError('Invalid days')
  })
});

const person2family = yup.object().shape({
  dob2: yup
    .date()
    .nullable()
    .min(
      minDOBDate,
      `must be later than ${format(minDOBDate, config.dateFormat)}`
    )
    .max(
      maxDOBDate,
      `must be less than ${format(maxDOBDate, config.dateFormat)}`
    )
    .typeError('Enter a valid date in DD-MM-YYYY format'),
  age2: yup
    .number()
    .required('age(2) is required')
    .moreThan(config.visitors.age.moreThan)
    .lessThan(config.visitors.age.lessThan),
  dependents: yup.number().required()
});

const couple = single.concat(person2couple);
const family = single.concat(person2family);

const schema = yup.lazy((values) => {
  if (values.coverageType === 'single') {
    return single;
  } else if (values.coverageType === 'couple') {
    return couple;
  } else {
    return family;
  }
});

export { single, couple, family, schema };
