import React from 'react';
import { FormControl, InputLabel, Select } from '@material-ui/core';
import config from 'common/config';

const NoOfDependents = ({ formik }) => {
  const { values, handleChange, handleBlur } = formik;

  return (
    <div className="frm-grp">
      <FormControl variant="outlined" className="fld-min-150">
        <InputLabel htmlFor="filled-age-native-simple">
          No of dependents
        </InputLabel>
        <Select
          native
          value={values.dependents}
          label="No of dependents"
          name="dependents"
          onBlur={handleBlur}
          onChange={handleChange}
          inputProps={{
            name: 'dependents',
            id: 'filled-age-native-simple'
          }}>
          {config.noOfDependents.map((key) => (
            <option key={key} value={key}>
              {key}
            </option>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default NoOfDependents;
