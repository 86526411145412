import React from 'react';
import moment from 'moment';
import { TextField as MuiTextField, Typography } from '@material-ui/core';
import InputMask from 'react-input-mask';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import useStyles from '../styles';

const Single = ({
  query,
  values,
  errors,
  touched,
  handleBlur,
  handleChange
}) => {
  const classes = useStyles();

  return (
    <>
      {(() => {
        if (
          query.dob1 &&
          moment(query.dob1, process.env.REACT_APP_DATE_FORMAT, true).isValid()
        ) {
          return (
            <div className={classes.formRowCompact}>
              <Typography variant="subtitle2">Date of Birth</Typography>
              <Typography gutterBottom>{query.dob1}</Typography>
            </div>
          );
        } else {
          return (
            <div className={classes.formRow}>
              <InputMask
                className={classes.dob}
                error={errors.dob1 && touched.dob1}
                fullWidth
                helperText={errors.dob1 && touched.dob1 && errors.dob1}
                label="Date of Birth"
                mask={process.env.REACT_APP_DATE_MASK_FORMAT}
                maskPlaceholder={process.env.REACT_APP_DATE_FORMAT}
                name="dob1"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.dob1}
                variant="outlined">
                <MuiTextField />
              </InputMask>
            </div>
          );
        }
      })()}
      <div className={classes.formRow}>
        <Field
          component={TextField}
          fullWidth
          label="Applicant's Name"
          name="applicantName1"
          variant="outlined"
          values={values.applicantName1}
        />
      </div>
    </>
  );
};

export default Single;
