const ToBoolean = (data, booleans = []) => {
  for (let key in data) {
    // data doesn't have hasOwnProperty
    // so can't or dont need that check

    if (booleans.includes(key)) {
      if (data[key] === 'true' || data[key] === '1') {
        data[key] = true;
      } else {
        data[key] = false;
      }
    }
  }

  return data;
};

export default ToBoolean;
