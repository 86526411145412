import React, { useEffect, useRef } from 'react';
import InputMask from 'react-input-mask';
import moment from 'moment';
import {
  TextField as MuiTextField,
  Typography,
  InputAdornment
} from '@material-ui/core';
import { Radio, RadioGroup, Toggler } from 'components';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { COVERAGES_SUPER } from 'common/Constants';
import useStyles from './../../formStyles';
import clsx from 'clsx';
import { dobToAge } from 'common/Helper';

const Form1 = ({
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  handleChange,
  handleBlur,
  validateField,
  values
}) => {
  const classes = useStyles();
  const ageInput = useRef(null);

  useEffect(() => {
    ageInput.current.focus();
  }, []);

  return (
    <>
      <div className={classes.formRow}>
        <InputMask
          error={errors.startDate1 && touched.startDate1}
          fullWidth
          helperText={
            errors.startDate1 && touched.startDate1 && errors.startDate1
          }
          label="Start coverage from"
          mask={process.env.REACT_APP_DATE_MASK_FORMAT}
          maskPlaceholder={process.env.REACT_APP_DATE_FORMAT}
          id="startDate1"
          name="startDate1"
          onBlur={(e) => {
            handleBlur(e);
            if (!errors.startDate1 && !errors.endDate1) {
              //update startDate2 2nd applicant
              if (
                !moment(
                  values.endDate2,
                  process.env.REACT_APP_DATE_FORMAT,
                  true
                ).isValid()
              ) {
                setFieldValue('startDate2', e.target.value);
              }

              //update endDate (conditionally)
              const totalDays = moment(
                values.endDate1,
                process.env.DATE_FORMAT
              ).diff(
                moment(values.startDate1, process.env.REACT_APP_DATE_FORMAT),
                'days'
              );

              if (totalDays !== 365) {
                setFieldValue(
                  'endDate1',
                  moment(values.startDate1, process.env.REACT_APP_DATE_FORMAT)
                    .add(364, 'days')
                    .format(process.env.REACT_APP_DATE_FORMAT)
                );

                //update endDate 2nd applicant
              }
            }
          }}
          onChange={handleChange}
          value={values.startDate1}
          variant="outlined">
          <MuiTextField />
        </InputMask>
        <Toggler>
          <Typography variant="body2">
            Coverage will start from this date. There will be a waiting period
            if the applicant has already landed in Canada.
          </Typography>
        </Toggler>
      </div>
      <div className={classes.formRow}>
        <Field
          disabled
          fullWidth
          component={TextField}
          id="endDate1"
          name="endDate1"
          label="Coverage up-to"
          value={values.endDate1}
          variant="outlined"
        />
        <Toggler>
          <Typography variant="body2">
            Minimum 365 days, i.e 1 year of coverage is required for super visa
            application. You may extend/cancel your policy anytime. Balance is
            100% refundable.
          </Typography>
        </Toggler>
      </div>
      <div className={classes.formRow}>
        <Field
          component={TextField}
          fullWidth
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>
          }}
          label="Total coverage required?"
          name="coverage1"
          onChange={handleChange}
          select
          // eslint-disable-next-line react/jsx-sort-props
          SelectProps={{ native: true }}
          value={values.coverage1}
          variant="outlined">
          <option value="" key="0">
            select
          </option>
          {COVERAGES_SUPER.map((item) => (
            <option value={item.value} key={item.value}>
              {item.label}
            </option>
          ))}
        </Field>
        <Toggler>
          <Typography variant="body2">
            This will be the total coverage amount. Benefit details differ from
            company to company. Details available on quotes page.
          </Typography>
        </Toggler>
      </div>
      <div className={classes.formRow}>
        <div className={classes.dFlex}>
          <Field
            inputRef={ageInput}
            className={classes.age}
            component={TextField}
            label="Age - 1"
            name="age1"
            InputProps={{
              onChange: (e) => {
                handleChange(e);
                setFieldTouched('dob1', true);
                setFieldValue('dob1', '');
              }
            }}
            variant="outlined"
          />

          <div className={classes.or}>or</div>

          <InputMask
            className={classes.dob}
            error={errors.dob1 && touched.dob1}
            fullWidth
            helperText={errors.dob1 && touched.dob1 && errors.dob1}
            label="Date of Birth"
            mask={process.env.REACT_APP_DATE_MASK_FORMAT}
            maskPlaceholder={process.env.REACT_APP_DATE_FORMAT}
            name="dob1"
            onBlur={handleBlur}
            onChange={(e) => {
              handleChange(e);
              if (
                moment(
                  e.target.value,
                  process.env.REACT_APP_DATE_FORMAT,
                  true
                ).isValid()
              ) {
                setFieldTouched('age1', true);
                setFieldValue('age1', dobToAge(e.target.value));
                validateField('age1');
              }
            }}
            value={values.dob1}
            variant="outlined">
            <MuiTextField
              InputLabelProps={{ shrink: true }}
              placeholder={process.env.REACT_APP_DATE_FORMAT}
            />
          </InputMask>
        </div>

        <Toggler>
          <Typography variant="body2">
            Age is the major factor in calculating super visa quotes.
          </Typography>
        </Toggler>
      </div>

      <div className={clsx(classes.formRow)}>
        <RadioGroup
          error={!!(errors.havePreConditions1 && touched.havePreConditions1)}
          defaultValue={values.havePreConditions1}
          onSelect={(value) => {
            setFieldValue('havePreConditions1', value);
          }}>
          {(props) => (
            <>
              <Typography gutterBottom>
                Would you like to cover pre-existing medical conditions?
              </Typography>
              <Radio value="true" {...props}>
                Yes
              </Radio>
              <Radio value="false" {...props}>
                No
              </Radio>
              <Typography
                variant="body2"
                color={
                  touched.havePreConditions1 && errors.havePreConditions1
                    ? 'error'
                    : 'textPrimary'
                }>
                {errors.havePreConditions1 &&
                  touched.havePreConditions1 &&
                  errors.havePreConditions1}
              </Typography>
            </>
          )}
        </RadioGroup>
        <Toggler>
          <Typography variant="body2">
            A pre-existing medical condition usually means any medical condition
            or symptoms for which medical advice, diagnosis, or treatment was
            recommended or received by a doctor or other practitioner at any
            time prior to your departure date. These medical conditions or
            symptoms are described in the insurance policy.
          </Typography>
        </Toggler>
      </div>
    </>
  );
};

export default Form1;
