import React from 'react';
import { Email, Item, Span, renderEmail } from 'react-html-email';

const css = `
@media only screen {
body {font-family: Helvetica,serif
font-size: 12px;
}}`.trim();

const textStyle1 = {
  fontSize: 12,
  fontFamily: 'Helvetica,serif'
};

const textStyle2 = {
  fontSize: 14,
  fontFamily: 'Helvetica,serif'
};

const cellStyle = {
  borderBottom: 'solid 1px #ccc'
};

const ItemRow = ({ label, children }) => {
  return (
    <tr>
      <td>
        <Span {...{ ...textStyle1, ...cellStyle }}>{label}</Span>
      </td>
      <td>
        <Span {...{ ...textStyle2, ...cellStyle }}>{children}</Span>
      </td>
    </tr>
  );
};

const EmailHTML = (data) => {
  const emailBody = renderEmail(
    <Email title="Plan Submission" headCSS={css}>
      <Item align="center">
        <p {...textStyle1}>New Plan Submission</p>
      </Item>
      <Item>
        <h2>Submitter..</h2>
        <table>
          <tbody>
            <ItemRow label="Contact">{data.contact}</ItemRow>
            <ItemRow label="Phone">{data.phone}</ItemRow>
            <ItemRow label="Email">{data.email}</ItemRow>
            <ItemRow label="Location (IP)">{data.location}</ItemRow>
            <ItemRow label="Website">{data.website}</ItemRow>
            <ItemRow label="Search">{data.search}</ItemRow>
          </tbody>
        </table>
      </Item>
      <Item>
        <table>
          <tbody>
            <ItemRow label="Insurance">{data.insuranceType}</ItemRow>
            <ItemRow label="Coverage">{data.coverageType}</ItemRow>
            <ItemRow label="PlanId">{data.planId}</ItemRow>
            <ItemRow label="Plan">{data.planName}</ItemRow>
          </tbody>
        </table>
      </Item>
      <Item>
        <table>
          <tbody>
            <ItemRow label="Deductible">{data.deductible}</ItemRow>
            <ItemRow label="Age (1)">{data.age1}</ItemRow>
            <ItemRow label="Age (2)">{data.age2}</ItemRow>
            <ItemRow label="DOB (1)">{data.dob1}</ItemRow>
            <ItemRow label="DOB (2)">{data.dob2}</ItemRow>
            <ItemRow label="Coverage (1)">{data.coverage1}</ItemRow>
            <ItemRow label="Coverage (2)">{data.coverage2}</ItemRow>
            <ItemRow label="Coverage for (1)">{data.days1}days</ItemRow>
            <ItemRow label="Coverage for (2)">{data.days2}days</ItemRow>
            <ItemRow label="Pre-existing conditions (1)">
              {data.havePreConditions1 === 'true'
                ? 'coverage required'
                : 'coverage NOT required'}
            </ItemRow>
            <ItemRow label=" Pre-existing conditions (2)">
              {data.havePreConditions2 === 'true'
                ? 'coverage required'
                : 'coverage NOT required'}
            </ItemRow>
            <ItemRow label="Start date (1)">{data.startDate1}</ItemRow>
            <ItemRow label="Start date (2)">{data.startDate2}</ItemRow>
            <ItemRow label="End date (1)">{data.endDate1}</ItemRow>
            <ItemRow label="End date (2)">{data.endDate2}</ItemRow>
          </tbody>
        </table>
      </Item>
    </Email>
  );

  return emailBody;
};

export default EmailHTML;
