import moment from 'moment';

export const toQueryString = (values) => {
  return Object.keys(values)
    .map(
      (key) =>
        key +
        '=' +
        (values[key] === undefined || values[key] === null
          ? ''
          : typeof values[key] === 'string' || values[key] instanceof String
          ? values[key].trim()
          : values[key])
    )
    .join('&');
};

/*
 * convert DD-MM-YYYY to database date format (YYYY-MM-DD)
 */
export const dateToDb = (date) => {
  if (!date) return '';

  return moment(date, process.env.REACT_APP_DATE_FORMAT, true).format(
    'YYYY-MM-DD'
  );
};

export const dbToDate = (date) => {
  if (!date) return '';

  return moment(date, 'YYYY-MM-DD', true).format(
    process.env.REACT_APP_DATE_FORMAT
  );
};

export const dbToDateCalendar = (date) => {
  if (!date) return '';

  return moment(date, 'YYYY-MM-DD', true);
};

export const dobToAge = (value) => {
  const dob = moment(value, process.env.REACT_APP_DATE_FORMAT);
  const age = moment().diff(dob, 'years');
  return isNaN(age) ? '' : age;
};

export const datesToDays = (end, start) => {
  if (!end || !start) {
    return '';
  }

  const endDate = moment(end, process.env.REACT_APP_DATE_FORMAT);
  const startDate = moment(start, process.env.REACT_APP_DATE_FORMAT);
  const days = moment(endDate).diff(startDate, 'days');

  return days + 1;
};

export const toNull = (obj) => {
  Object.keys(obj).forEach((k) => {
    if (obj[k] === '') {
      obj[k] = null;
    }
  });

  return obj;
};

export const objToString = (obj) => {
  let string = '';
  Object.keys(obj).forEach((k) => {
    string = `${string}
    ${k}: ${obj[k]}`;
  });

  return string;
};

export const toBoolean = (obj, keys) => {
  keys.forEach((k) => {
    if (k in obj) {
      if (obj[k] === 'true' || obj[k] === '1') {
        obj[k] = true;
      } else {
        obj[k] = false;
      }
    }
  });

  return obj;
};
