import React, { useEffect, useRef } from 'react';
import { colors, IconButton, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  CoverMedicalConditions,
  CoverMedicalConditionsTitle
} from 'components';
import {
  COVERAGES_SUPER,
  COVERAGES_VISITOR,
  DEDUCTIBLES
} from 'common/Constants';
import clsx from 'clsx';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme) => ({
  rootMeta: {
    backgroundColor: colors.grey[100],
    padding: theme.spacing(1)
  },
  policyTypeC: {
    paddingLeft: 30
  },
  metaAdjust: {
    backgroundColor: colors.grey[100],
    padding: theme.spacing(1, 0),
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',

    [theme.breakpoints.down('sm')]: {
      borderBottom: `solid 4px ${colors.red[600]}`
    }
  },
  metaAdjustItem: {
    margin: theme.spacing(2, '2px', 0, 0),

    [theme.breakpoints.up('md')]: {
      width: '100%',
      margin: theme.spacing(2, 1)
    }
  }
}));

const InsuranceType = ({ type }) => {
  if (type === 'super') {
    return <>Super Visa Insurance</>;
  } else if (type === 'visitor') {
    return <>Visitors to Canada Insurance</>;
  } else if (type === 'student') {
    return <>Student Insurance</>;
  } else {
    return <>Invalid</>;
  }
};

const InsuranceFor = ({ type }) => {
  if (type === 'family') {
    return <>Family</>;
  } else if (type === 'couple') {
    return <>Couple</>;
  } else {
    return <>Single Person</>;
  }
};

const Ages = ({ age1, age2, coverageType }) => {
  if (coverageType === 'single') {
    return <>(age {age1} years)</>;
  } else {
    if (age1 && age2) {
      return (
        <>
          (age {age1} years and {age2} years)
        </>
      );
    } else {
      return <>(age {age1} years)</>;
    }
  }
};

const Days = ({ days1, days2, coverageType }) => {
  if (coverageType === 'single') {
    return <>for {days1} days</>;
  } else {
    if (days1 && days1 !== '' && days2 && days2 !== '') {
      if (days1 === days2) {
        return <>for {days1} days</>;
      } else {
        return (
          <>
            for {days1} and {days2} days
          </>
        );
      }
    } else {
      return <>for {days1} days</>;
    }
  }
};

const HavePreConditions = ({
  insuranceType,
  coverageType,
  havePreConditions1,
  havePreConditions2
}) => {
  if (insuranceType === 'student') {
    return <></>;
  } else {
    if (coverageType === 'couple') {
      if (havePreConditions1 === havePreConditions2) {
        return (
          <>
            {havePreConditions1
              ? 'covering pre-existing medical conditions'
              : 'excluding coverage for pre-existing medical conditions'}
          </>
        );
      } else {
        return (
          <>
            {havePreConditions1
              ? 'covering pre-existing medical conditions for 1st applicant'
              : 'excluding coverage for pre-existing medical conditions for 1st applicant'}{' '}
            and{' '}
            {havePreConditions2
              ? 'covering pre-existing medical conditions for 2nd applicant'
              : 'excluding coverage for pre-existing medical conditions for 2nd applicant'}
          </>
        );
      }
    } else {
      return (
        <>
          {!!havePreConditions1}
          {havePreConditions1
            ? 'covering pre-existing medical conditions'
            : 'excluding coverage for pre-existing medical conditions'}
        </>
      );
    }
  }
};

const MetaInfo = (props) => {
  const classes = useStyles();
  const {
    insuranceType,
    coverageType,
    plan,
    deductible,
    age1,
    coverage1,
    days1,
    havePreConditions1,
    age2,
    coverage2,
    days2,
    havePreConditions2,
    onDeductibleChange,
    onQueryChange,
    onBackClick
  } = props;
  const rootMetaAdjust = useRef(null);
  const metaAdjust = useRef(null);

  // fix meta on top;
  useEffect(() => {
    const handleScroll = () => {
      if (
        window &&
        window.innerWidth < 960 &&
        rootMetaAdjust.current.getBoundingClientRect().y < 1
      ) {
        /*console.log(
          'height',
          metaAdjust.current.getBoundingClientRect().height
        );*/
        rootMetaAdjust.current.setAttribute(
          'style',
          `height:${Math.round(
            metaAdjust.current.getBoundingClientRect().height
          )}px;`
        );
        metaAdjust.current.setAttribute(
          'style',
          'position:fixed;top:0;left:0;right:0;z-index:100000'
        );
      } else {
        rootMetaAdjust.current.removeAttribute('style');
        metaAdjust.current.removeAttribute('style');
      }
    };
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <div className={clsx('wrapper-meta', classes.rootMeta)}>
        <Typography variant="body2" align="center">
          <InsuranceType type={insuranceType} /> for{' '}
          <InsuranceFor type={coverageType} />
          <Ages age1={age1} age2={age2} coverageType={coverageType} />{' '}
          <Days days1={days1} days2={days2} coverageType={coverageType} />
          {', '}
          <HavePreConditions
            insuranceType={insuranceType}
            coverageType={coverageType}
            havePreConditions1={havePreConditions1}
            havePreConditions2={havePreConditions2}
          />
          <IconButton onClick={onBackClick} color="secondary">
            <EditIcon />
          </IconButton>
        </Typography>
      </div>
      <div className={classes.rootMetaAdjust} ref={rootMetaAdjust}>
        <div
          className={clsx('wrapper-meta-adjust', classes.metaAdjust)}
          ref={metaAdjust}>
          {insuranceType !== 'student' && !plan && (
            <div
              className={clsx(
                'meta-adjust-deductible',
                classes.metaAdjustItem
              )}>
              <TextField
                id="deductible"
                fullWidth
                select
                label="Deductible"
                onChange={(e) => {
                  //onDeductibleChange(parseInt(e.target.value));
                  onDeductibleChange(parseInt(e.target.value));
                }}
                SelectProps={{
                  native: true
                }}
                variant="outlined"
                value={deductible}>
                {DEDUCTIBLES.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </TextField>
            </div>
          )}

          {insuranceType !== 'student' && (
            <div
              className={clsx(
                'meta-adjust-coverage-1',
                classes.metaAdjustItem
              )}>
              <TextField
                id="coverage"
                fullWidth
                select
                label={coverageType === 'couple' ? 'Coverage 1st' : 'Coverage'}
                onChange={(e) => {
                  //onCoverage1Change(parseInt(e.target.value));
                  onQueryChange('coverage1', parseInt(e.target.value));
                }}
                SelectProps={{
                  native: true
                }}
                variant="outlined"
                value={coverage1}>
                {(insuranceType === 'super'
                  ? COVERAGES_SUPER
                  : COVERAGES_VISITOR
                ).map((item) => (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </TextField>
            </div>
          )}

          {insuranceType !== 'student' && coverageType === 'couple' && (
            <div
              className={clsx(
                'meta-adjust-coverage-2',
                classes.metaAdjustItem
              )}>
              <TextField
                id="coverage2"
                fullWidth
                select
                label="Coverage 2nd"
                onChange={(e) => {
                  //onCoverage2Change(parseInt(e.target.value));
                  onQueryChange('coverage2', parseInt(e.target.value));
                }}
                SelectProps={{
                  native: true
                }}
                variant="outlined"
                value={coverage2}>
                {(insuranceType === 'super'
                  ? COVERAGES_SUPER
                  : COVERAGES_VISITOR
                ).map((item) => (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </TextField>
            </div>
          )}
        </div>
      </div>

      {insuranceType !== 'student' && (
        <div
          className={clsx(
            'meta-adjust-havePreConditions1',
            classes.rootMeta,
            coverageType === 'couple' ? classes.policyTypeC : ''
          )}>
          <CoverMedicalConditionsTitle />
          <CoverMedicalConditions
            formik={{
              values: props,
              handleChange: (e) => {
                onQueryChange(
                  'havePreConditions1',
                  e.target.value === 'true' || e.target.value === '1'
                    ? true
                    : false
                );
              }
            }}
            name="havePreConditions1"
          />
          {coverageType === 'couple' && (
            <CoverMedicalConditions
              formik={{
                values: props,
                handleChange: (e) => {
                  onQueryChange(
                    'havePreConditions2',
                    e.target.value === 'true' || e.target.value === '1'
                      ? true
                      : false
                  );
                }
              }}
              fieldNo="2"
              name="havePreConditions2"
            />
          )}
        </div>
      )}
    </>
  );
};

export default MetaInfo;
