import { handleResponse, handleError } from './apiUtils';

const baseUrl = process.env.REACT_APP_API_URL + '/quote-requests';

export function post(data) {
  return fetch(baseUrl, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(handleResponse)
    .catch(handleError);
}
