import { makeStyles } from '@material-ui/styles';
import { colors } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize: '14px'
  },
  subTotal: {
    fontSize: '13px',
    color: colors.grey[500]
  }
}));

export default useStyles;
