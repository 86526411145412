export default {
  dateFormat: 'dd-MM-yyyy',
  dateFormatDisplay: 'DD-MM-YYYY',
  policyTypes: {
    superVisa: {
      single: 'Single Coverage',
      couple: 'Couple Policy'
    },
    visitors: {
      single: 'Single Coverage',
      couple: 'Couple Policy',
      family: 'Family Policy'
    },
    internationalStudent: {
      single: 'Single Coverage',
      couple: 'Couple Policy',
      family: 'Family Policy'
    },
    travel: {
      single: 'Single Coverage',
      couple: 'Couple Policy',
      family: 'Family Policy'
    }
  },
  superVisa: {
    days: {
      default: 365,
      min: 365,
      max: 800
    },
    age: {
      moreThan: 39,
      lessThan: 90,
      minDays: 0
    },
    coverages: {
      100000: '100,000 (min. requirement)',
      150000: '150,000',
      200000: '200,000',
      300000: '300,000',
      500000: '500,000',
      1000000: '1,000,000'
    }
  },
  visitors: {
    isEndDateFixed: false,
    days: {
      default: 90,
      min: 1,
      max: 800
    },
    age: {
      moreThan: -1,
      lessThan: 90,
      minDays: 14
    },
    coverages: {
      10000: '10,000',
      15000: '15,000',
      25000: '25,000',
      50000: '50,000',
      100000: '100,000',
      150000: '150,000',
      200000: '200,000',
      250000: '250,000',
      300000: '300,000',
      500000: '500,000',
      1000000: '1,000,000'
    }
  },
  internationalStudent: {
    days: {
      default: 365
    },
    studentTypes: [
      { id: 'IB', title: 'International Students in Canada' },
      { id: 'OB', title: 'Canadian Students Studying Abroad' }
    ]
  },
  travel: {
    tripTypes: [
      { id: 's', title: 'Single Trip' },
      { id: 'm', title: 'Multi-trip' }
    ],
    travelingTo: [
      { id: 'usa', title: 'USA' },
      { id: 'ww', title: 'Worldwide (Non-USA)' },
      { id: 'ca', title: 'Within Canada' }
    ],
    tripDuration: [4, 8, 10, 15, 16, 30, 32, 35, 60, 125]
  },
  YesNo: {
    0: 'No',
    1: 'Yes'
  },
  TrueFalse: {
    false: 'No',
    true: 'Yes'
  },
  noOfDependents: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
};
