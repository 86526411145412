import React from 'react';
import { Link } from '@material-ui/core';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutline from '@material-ui/icons/RemoveCircleOutline';

const DifferentCoverageBtn = ({ formik }) => {
  const { values, setFieldValue } = formik;

  return (
    <div className="frm-grp">
      {values.coverageType === 'couple' && values.differentCoverages && (
        <Link
          className="text-icon"
          href="#"
          onClick={(e) => {
            e.preventDefault();
            setFieldValue('differentCoverages', false);
            setFieldValue('coverage2', values.coverage);
          }}>
          <RemoveCircleOutline fontSize="small" /> Make coverage amount same for
          all applicants
        </Link>
      )}
      {values.coverageType === 'couple' && !values.differentCoverages && (
        <Link
          className="text-icon"
          href="#"
          onClick={(e) => {
            e.preventDefault();
            setFieldValue('differentCoverages', true);
          }}>
          <AddCircleOutline fontSize="small" /> Enter different coverage amount
          per applicant
        </Link>
      )}
    </div>
  );
};

export default DifferentCoverageBtn;
