import React, { useEffect, useState, useRef } from 'react';
import { Formik, Form } from 'formik';
import moment from 'moment';
import { superSingleSchema } from 'schemas/quotes';
import { Form as QuoteForm } from './components';
import { Typography, makeStyles } from '@material-ui/core';
import { toQueryString, dateToDb, dbToDate } from 'common/Helper';
import queryString from 'query-string';
import { getPlanBySlug } from 'api/planApi';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(5)
  }
}));

const scrollToContentTop = (ref) => {
  if (window) {
    window.scrollTo(0, ref.current.offsetTop);
  }
};

const SuperSingle = ({ location, history }) => {
  const classes = useStyles();
  const mainContent = useRef(null);
  const [query, setQuery] = useState({});
  const { slug } = useParams();

  useEffect(() => {
    scrollToContentTop(mainContent);
    setQuery(queryString.parse(location.search));

    //check if slug; get plan & set plan;
    if (slug) {
      getPlanBySlug(slug)
        .then((plan) => {
          setQuery({ ...query, plan: plan._id });
        })
        .catch(console.log);
    }
  }, [slug]);

  return (
    <div ref={mainContent}>
      <Typography className={classes.title} component="h2" variant="h3">
        Enter coverage &amp; applicant's details.
      </Typography>
      <Formik
        enableReinitialize
        initialValues={{
          plan: query.plan ? query.plan : '',
          coverageType: 'single',
          age1: query.age1 ? query.age1 : '',
          dob1: query.dob1 ? dbToDate(query.dob1) : '',
          startDate1: query.startDate1
            ? dbToDate(query.startDate1)
            : moment().format(process.env.REACT_APP_DATE_FORMAT),
          endDate1: query.endDate1
            ? dbToDate(query.endDate1)
            : moment()
                .add(364, 'days')
                .format(process.env.REACT_APP_DATE_FORMAT),
          coverage1: query.coverage1 ? query.coverage1 : '100000',
          havePreConditions1: query.havePreConditions1
            ? query.havePreConditions1
            : ''
        }}
        validationSchema={superSingleSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          //onSubmit(values);
          const url = slug
            ? `/super-visa-insurance/quotes/${slug}`
            : '/super-visa-insurance/quotes';
          history.push(
            `${url}?${toQueryString({
              ...values,
              startDate1: dateToDb(values.startDate1),
              endDate1: dateToDb(values.endDate1),
              dob1: values.dob1 ? dateToDb(values.dob1) : ''
            })}`
          );
          //history.push(url);
        }}>
        {(props) => (
          <Form>
            <QuoteForm {...props} />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SuperSingle;
