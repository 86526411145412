import Yup from 'schemas/Yup';
import moment from 'moment';

const age = Yup.number()
  .required('Required')
  .min(40, 'You must be at least 40 years old to qualify')
  .max(90, 'Age should not be greater than 90');

const dob = Yup.date()
  .format(process.env.REACT_APP_DATE_FORMAT)
  .checkNull()
  .nullable()
  .max(
    moment().subtract(40, 'years'),
    `DOB should be less than or equals to ${moment()
      .subtract(40, 'years')
      .format(process.env.REACT_APP_DATE_FORMAT)}`
  )
  .typeError(
    `Please enter a valid date of birth in ${process.env.REACT_APP_DATE_FORMAT} format`
  );

const startDate = Yup.date()
  .format(process.env.REACT_APP_DATE_FORMAT)
  .required('Enter a coverage start date')
  /*.min(
    moment().startOf('day'),
    `Please enter a valid date greater than or equal to ${moment()
      .startOf('day')
      .format(process.env.REACT_APP_DATE_FORMAT)}`
  )*/
  .max(
    moment().add(2, 'years'),
    `Please enter a date less than or equal to ${moment()
      .add(2, 'years')
      .format(process.env.REACT_APP_DATE_FORMAT)}`
  )
  .typeError(
    `Please enter a valid start date in ${process.env.REACT_APP_DATE_FORMAT} format`
  );

const endDate = Yup.date()
  .format(process.env.REACT_APP_DATE_FORMAT)
  .required('Enter a coverage end date')
  .typeError(
    `Please enter a valid end date in ${process.env.REACT_APP_DATE_FORMAT} format`
  );

const coverage = Yup.number()
  .required('coverage is required')
  .min(100000, 'Min 100,000 coverage is required');

const havePreConditions = Yup.boolean().required('Please select an option');

export const singleSchema = Yup.object().shape({
  age1: age,
  dob1: dob,
  startDate1: startDate,
  endDate1: endDate,
  coverage1: coverage,
  havePreConditions1: havePreConditions
});

export const coupleApplicant1Schema = Yup.object().shape({
  age1: age,
  dob1: dob,
  startDate1: startDate,
  endDate1: endDate,
  coverage1: coverage,
  havePreConditions1: havePreConditions
});

export const coupleApplicant2Schema = Yup.object().shape({
  age2: age,
  dob2: dob,
  startDate2: startDate,
  endDate2: endDate,
  coverage2: coverage,
  havePreConditions2: havePreConditions
});

export const coupleSchema = coupleApplicant1Schema.concat(
  coupleApplicant2Schema
);
