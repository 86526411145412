import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  Coverage,
  CoverMedicalConditions,
  CoverMedicalConditionsTitle,
  DifferentCoverageBtn,
  DifferentDateBtn,
  DOBAge,
  Duration,
  CoverageType
} from 'components';
import { useFormik } from 'formik';
import config from '../../common/config';
import { schema } from './schema';
import addDays from 'date-fns/addDays';
import subYears from 'date-fns/subYears';
import startOfDay from 'date-fns/startOfDay';
import {
  getQS,
  QSToDates,
  getController,
  setQS,
  datesToQS,
  ToBoolean
} from './../../helpers';

const todayDate = startOfDay(new Date());
const dateFields = [
  'dob1',
  'dob2',
  'startDate1',
  'startDate2',
  'endDate1',
  'endDate2'
];
const useStyles = makeStyles({
  policyTypeC: {
    paddingLeft: 30
  },
  iconText: {
    display: 'flex',
    alignItems: 'center'
  }
});

function Super({ quotePath }) {
  const classes = useStyles();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      deductible: 0,
      insuranceType: '1',
      differentDates: false,
      differentCoverages: false,
      coverageType: 'single',
      dob1: null,
      dob2: null,
      age1: 60,
      age2: 55,
      startDate1: todayDate,
      endDate1: addDays(todayDate, config.superVisa.days.default - 1),
      days1: config.superVisa.days.default,
      startDate2: todayDate,
      endDate2: addDays(todayDate, config.superVisa.days.default - 1),
      days2: config.superVisa.days.default,
      coverage1: 100000,
      coverage2: 100000,
      havePreConditions1: false,
      havePreConditions2: false,
      ...getQS((data) => {
        let transform = QSToDates(data, dateFields);
        transform = ToBoolean(transform, [
          'differentDates',
          'differentCoverages',
          'havePreConditions1',
          'havePreConditions2'
        ]);
        return transform;
      })
    },
    validationSchema: schema,
    onSubmit: (values, { setSubmitting }) => {
      // fix 2nd coverage
      if (!values.differentCoverages) {
        values.coverage2 = values.coverage1;
      }
      setSubmitting(true);
      getController(window.location.pathname);
      window.location.href = `${
        quotePath
          ? quotePath
          : getController(window.location.pathname) + '/quotes'
      }?${setQS(datesToQS(values, dateFields))}#quote-result`;
    }
  });

  return (
    <div
      className={clsx(
        'policy-type',
        formik.values.coverageType !== 'single' && classes.policyTypeC
      )}>
      <form onSubmit={formik.handleSubmit}>
        <div className="frm-grp">
          <CoverageType
            formik={formik}
            options={config.policyTypes.superVisa}
          />
        </div>
        <div className="frm-grp">
          <DOBAge
            formik={formik}
            maxDate={subYears(new Date(), config.superVisa.age.moreThan + 1)}
          />
        </div>
        {formik.values.coverageType === 'couple' && (
          <div className="frm-grp">
            <DOBAge
              nameDob="dob2"
              nameAge="age2"
              formik={formik}
              fieldNo="2"
              maxDate={subYears(new Date(), config.superVisa.age.moreThan + 1)}
            />
          </div>
        )}
        <div className="frm-grp">
          <Duration formik={formik} endDateDisabled />
        </div>
        {formik.values.coverageType === 'couple' &&
          formik.values.differentDates && (
            <div className="frm-grp">
              <Duration
                endDateDisabled
                fieldNo="2"
                formik={formik}
                nameDays="days2"
                nameEnd="endDate2"
                nameStart="startDate2"
              />
            </div>
          )}
        <DifferentDateBtn formik={formik} />
        <Coverage
          formik={formik}
          name="coverage1"
          options={config.superVisa.coverages}
        />
        {formik.values.coverageType === 'couple' &&
          formik.values.differentCoverages && (
            <Coverage
              fieldNo="2"
              formik={formik}
              name="coverage2"
              options={config.superVisa.coverages}
            />
          )}
        <DifferentCoverageBtn formik={formik} />
        <div className="frm-grp">
          <CoverMedicalConditionsTitle />
          <CoverMedicalConditions formik={formik} name="havePreConditions1" />
          {formik.values.coverageType === 'couple' && (
            <CoverMedicalConditions
              formik={formik}
              fieldNo="2"
              name="havePreConditions2"
            />
          )}
        </div>
        <div className="frm-grp">
          <Button type="submit" variant="contained" color="secondary">
            {formik.isSubmitting ? 'SUBMITTING' : 'GET QUOTE'}
          </Button>
        </div>
      </form>
    </div>
  );
}

export default Super;
