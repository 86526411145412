import React, { useEffect } from 'react';
import InputMask from 'react-input-mask';
import moment from 'moment';
import {
  InputAdornment,
  TextField as MuiTextField,
  Typography
} from '@material-ui/core';
import { Toggler } from 'components';
import { Field, useFormikContext } from 'formik';
import { TextField } from 'formik-material-ui';
import { COVERAGES_VISITOR } from 'common/Constants';
import useStyles from './../../formStyles';

const Form1 = ({ touched, handleChange, handleBlur }) => {
  const classes = useStyles();
  const { errors, setFieldValue, values } = useFormikContext();

  useEffect(() => {
    const startDate = moment(
      values.startDate1,
      process.env.REACT_APP_DATE_FORMAT,
      true
    );

    //if start date is valid;
    if (startDate.isValid() && values.days1 > 0) {
      //if days is +ve; update endDate
      setFieldValue(
        'endDate1',
        startDate
          .add(values.days1 - 1, 'days')
          .format(process.env.REACT_APP_DATE_FORMAT)
      );
    }
  }, [values.startDate1]);

  useEffect(() => {
    const startDate = moment(
      values.startDate1,
      process.env.REACT_APP_DATE_FORMAT,
      true
    );
    const endDate = moment(
      values.endDate1,
      process.env.REACT_APP_DATE_FORMAT,
      true
    );

    //if start date is valid;
    if (startDate.isValid() && endDate.isValid()) {
      // set days
      setFieldValue('days1', endDate.diff(startDate, 'days') + 1);
    }
  }, [values.endDate1]);

  useEffect(() => {
    const startDate = moment(
      values.startDate1,
      process.env.REACT_APP_DATE_FORMAT,
      true
    );
    const days = Number(values.days1);

    if (startDate.isValid() && !isNaN(days) && days > 0) {
      const endDate = startDate.add(days - 1, 'days');

      setFieldValue(
        'endDate1',
        endDate.format(process.env.REACT_APP_DATE_FORMAT)
      );
    }
  }, [values.days1]);

  return (
    <>
      <div className={classes.formRow}>
        <InputMask
          error={errors.startDate1 && touched.startDate1}
          fullWidth
          helperText={
            errors.startDate1 && touched.startDate1 && errors.startDate1
          }
          label="Start coverage from"
          mask={process.env.REACT_APP_DATE_MASK_FORMAT}
          maskPlaceholder={process.env.REACT_APP_DATE_FORMAT}
          id="startDate1"
          name="startDate1"
          onBlur={(e) => {
            handleBlur(e);
            if (!errors.startDate1 && !errors.endDate1) {
              //update endDate (conditionally)
              const totalDays = moment(
                values.endDate1,
                process.env.REACT_APP_DATE_FORMAT
              ).diff(
                moment(values.startDate1, process.env.REACT_APP_DATE_FORMAT),
                'days'
              );

              if (totalDays < 1) {
                setFieldValue(
                  'endDate1',
                  moment(values.startDate1, process.env.REACT_APP_DATE_FORMAT)
                    .add(90, 'days')
                    .format(process.env.REACT_APP_DATE_FORMAT)
                );

                //update endDate 2nd applicant
              }
            }
          }}
          onChange={handleChange}
          value={values.startDate1}
          variant="outlined">
          <MuiTextField />
        </InputMask>
        <Toggler>
          <Typography variant="body2">
            Coverage will start from this date. There will be a waiting period
            if the applicant has already landed in Canada.
          </Typography>
        </Toggler>
      </div>

      <div className={classes.formRow}>
        <InputMask
          error={errors.endDate1 && touched.endDate1}
          fullWidth
          helperText={errors.endDate1 && touched.endDate1 && errors.endDate1}
          label="Coverage up-to"
          mask={process.env.REACT_APP_DATE_MASK_FORMAT}
          maskPlaceholder={process.env.REACT_APP_DATE_FORMAT}
          id="endDate1"
          name="endDate1"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.endDate1}
          variant="outlined">
          <MuiTextField />
        </InputMask>
        <Toggler>
          <Typography variant="body2">
            You may extend/cancel your policy anytime.
          </Typography>
        </Toggler>
      </div>

      <div className={classes.formRow}>
        <Field
          component={TextField}
          fullWidth
          label="Days of coverage"
          name="days1"
          InputProps={{
            onChange: handleChange
          }}
          value={values.days1}
          variant="outlined"
        />
      </div>

      <div className={classes.formRow}>
        <Field
          component={TextField}
          fullWidth
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>
          }}
          label="Total coverage required?"
          name="coverage1"
          onChange={handleChange}
          select
          // eslint-disable-next-line react/jsx-sort-props
          SelectProps={{ native: true }}
          value={values.coverage1}
          variant="outlined">
          {COVERAGES_VISITOR.map((item) => (
            <option value={item.value} key={item.value}>
              {item.label}
            </option>
          ))}
        </Field>
        <Toggler>
          <Typography variant="body2">
            This will be the total coverage amount. Benefit details differ from
            company to company. Details available on quotes page.
          </Typography>
        </Toggler>
      </div>
    </>
  );
};

export default Form1;
