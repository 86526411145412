import React from 'react';
import { Typography } from '@material-ui/core';

const Amount = ({ number, decPlaces, decSep, thouSep, symbol }) => {
  decPlaces = isNaN((decPlaces = Math.abs(decPlaces))) ? 2 : decPlaces;
  decSep = typeof decSep === 'undefined' ? '.' : decSep;
  thouSep = typeof thouSep === 'undefined' ? ',' : thouSep;
  symbol = typeof symbol === 'undefined' ? '$' : symbol;

  let sign = number < 0 ? '-' : '';
  let i = String(
    parseInt((number = Math.abs(Number(number) || 0).toFixed(decPlaces)))
  );
  const length = i.length;
  let j = length > 3 ? length % 3 : 0;

  return (
    <>
      <Typography variant="body1" component="span" className="amount-symbol">
        {symbol}
        {sign}
      </Typography>
      <Typography variant="h2" component="span" className="amount-primary">
        {j ? i.substr(0, j) + thouSep : ''}
        {i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, '$1' + thouSep)}
      </Typography>
      <Typography variant="body1" component="span" className="amount-secondary">
        {decPlaces
          ? decSep +
            Math.abs(number - i)
              .toFixed(decPlaces)
              .slice(2)
          : ''}
      </Typography>
    </>
  );
};

export default Amount;
