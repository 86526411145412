import { handleResponse, handleError } from './apiUtils';
const baseUrl = 'https://www.googleapis.com';

export function getLocation() {
  return fetch(
    `${baseUrl}/geolocation/v1/geolocate?key=${process.env.REACT_APP_PUBLIC_GOOGLE_API_KEY}`,
    {
      method: 'POST'
    }
  )
    .then(handleResponse)
    .catch(handleError);
}

export function getGeocode(lat, lng) {
  return fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyCz2fo5lpK83Zuy1ylcKNM9JOXa9YrGHf4&location_type=ROOFTOP`
  )
    .then(handleResponse)
    .catch(handleError);
}
