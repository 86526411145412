import React from "react";
import { IconButton, Popover, Typography } from "@material-ui/core";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

const FamilyHelp = ({ insuranceType }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <IconButton
        aria-describedby={id}
        color="primary"
        aria-label="Help"
        component="span"
        onClick={handleClick}
        size="small"
      >
        <HelpOutlineIcon />
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        id={id}
        onClose={handleClose}
        open={open}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className="pop-over">
          {insuranceType === "visitor" && (
            <>
              <Typography variant="body1">
                All family members must be under age 60:
              </Typography>
              <Typography variant="body2">
                A family is defined as three or more of: parents or legal
                guardian(s) and their unmarried children under age 21 who are
                visiting Canada with them and are dependent on them for sole
                means of support.
              </Typography>
            </>
          )}

          {insuranceType === "student" && (
            <Typography variant="body2">
              Family rates are calculated for at least 2 individuals such as
              spouse and dependent children.
            </Typography>
          )}
          {insuranceType === "travel" && (
            <Typography variant="body2">
              Family Coverage includes the applicant, and/or his/her spouse, and
              dependent children or grandchildren. Family Coverage is also
              available for up to two parents and two grandparents from the same
              family plus their children. The effect and expiry dates of
              coverage must be the same for all family members.
            </Typography>
          )}
        </div>
      </Popover>
    </>
  );
};

export default FamilyHelp;
