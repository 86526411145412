import React, { useEffect, useRef } from 'react';
import InputMask from 'react-input-mask';
import moment from 'moment';
import {
  InputAdornment,
  TextField as MuiTextField,
  Typography
} from '@material-ui/core';
import { Radio, RadioGroup, Toggler } from 'components';
import { Field, useFormikContext } from 'formik';
import { TextField } from 'formik-material-ui';
import { COVERAGES_VISITOR } from 'common/Constants';
import useStyles from '../../formStyles';
import clsx from 'clsx';
import { dobToAge } from 'common/Helper';

const Form2 = ({
  touched,
  setFieldTouched,
  handleChange,
  handleBlur,
  validateField
}) => {
  const classes = useStyles();
  const age2Input = useRef(null);
  const { errors, setFieldValue, values } = useFormikContext();

  useEffect(() => {
    age2Input.current.focus();
  }, []);

  useEffect(() => {
    console.log('startDate2 effect');
    const startDate = moment(
      values.startDate2,
      process.env.REACT_APP_DATE_FORMAT,
      true
    );

    //if start date is valid;
    if (startDate.isValid() && values.days2 > 0) {
      //if days is +ve; update endDate
      const endDate = startDate
        .add(values.days2 - 1, 'days')
        .format(process.env.REACT_APP_DATE_FORMAT);

      setFieldValue('endDate2', endDate);
    }
  }, [values.startDate2]);

  useEffect(() => {
    console.log('endDate2 effect');
    const startDate = moment(
      values.startDate2,
      process.env.REACT_APP_DATE_FORMAT,
      true
    );
    const endDate = moment(
      values.endDate2,
      process.env.REACT_APP_DATE_FORMAT,
      true
    );

    //if start date is valid;
    if (startDate.isValid() && endDate.isValid()) {
      // set days
      const days = endDate.diff(startDate, 'days') + 1;
      setFieldValue('days2', days);
    }
  }, [values.endDate2]);

  useEffect(() => {
    console.log('days2 effect');
    const startDate = moment(
      values.startDate2,
      process.env.REACT_APP_DATE_FORMAT,
      true
    );

    const days = Number(values.days2);

    if (startDate.isValid() && !isNaN(days) && days > 0) {
      const endDate = startDate
        .add(days - 1, 'days')
        .format(process.env.REACT_APP_DATE_FORMAT);

      setFieldValue('endDate2', endDate);
    }
  }, [values.days2]);

  return (
    <>
      <div className={classes.formRow}>
        <InputMask
          error={errors.startDate2 && touched.startDate2}
          fullWidth
          helperText={
            errors.startDate2 && touched.startDate2 && errors.startDate2
          }
          label="Start coverage from"
          mask={process.env.REACT_APP_DATE_MASK_FORMAT}
          maskPlaceholder={process.env.REACT_APP_DATE_FORMAT}
          id="startDate2"
          name="startDate2"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.startDate2}
          variant="outlined">
          <MuiTextField />
        </InputMask>
        <Toggler>
          <Typography variant="body2">
            Coverage will start from this date. There will be a waiting period
            if the applicant has already landed in Canada.
          </Typography>
        </Toggler>
      </div>

      <div className={classes.formRow}>
        <InputMask
          error={errors.endDate2 && touched.endDate2}
          fullWidth
          helperText={errors.endDate2 && touched.endDate2 && errors.endDate2}
          label="Coverage up-to"
          mask={process.env.REACT_APP_DATE_MASK_FORMAT}
          maskPlaceholder={process.env.REACT_APP_DATE_FORMAT}
          id="endDate2"
          name="endDate2"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.endDate2}
          variant="outlined">
          <MuiTextField />
        </InputMask>
        <Toggler>
          <Typography variant="body2">
            You may extend/cancel your policy anytime.
          </Typography>
        </Toggler>
      </div>

      <div className={classes.formRow}>
        <Field
          component={TextField}
          fullWidth
          label="Days of coverage"
          name="days2"
          InputProps={{
            onChange: handleChange
          }}
          value={values.days2}
          variant="outlined"
        />
      </div>

      <div className={classes.formRow}>
        <Field
          component={TextField}
          fullWidth
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>
          }}
          label="Total coverage for 2nd applicant?"
          name="coverage2"
          //onChange={e => setFieldValue('province', e.target.value)}
          onChange={handleChange}
          select
          // eslint-disable-next-line react/jsx-sort-props
          SelectProps={{ native: true }}
          value={values.coverage2}
          variant="outlined">
          <option value="" key="0">
            select
          </option>
          {COVERAGES_VISITOR.map((item) => (
            <option value={item.value} key={item.value}>
              {item.label}
            </option>
          ))}
        </Field>
        <Toggler>
          <Typography variant="body2">
            This will be the total coverage amount. Benefit details differ from
            company to company. Details available on quotes page.
          </Typography>
        </Toggler>
      </div>

      <div className={classes.formRow}>
        <div className={classes.dFlex}>
          <Field
            inputRef={age2Input}
            className={classes.age}
            component={TextField}
            label="Age - 2"
            name="age2"
            InputProps={{
              onChange: (e) => {
                handleChange(e);
                setFieldTouched('dob2', true);
                setFieldValue('dob2', '');
              }
            }}
            variant="outlined"
          />

          <div className={classes.or}>or</div>

          <InputMask
            className={classes.dob}
            error={errors.dob2 && touched.dob2}
            fullWidth
            helperText={errors.dob2 && touched.dob2 && errors.dob2}
            label="Date of Birth"
            mask={process.env.REACT_APP_DATE_MASK_FORMAT}
            maskPlaceholder={process.env.REACT_APP_DATE_FORMAT}
            name="dob2"
            onBlur={handleBlur}
            onChange={(e) => {
              handleChange(e);
              //validateField(e.target.name);
              if (
                moment(
                  e.target.value,
                  process.env.REACT_APP_DATE_FORMAT,
                  true
                ).isValid()
              ) {
                setFieldTouched('age2', true);
                setFieldValue('age2', dobToAge(e.target.value));
                validateField('age2');
              }
            }}
            value={values.dob2}
            variant="outlined">
            <MuiTextField
              InputLabelProps={{ shrink: true }}
              placeholder={process.env.REACT_APP_DATE_FORMAT}
            />
          </InputMask>
        </div>

        <Toggler>
          <Typography variant="body2">
            Age is the major factor in calculating super visa quotes.
          </Typography>
        </Toggler>
      </div>

      <div className={clsx(classes.formRow)}>
        <RadioGroup
          error={!!(errors.havePreConditions2 && touched.havePreConditions2)}
          defaultValue={values.havePreConditions2}
          onSelect={(value) => {
            setFieldValue('havePreConditions2', value);
          }}>
          {(props) => (
            <>
              <Typography gutterBottom>
                Cover pre-existing medical conditions for 2nd applicant?
              </Typography>
              <Radio value="true" {...props}>
                Yes
              </Radio>
              <Radio value="false" {...props}>
                No
              </Radio>
              <Typography
                variant="body2"
                color={
                  errors.havePreConditions2 && touched.havePreConditions2
                    ? 'error'
                    : 'textPrimary'
                }>
                {errors.havePreConditions2 &&
                  touched.havePreConditions2 &&
                  errors.havePreConditions2}
              </Typography>
            </>
          )}
        </RadioGroup>
        <Toggler>
          <Typography variant="body2">
            A pre-existing medical condition usually means any medical condition
            or symptoms for which medical advice, diagnosis, or treatment was
            recommended or received by a doctor or other practitioner at any
            time prior to your departure date. These medical conditions or
            symptoms are described in the insurance policy.
          </Typography>
        </Toggler>
      </div>
    </>
  );
};

export default Form2;
