import React from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import differenceInYears from 'date-fns/differenceInYears';
import config from 'common/config';
import clsx from 'clsx';

const useStyles = makeStyles({
  or: {
    padding: '1rem .5rem',
    display: 'inline-flex'
  },
  dob: {
    width: 180
  },
  age: {
    width: 80
  }
});

const DOBAge = ({
  formik,
  fieldNo = 1,
  labelDob = 'Date of Birth',
  labelAge = 'Age',
  nameDob = 'dob1',
  nameAge = 'age1',
  maxDate = new Date()
}) => {
  const classes = useStyles();
  const {
    setFieldValue,
    setFieldTouched,
    handleChange,
    handleBlur,
    errors,
    touched,
    values
  } = formik;

  return (
    <div className="fld-row">
      <div
        className={clsx(
          'fld-no',
          `fld-no-${fieldNo}`,
          values.coverageType !== 'single' && 'active'
        )}>
        {fieldNo}
      </div>
      <KeyboardDatePicker
        autoOk
        className={classes.dob}
        maxDate={maxDate}
        format="dd-MM-yyyy"
        error={errors[nameDob] && touched[nameDob]}
        helperText={
          errors[nameDob] && touched[nameDob]
            ? errors[nameDob]
            : `${config.dateFormatDisplay} format`
        }
        label={labelDob}
        inputVariant="outlined"
        name={nameDob}
        onBlur={handleBlur}
        onChange={(d) => {
          let years;
          setFieldValue(nameDob, d);
          years = differenceInYears(new Date(), d);
          if (!Number.isNaN(years)) {
            setFieldValue(nameAge, years);
            setFieldTouched(nameAge, true);
          }
        }}
        value={values[nameDob]}
        variant="inline"
      />
      <div className={classes.or}>or</div>
      <TextField
        className={classes.age}
        error={errors[nameAge] && touched[nameAge]}
        helperText={
          errors[nameAge] && touched[nameAge] ? errors[nameAge] : 'years'
        }
        label={labelAge}
        name={nameAge}
        onBlur={handleBlur}
        onChange={(e) => {
          handleChange(e);

          if (values[nameDob]) {
            setFieldValue(nameDob, null);
          }
        }}
        type="Number"
        value={values[nameAge]}
        variant="outlined"
      />
    </div>
  );
};

export default DOBAge;
