import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  Coverage,
  CoverMedicalConditions,
  CoverMedicalConditionsTitle,
  NoOfDependents,
  DifferentCoverageBtn,
  DifferentDateBtn,
  DOBAge,
  Duration,
  CoverageType
} from 'components';
import { useFormik } from 'formik';
import config from 'common/config';
import { schema } from './schema';
import addDays from 'date-fns/addDays';
import subDays from 'date-fns/subDays';
import startOfDay from 'date-fns/startOfDay';
import {
  getQS,
  setQS,
  datesToQS,
  QSToDates,
  getController,
  ToBoolean
} from 'helpers';

const todayDate = startOfDay(new Date());
const dateFields = [
  'dob1',
  'dob2',
  'startDate1',
  'startDate2',
  'endDate1',
  'endDate2'
];
const useStyles = makeStyles({
  policyTypeC: {
    paddingLeft: 30
  },
  iconText: {
    display: 'flex',
    alignItems: 'center'
  }
});

function Visitor({ quotePath }) {
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      insuranceType: '2',
      differentDates: false,
      differentCoverages: false,
      coverageType: 'single',
      dob1: null,
      dob2: null,
      age1: 35,
      age2: 30,
      startDate1: todayDate,
      endDate1: addDays(todayDate, config.visitors.days.default - 1),
      days1: config.visitors.days.default,
      startDate2: todayDate,
      endDate2: addDays(todayDate, config.visitors.days.default - 1),
      days2: config.visitors.days.default,
      coverage1: 50000,
      coverage2: 50000,
      havePreConditions1: false,
      havePreConditions2: false,
      dependents: 0,
      ...getQS((data) => {
        let transform = QSToDates(data, dateFields);
        transform = ToBoolean(transform, [
          'differentDates',
          'differentCoverages'
        ]);
        return transform;
      })
    },
    validationSchema: schema,
    onSubmit: (values, { setSubmitting }) => {
      // fix 2nd coverage
      if (!values.differentCoverages) {
        delete values.coverage2;
      }

      // fix 2nd days
      if (!values.differentDates) {
        delete values.days2;
      }

      setSubmitting(true);
      window.location.href = `${
        quotePath
          ? quotePath
          : getController(window.location.pathname) + '/quotes'
      }?${setQS(datesToQS(values, dateFields))}#quote-result`;
    }
  });

  return (
    <div
      className={clsx(
        'policy-type',
        formik.values.CoverageType !== 's' && classes.policyTypeC
      )}>
      <form onSubmit={formik.handleSubmit}>
        <div className="frm-grp">
          <CoverageType formik={formik} options={config.policyTypes.visitors} />
        </div>
        <div className="frm-grp">
          <DOBAge
            formik={formik}
            maxDate={subDays(new Date(), config.visitors.age.minDays)}
          />
        </div>
        {formik.values.coverageType !== 'single' && (
          <div className="frm-grp">
            <DOBAge
              nameDob="dob2"
              nameAge="age2"
              formik={formik}
              fieldNo="2"
              maxDate={subDays(new Date(), config.visitors.age.minDays)}
            />
          </div>
        )}
        {formik.values.coverageType === 'family' && (
          <NoOfDependents formik={formik} />
        )}
        <div className="frm-grp">
          <Duration formik={formik} />
        </div>
        {formik.values.coverageType === 'couple' &&
          formik.values.differentDates && (
            <div className="frm-grp">
              <Duration
                formik={formik}
                nameStart="startDate2"
                nameEnd="endDate2"
                nameDays="days2"
                fieldNo="2"
              />
            </div>
          )}
        <DifferentDateBtn formik={formik} />
        <Coverage
          formik={formik}
          name="coverage1"
          options={config.visitors.coverages}
        />
        {formik.values.coverageType === 'couple' &&
          formik.values.differentCoverages && (
            <Coverage
              formik={formik}
              fieldNo="2"
              name="coverage2"
              options={config.visitors.coverages}
            />
          )}
        <DifferentCoverageBtn formik={formik} />
        <div className="frm-grp">
          <CoverMedicalConditionsTitle />
          <CoverMedicalConditions formik={formik} name="havePreConditions1" />
          {formik.values.coverageType === 'couple' && (
            <CoverMedicalConditions
              formik={formik}
              fieldNo="2"
              name="havePreConditions2"
            />
          )}
        </div>
        <div className="frm-grp">
          <Button type="submit" variant="contained" color="secondary">
            {formik.isSubmitting ? 'SUBMITTING' : 'GET QUOTE'}
          </Button>
        </div>
      </form>
    </div>
  );
}

export default Visitor;
