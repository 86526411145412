import React from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import config from 'common/config';
import addDays from 'date-fns/addDays';
import differenceInDays from 'date-fns/differenceInDays';
import startOfDay from 'date-fns/startOfDay';
import isValid from 'date-fns/isValid';

const useStyles = makeStyles((theme) => ({
  fldRow: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row'
    }
  },
  or: {
    padding: '1rem .5rem',
    display: 'inline-flex'
  },
  start: {
    width: 180
  },
  endDateWrapper: {
    display: 'inline-flex',
    marginTop: 20,
    [theme.breakpoints.up('sm')]: {
      marginTop: 0,
      marginLeft: 10
    }
  },
  end: {
    width: 180
  },
  days: {
    width: 80
  }
}));

const Duration = ({
  formik,
  endDateDisabled,
  fieldNo = '1',
  labelStart = 'Start Date',
  labelEnd = 'End Date',
  labelDays = 'Days',
  nameStart = 'startDate1',
  nameEnd = 'endDate1',
  nameDays = 'days1'
}) => {
  const classes = useStyles();
  const {
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    handleBlur,
    handleChange,
    values
  } = formik;

  return (
    <div className={clsx('fld-row', classes.fldRow)}>
      <div
        className={clsx(
          'fld-no',
          `fld-no-${fieldNo}`,
          values.policy_type === 'c' && values.differentDates && 'active'
        )}>
        {fieldNo}
      </div>

      <KeyboardDatePicker
        autoOk
        className={classes.start}
        disablePast
        format="dd-MM-yyyy"
        error={errors[nameStart] && touched[nameStart]}
        helperText={
          errors[nameStart] && touched[nameStart]
            ? errors[nameStart]
            : `${config.dateFormatDisplay} format`
        }
        label={labelStart}
        inputVariant="outlined"
        name={nameStart}
        onBlur={handleBlur}
        onChange={(d) => {
          let date = startOfDay(d);
          setFieldTouched(nameStart, true);
          setFieldValue(nameStart, date);

          if (
            date &&
            isValid(date) &&
            values[nameDays] &&
            values[nameDays] > 0
          ) {
            setFieldTouched(nameEnd, true);
            setFieldValue(nameEnd, addDays(date, values[nameDays] - 1));
          }

          if (nameStart === 'startDate1' && !values.differentDates) {
            setFieldTouched('startDate2', true);
            setFieldValue('startDate2', date);

            if (
              date &&
              isValid(date) &&
              values[nameDays] &&
              values[nameDays] > 0
            ) {
              setFieldTouched('endDate2', true);
              setFieldValue('endDate2', addDays(date, values[nameDays] - 1));
            }
          }
        }}
        value={values[nameStart]}
        variant="inline"
      />

      <div className={classes.endDateWrapper}>
        <KeyboardDatePicker
          autoOk
          className={classes.end}
          disablePast
          disabled={endDateDisabled}
          format="dd-MM-yyyy"
          error={errors[nameEnd] && touched[nameEnd]}
          helperText={
            errors[nameEnd] && touched[nameEnd]
              ? errors[nameEnd]
              : `${config.dateFormatDisplay} format`
          }
          label={labelEnd}
          inputVariant="outlined"
          name={nameEnd}
          onBlur={handleBlur}
          onChange={(d) => {
            let date = startOfDay(d);
            setFieldValue(nameEnd, date);
            setFieldTouched(nameEnd, true);

            //update days if valid date (endDate) and if startDate exists
            if (date && isValid(date) && values[nameStart]) {
              setFieldValue(
                nameDays,
                differenceInDays(date, values[nameStart]) + 1
              );
              setFieldTouched(nameDays, true);
            }

            // manage 2nd (days)
            if (nameEnd === 'endDate1' && !values.differentDates) {
              setFieldTouched('endDate2', true);
              setFieldValue('endDate2', date);

              if (date && isValid(date) && values[nameStart]) {
                setFieldTouched('days2', true);
                setFieldValue(
                  'days2',
                  differenceInDays(date, values[nameStart]) + 1
                );
              }
            }
          }}
          value={values[nameEnd]}
          variant="inline"
        />

        <div className={classes.or}>or</div>

        <TextField
          className={classes.days}
          disabled={endDateDisabled}
          error={errors[nameDays] && touched[nameDays]}
          helperText={errors[nameDays] && touched[nameDays] && errors[nameDays]}
          label={labelDays}
          name={nameDays}
          onBlur={handleBlur}
          onChange={(e) => {
            let days = e.target.value;
            handleChange(e);
            setFieldTouched(nameDays, true);

            // update endDate; if startDate & isValidDate
            if (
              values[nameStart] &&
              isValid(values[nameStart]) &&
              days &&
              days > 0
            ) {
              setFieldValue(nameEnd, addDays(values[nameStart], days - 1));
              setFieldTouched(nameEnd, true);
            }

            // manage
            if (nameDays === 'days1' && !values.differentDates) {
              setFieldTouched('days2', true);
              setFieldValue('days2', days);
            }
          }}
          type="Number"
          value={values[nameDays]}
          variant="outlined"
        />
      </div>
    </div>
  );
};

export default Duration;
