import React from 'react';
import { Button, colors, Grid, Paper, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { STORAGE_URL, DEDUCTIBLE_TYPES } from 'common/Constants';
import { Quote } from 'components';
import { Disclaimer, Summary } from './components';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    height: '100%'
  },
  row1: {
    display: 'flex',
    alignItems: 'center',

    '& > div': {
      flex: '0 1 50%'
    }
  },
  deductible: {
    borderTop: `solid 1px ${colors.grey[100]}`,
    padding: theme.spacing(1, 0),
    margin: theme.spacing(1, 0)
  },
  ctaBuy: {
    marginTop: 'auto'
  },
  imgFluid: {
    maxWidth: '100%',
    height: 'auto'
  }
}));

const Item = ({ insuranceType, query, record, onRateSelect }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Paper className={clsx('quotes-item', classes.root)}>
        <Disclaimer disclaimer={record.disclaimer}>
          <div className={clsx(classes.row1)}>
            <div className={clsx('quotes-item-img')}>
              <img
                src={`${STORAGE_URL}/images/partners/${record.plan.slug}.png`}
                alt={record.plan.name}
                className={classes.imgFluid}
              />
            </div>
            <div>
              <Quote
                cost={record.cost}
                cost1={record.cost1}
                cost2={record.cost2}
                costMonthly={record.costMonthly}
                coverageType={query.coverageType}
                days1={query.days1}
                days2={query.days2}
                deductible={record.deductible}
                disclaimer={record.disclaimer}
                haveMonthlyPlans={record.plan.haveMonthlyPlans}
                insuranceType={insuranceType}
                slug={record.plan.slug}
              />
            </div>
          </div>

          <div className={clsx('quotes-item-deductible', classes.deductible)}>
            <Typography variant="body2">
              Deductible <strong>{record.deductible}</strong> per{' '}
              {DEDUCTIBLE_TYPES[record.plan.deductibleType]}
            </Typography>
            <Summary details={record.plan.summary} />
          </div>

          <div className={clsx(classes.ctaBuy)}>
            <Button
              color="default"
              className={clsx('cta-details-buy', classes.buttonContinue)}
              disableElevation
              fullWidth
              onClick={() => {
                onRateSelect(record);
              }}
              variant="outlined">
              View Details &amp; Buy
            </Button>
          </div>
        </Disclaimer>
      </Paper>
    </Grid>
  );
};

export default Item;
