import React from 'react';
import { Amount } from 'components';
import useStyles from '../style';

const VisitorCouple = (props) => {
  const classes = useStyles();

  return (
    <>
      <div className="quote-couple quote-couple-1 quote-couple-super">
        <div className="fld-no fld-no-1 active">1</div>
        <Amount number={props.cost1} />
      </div>
      <div className="quote-couple quote-couple-2 quote-couple-super">
        <div className="fld-no fld-no-2 active">2</div>
        <Amount number={props.cost2} />
      </div>
      <div className={classes.total}>Total: {props.cost}</div>
    </>
  );
};

export default VisitorCouple;
