import parse from 'date-fns/parse';
import isValid from 'date-fns/isValid';

const QSToDates = (data, dates = [], pattern = 'yyyy-MM-dd') => {
  //console.log("data", data);
  for (let key in data) {
    // data doesn't have hasOwnProperty
    // so can't or dont need that check
    if (dates.includes(key)) {
      let date = parse(data[key], pattern, new Date());
      if (isValid(date)) {
        data[key] = date;
      } else {
        data[key] = null;
      }
    }
  }
  return data;
};

export default QSToDates;
