import Yup from 'schemas/Yup';

const dob = Yup.date()
  .format(process.env.REACT_APP_DATE_FORMAT)
  .required('Date of Birth is required')
  .typeError(
    `Please enter a valid date of birth in ${process.env.REACT_APP_DATE_FORMAT} format`
  );

const arrivalDate = Yup.date()
  .format(process.env.REACT_APP_DATE_FORMAT)
  .required('Arrival Date is required')
  .typeError(
    `Please enter a valid date in ${process.env.REACT_APP_DATE_FORMAT} format`
  );

const address = Yup.string().required('Address is required');

const origin = Yup.string().required('Country of origin is required');

export const superVisaSingleSchema = Yup.object().shape({
  name: Yup.string().required('Your name is required'),
  phone: Yup.string()
    .checkNullPhone()
    .required('Phone is required')
    .matches('\\d{3}-\\d{3}-\\d{4}', {
      message: 'Enter a valid phone number',
      excludeEmptyString: true
    }),
  email: Yup.string().nullable().email('Please enter a valid email address')
});

export const superVisitorSingleSchema = Yup.object().shape({
  applicantName1: Yup.string().required('Applicant name is required'),
  dob1: dob,
  address,
  arrivalDate,
  origin
});

export const superVisitorCoupleSchema = Yup.object().shape({
  applicantName1: Yup.string().required("1st Applicant's name is required"),
  dob1: dob,
  applicantName2: Yup.string().required("2nd Applicant's name is required"),
  dob2: dob,
  address,
  arrivalDate,
  origin
});
