import { handleResponse, handleError } from './apiUtils';
import { stringify } from 'query-string';

const baseUrl = process.env.REACT_APP_API_URL + '/rates';

export function getSuperSingleRates(data) {
  return fetch(`${baseUrl}/super/single?${stringify(data)}`)
    .then(handleResponse)
    .catch(handleError);
}

export function getSuperCoupleRates(data) {
  return fetch(`${baseUrl}/super/couple?${stringify(data)}`)
    .then(handleResponse)
    .catch(handleError);
}

export function getVisitorSingleRates(data) {
  return fetch(`${baseUrl}/visitor/single?${stringify(data)}`)
    .then(handleResponse)
    .catch(handleError);
}

export function getVisitorCoupleRates(data) {
  return fetch(`${baseUrl}/visitor/couple?${stringify(data)}`)
    .then(handleResponse)
    .catch(handleError);
}

export function getVisitorFamilyRates(data) {
  return fetch(`${baseUrl}/visitor/family?${stringify(data)}`)
    .then(handleResponse)
    .catch(handleError);
}

export function getStudentRates(data) {
  return fetch(`${baseUrl}/student?${stringify(data)}`)
    .then(handleResponse)
    .catch(handleError);
}

export function getPlanRates(data) {
  return fetch(`${baseUrl}/?${stringify(data)}`)
    .then(handleResponse)
    .catch(handleError);
}

export function sendQuote(data) {
  return fetch(`${baseUrl}/send`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(handleResponse)
    .catch(handleError);
}

export default {
  getSuperSingleRates,
  getSuperCoupleRates,
  getVisitorSingleRates,
  getVisitorCoupleRates,
  getVisitorFamilyRates,
  getStudentRates,
  sendQuote
};
