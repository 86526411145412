import React from 'react';
import { Button } from '@material-ui/core';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  cta: {
    marginBottom: theme.spacing(1)
  }
}));

const DetailsBtn = ({ details, detailsIsOpen, toggleDetails }) => {
  const classes = useStyles();

  if (details) {
    return (
      <Button
        className={clsx('quote-details-btn', classes.cta)}
        color="primary"
        endIcon={detailsIsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        onClick={toggleDetails}
        variant="contained">
        {detailsIsOpen ? <>Close Details</> : <>Plan Details</>}
      </Button>
    );
  } else {
    return '';
  }
};

export default DetailsBtn;
