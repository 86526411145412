import Yup from 'schemas/Yup';
import moment from 'moment';

const age = Yup.number()
  .required('Required')
  .min(0, 'Age should be minimum 0 years')
  .max(90, 'Age should not be greater than 90');

const days = Yup.number().max(365, 'Days should not be greater than 365');

const dob = Yup.date()
  .format(process.env.REACT_APP_DATE_FORMAT)
  .checkNull()
  .max(
    moment(),
    `DOB should be less than ${moment()
      .add(1, 'days')
      .format(process.env.REACT_APP_DATE_FORMAT)}`
  )
  .nullable()
  .typeError(
    `Please enter a valid date of birth in ${process.env.REACT_APP_DATE_FORMAT} format`
  );

const startDate = Yup.date()
  .format(process.env.REACT_APP_DATE_FORMAT)
  .required('Enter a coverage start date')
  /*.min(
    moment().startOf('day'),
    `Please enter a valid date greater than or equal to ${moment()
      .startOf('day')
      .format(process.env.REACT_APP_DATE_FORMAT)}`
  )*/
  .max(
    moment().add(2, 'years'),
    `Please enter a date less than or equal to ${moment()
      .add(2, 'years')
      .format(process.env.REACT_APP_DATE_FORMAT)}`
  )
  .typeError(
    `Please enter a valid start date in ${process.env.REACT_APP_DATE_FORMAT} format`
  );

const endDate = Yup.date()
  .format(process.env.REACT_APP_DATE_FORMAT)
  .required('Enter a coverage end date')
  .typeError(
    `Please enter a valid end date in ${process.env.REACT_APP_DATE_FORMAT} format`
  );

const coverage = Yup.number()
  .required('coverage is required')
  .min(10000, 'Min 10,000 coverage is required');

const havePreConditions = Yup.boolean().required('Please select an option');

export const singleSchema = Yup.object().shape({
  age1: age,
  dob1: dob,
  startDate1: startDate,
  endDate1: endDate.when('startDate1', (startDate, schema) => {
    if (startDate) {
      return schema.min(
        startDate,
        'Should be greater than or equals to start date'
      );
    } else {
      return schema;
    }
  }),
  days1: days,
  coverage1: coverage,
  havePreConditions1: havePreConditions
});

export const coupleApplicant1Schema = Yup.object().shape({
  age1: age,
  dob1: dob,
  startDate1: startDate,
  endDate1: endDate.when('startDate1', (startDate, schema) => {
    if (startDate) {
      return schema.min(
        startDate,
        'Should be greater than or equals to start date'
      );
    } else {
      return schema;
    }
  }),
  days1: days,
  coverage1: coverage,
  havePreConditions1: havePreConditions
});

export const coupleApplicant2Schema = Yup.object().shape({
  differentCoverages: Yup.boolean(),
  differentDays: Yup.boolean(),
  age2: age,
  dob2: dob,
  startDate2: startDate,
  endDate2: endDate.when('startDate2', (startDate, schema) => {
    if (startDate) {
      return schema.min(
        startDate,
        'Should be greater than or equals to start date'
      );
    } else {
      return schema;
    }
  }),
  days2: Yup.string()
    .nullable()
    .when('differentDays', {
      is: (differentDays) => !!differentDays,
      then: days
    }),
  coverage2: Yup.number()
    .nullable()
    .when('differentCoverages', {
      is: (differentCoverages) => !!differentCoverages,
      then: coverage
    }),
  havePreConditions2: havePreConditions
});

export const coupleSchema = coupleApplicant1Schema.concat(
  coupleApplicant2Schema
);

// family schema
export const familySchemaStep1 = Yup.object().shape({
  startDate1: startDate,
  endDate1: endDate.when('startDate1', (startDate, schema) => {
    if (startDate) {
      return schema.min(
        startDate,
        'Should be greater than or equals to start date'
      );
    } else {
      return schema;
    }
  }),
  days1: days,
  coverage1: coverage
});

export const familySchemaStep2 = Yup.object().shape({
  age1: age,
  dob1: dob,
  age2: age,
  dob2: dob,
  coverage1: coverage,
  havePreConditions1: havePreConditions,
  children: Yup.number()
});

export const familySchema = familySchemaStep1.concat(familySchemaStep2);
