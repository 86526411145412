import { createMuiTheme } from '@material-ui/core';
import palette from './palette';
import typography from './typography';
import overrides from './overrides';
//import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

/*const customBreakpointValues = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920
  }
};

const breakpoints = createBreakpoints({ ...customBreakpointValues });*/

const theme = createMuiTheme({
  // breakpoints: {
  //   ...customBreakpointValues
  // },
  palette,
  typography,
  overrides
});

export default theme;
