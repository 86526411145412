import React, { useState, useEffect, useRef } from 'react';
import { Formik, Form } from 'formik';
import moment from 'moment';
import { Form1, Form2 } from './components';
import { NavigateBefore as ArrowBackIcon } from '@material-ui/icons';
import FormikErrorFocus from 'formik-error-focus';
import {
  Button,
  makeStyles,
  Stepper,
  Step,
  StepLabel,
  Typography
} from '@material-ui/core';
import {
  visitorCoupleApplicant1Schema,
  visitorCoupleApplicant2Schema
} from 'schemas/quotes';
import queryString from 'query-string';
import { toQueryString, dateToDb, dbToDate } from 'common/Helper';
import { getPlanBySlug } from 'api/planApi';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  button: {
    marginRight: theme.spacing(1)
  },
  title: {
    [theme.breakpoints.only('xs')]: {
      fontSize: '20px'
    }
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  back: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  stepLabelContainer: {
    [theme.breakpoints.only('xs')]: {
      display: 'none'
    }
  },
  stepperRoot: {
    padding: theme.spacing(2, 0, 5, 0)
  }
}));

function getSteps() {
  return ['1st Applicants details', '2nd applicants details'];
}

function getStepContent(step, props) {
  switch (step) {
    case 0:
      return (
        <>
          <Form1 {...props} />
        </>
      );
    case 1:
      return (
        <>
          <Form2 {...props} />
        </>
      );
    default:
      return 'Unknown step';
  }
}

function getStepValidationSchema(step) {
  switch (step) {
    case 0:
      return visitorCoupleApplicant1Schema;
    case 1:
      return visitorCoupleApplicant2Schema;
    default:
      return 'Unknown step';
  }
}

const scrollToContentTop = (ref) => {
  if (window) {
    window.scrollTo(0, ref.current.offsetTop);
  }
};

const VisitorCouple = ({ location, history }) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [query, setQuery] = useState({});
  const mainContent = useRef(null);
  const steps = getSteps();
  const { slug } = useParams();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    scrollToContentTop(mainContent);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    scrollToContentTop(mainContent);
  };

  const handleReset = () => {
    setActiveStep(0);
    scrollToContentTop(mainContent);
  };

  useEffect(() => {
    scrollToContentTop(mainContent);
    setQuery(queryString.parse(location.search));

    if (slug) {
      getPlanBySlug(slug)
        .then((plan) => {
          setQuery({ ...query, plan: plan._id });
        })
        .catch(console.log);
    }
  }, [slug]);

  return (
    <div ref={mainContent}>
      <Formik
        enableReinitialize
        initialValues={{
          coverageType: 'couple',
          plan: query.plan ? query.plan : '',
          age1: query.age1 ? query.age1 : '',
          dob1: query.dob1 ? dbToDate(query.dob1) : '',
          startDate1:
            query.startDate1 && dbToDate(query.startDate1) !== ''
              ? dbToDate(query.startDate1)
              : moment().format(process.env.REACT_APP_DATE_FORMAT),
          endDate1:
            query.endDate1 && dbToDate(query.endDate1) !== ''
              ? dbToDate(query.endDate1)
              : moment()
                  .add(89, 'days')
                  .format(process.env.REACT_APP_DATE_FORMAT),
          days1:
            query.startDate1 &&
            query.endDate1 &&
            dbToDate(query.endDate1) !== '' &&
            dbToDate(query.endDate1) !== ''
              ? moment(
                  dbToDate(query.endDate1),
                  process.env.REACT_APP_DATE_FORMAT
                ).diff(
                  moment(
                    dbToDate(query.startDate1),
                    process.env.REACT_APP_DATE_FORMAT
                  ),
                  'days'
                ) + 1
              : 90,
          coverage1: query.coverage1 ? query.coverage1 : '50000',
          havePreConditions1: query.havePreConditions1
            ? query.havePreConditions1
            : '',
          age2: query.age2 ? query.age2 : '',
          dob2: query.dob2 ? dbToDate(query.dob2) : '',
          startDate2:
            query.startDate2 && dbToDate(query.startDate2) !== ''
              ? dbToDate(query.startDate2)
              : moment().format(process.env.REACT_APP_DATE_FORMAT),
          endDate2:
            query.endDate2 && dbToDate(query.endDate2) !== ''
              ? dbToDate(query.endDate2)
              : moment()
                  .add(89, 'days')
                  .format(process.env.REACT_APP_DATE_FORMAT),
          days2:
            query.startDate2 &&
            query.endDate2 &&
            dbToDate(query.endDate2) !== '' &&
            dbToDate(query.endDate2) !== ''
              ? moment(
                  dbToDate(query.endDate2),
                  process.env.REACT_APP_DATE_FORMAT
                ).diff(
                  moment(
                    dbToDate(query.startDate2),
                    process.env.REACT_APP_DATE_FORMAT
                  ),
                  'days'
                ) + 1
              : 90,
          // coverage2 will be filled based on coverage1 on form1 submit
          coverage2: query.coverage2 ? query.coverage2 : '',
          havePreConditions2: query.havePreConditions2
            ? query.havePreConditions2
            : ''
        }}
        validationSchema={getStepValidationSchema(activeStep)}
        onSubmit={(values, { setSubmitting, setFieldValue, setTouched }) => {
          if (activeStep === 0) {
            if (values.coverage2 === '') {
              setFieldValue('coverage2', values.coverage1);
            }
            setTouched({});

            setSubmitting(false);

            handleNext();
          } else {
            setSubmitting(false);
            const url = slug
              ? `/visitors-insurance/quotes/${slug}`
              : '/visitors-insurance/quotes';
            history.push(
              `${url}?${toQueryString({
                ...values,
                startDate1: dateToDb(values.startDate1),
                startDate2: dateToDb(values.startDate2),
                endDate1: dateToDb(values.endDate1),
                endDate2: dateToDb(values.endDate2),
                dob1: values.dob1 ? dateToDb(values.dob1) : '',
                dob2: values.dob2 ? dateToDb(values.dob2) : ''
              })}`
            );
          }
        }}>
        {(props) => (
          <Form>
            <div className={classes.root}>
              <div className={classes.stepperMargin}>
                <Stepper
                  activeStep={activeStep}
                  classes={{ root: classes.stepperRoot }}>
                  {steps.map((label) => {
                    const stepProps = {};
                    const labelProps = {};
                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel
                          classes={{
                            labelContainer: classes.stepLabelContainer
                          }}
                          {...labelProps}>
                          {label}
                        </StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              </div>
              <div>
                {activeStep === steps.length ? (
                  <div>
                    <Typography className={classes.instructions}>
                      All steps completed - you&apos;re finished
                    </Typography>
                    <Button onClick={handleReset} className={classes.button}>
                      Reset
                    </Button>
                  </div>
                ) : (
                  <div>
                    <div className={classes.instructions}>
                      {getStepContent(activeStep, props)}
                    </div>
                    <div>
                      <Button
                        className={classes.button}
                        color="primary"
                        size="large"
                        style={{ width: '100%' }}
                        type="submit"
                        variant="contained">
                        {activeStep === steps.length - 1
                          ? 'Get Quotes'
                          : 'Next to 2nd Applicant'}
                      </Button>
                      <div className={classes.back}>
                        <Button
                          className={classes.button}
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          startIcon={<ArrowBackIcon />}>
                          Back
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <FormikErrorFocus
              // See scroll-to-element for configuration options: https://www.npmjs.com/package/scroll-to-element
              offset={0}
              align={'top'}
              focusDelay={200}
              ease={'linear'}
              duration={500}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default VisitorCouple;
