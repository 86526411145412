import React from 'react';
import { Button, FormLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { NoOfDependents, DOBAge, Duration, CoverageType } from 'components';
import { useFormik } from 'formik';
import config from 'common/config';
import { schema } from './schema';
import { addDays } from 'date-fns';
import startOfDay from 'date-fns/startOfDay';
import { getQS, setQS, datesToQS, QSToDates, getController } from 'helpers';

const useStyles = makeStyles({
  dobAgeLabel: {
    marginBottom: 15
  }
});

const todayDate = startOfDay(new Date());
const dateFields = ['dob1', 'dob2', 'startDate1', 'endDate1'];

function InternationalStudentInsurance({ quotePath }) {
  const classes = useStyles();
  const formik = useFormik({
    initialValues: {
      insuranceType: 'student',
      coverageType: 'single',
      dob1: null,
      dob2: null,
      age1: 25,
      age2: 25,
      startDate1: todayDate,
      endDate1: addDays(
        todayDate,
        config.internationalStudent.days.default - 1
      ),
      days1: config.internationalStudent.days.default,
      dependents: 0,
      ...getQS((data) => {
        return QSToDates(data, dateFields);
      })
    },
    validationSchema: schema,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      window.location.href = `${
        quotePath
          ? quotePath
          : getController(window.location.pathname) + '/quotes'
      }?${setQS(datesToQS(values, dateFields))}#quote-result`;
    }
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="frm-grp">
        <CoverageType
          formik={formik}
          options={config.policyTypes.internationalStudent}
          insuranceType="student"
        />
      </div>
      <div className="frm-grp">
        <FormLabel component="legend" className={classes.dobAgeLabel}>
          Applicants Date of Birth or Age
        </FormLabel>
        <DOBAge formik={formik} />
      </div>
      {(formik.values.coverageType === 'couple' ||
        formik.values.coverageType === 'family') && (
        <div className="frm-grp">
          <FormLabel component="legend" className={classes.dobAgeLabel}>
            Spouse Date of Birth or Age
          </FormLabel>
          <DOBAge formik={formik} fieldNo="2" nameDob="dob2" nameAge="age2" />
        </div>
      )}
      {formik.values.coverageType === 'family' && (
        <NoOfDependents formik={formik} />
      )}
      <div className="frm-grp">
        <Duration formik={formik} />
      </div>
      {formik.values.coverageType === 'couple' && formik.values.differentDates && (
        <div className="frm-grp">
          <Duration
            formik={formik}
            nameStart="startDate2"
            nameEnd="endDate2"
            nameDays="days2"
            fieldNo="2"
          />
        </div>
      )}
      <div className="frm-grp">
        <Button type="submit" variant="contained" color="primary">
          {formik.isSubmitting ? 'SUBMITTING' : 'GET QUOTE'}
        </Button>
      </div>
    </form>
  );
}

export default InternationalStudentInsurance;
