import React, { useState, useEffect, useRef } from 'react';
import { Formik, Form, Field } from 'formik';
import FormikErrorFocus from 'formik-error-focus';
import { makeStyles } from '@material-ui/styles';
import {
  IconButton,
  Button,
  Grid,
  TextField as MuiTextField,
  Typography
} from '@material-ui/core';
import InputMask from 'react-input-mask';
import { TextField } from 'formik-material-ui';
import { camelCase } from 'lodash';
import { Quote, SendQuote } from 'components';
import { dateToDb, toQueryString } from 'common/Helper';
import submitSchema from 'schemas/planSubmit';
import * as googleApi from 'api/google';
import * as userApi from 'api/userApi';
import rateApi from 'api/rateApi';
import * as clientApi from 'api/clientApi';
import * as planDetailApi from 'api/planDetailApi';
import * as planMetaApi from 'api/planMetaApi';
import { PlanDetails, PhoneFormat } from 'components';
import { Email } from './components';
import queryString from 'query-string';
import { STORAGE_URL } from 'common/Constants';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import * as quoteRequestApi from '../../api/quoteRequestApi';
import EditIcon from '@material-ui/icons/Edit';
import { DEFAULT_FORM_URLS } from 'common/Constants';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  formRow: {
    paddingBottom: theme.spacing(4)
  },
  contact: {
    marginBottom: theme.spacing(3)
  },
  quoteClm: {
    textAlign: 'center'
  },
  planImg: {
    maxWidth: '100%',
    height: 'auto'
  },
  price: {
    textAlign: 'center'
  },
  formIntro: {
    marginBottom: theme.spacing(4)
  },
  brandImg: {
    marginBottom: theme.spacing(3)
  },
  quoteCtaWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  buyDirectBtn: {
    marginLeft: theme.spacing(2)
  },
  callUsBtn: {
    margin: theme.spacing(3, 0)
  },
  sendQuoteRoot: {
    marginBottom: theme.spacing(4)
  }
}));

const scrollToContentTop = (ref) => {
  if (window) {
    window.scrollTo(0, ref.current.offsetTop);
  }
};

const AffiliateUrl = ({ affiliates, plan, classes }) => {
  let item;

  for (let i = 0; i < affiliates.length; i++) {
    if (affiliates[i].planId === plan) {
      item = affiliates[i];
    }
  }

  if (item && item.url) {
    return (
      <div className={clsx(classes.buyDirectBtn, 'buy-direct-btn')}>
        <Button
          className={clsx('cta-buy-directly')}
          color="secondary"
          href={item.url}
          variant="contained">
          Buy Directly
        </Button>
      </div>
    );
  } else {
    return <></>;
  }
};

const PlanSubmit = ({ location }) => {
  const [rate, setRate] = useState({});
  const [planMeta, setPlanMeta] = useState(null);
  const [planDetails, setPlanDetails] = useState(null);
  const [geoAddress, setGeoAddress] = useState(null);
  const [formattedAddress, setFormattedAddress] = useState('');
  const [client, setClient] = useState();
  const mainContent = useRef(null);
  const [query, setQuery] = useState({});
  const classes = useStyles();

  const getRate = (rates, deductible) => {
    let rate = rates.filter((rate) => {
      return rate.deductible === parseInt(deductible);
    });

    if (rate.length) {
      return rate[0];
    } else {
      return undefined;
    }
  };

  const getRateStudent = (rates) => {
    let rate = rates.filter((rate) => {
      return rate.plan._id === query.plan;
    });

    if (rate.length) {
      return rate[0];
    } else {
      return undefined;
    }
  };

  const handleSendQuote = (values) => {
    return rateApi.sendQuote({
      ...values,
      url: window.location.href
    });
  };

  const handleClickEdit = () => {
    if (query) {
      const { plan, planName, ...rest } = query;
      window.location = `${
        DEFAULT_FORM_URLS[query.insuranceType]
      }/quotes?${toQueryString(rest)}`;
    }
  };

  useEffect(() => {
    scrollToContentTop(mainContent);
    setQuery(queryString.parse(window.location.search));
  }, []);

  useEffect(() => {
    googleApi
      .getLocation()
      .then((res) => {
        // get details from location
        googleApi
          .getGeocode(res.location.lat, res.location.lng)
          .then((res) => {
            if (
              res.results &&
              res.results.length &&
              res.results[0].address_components
            ) {
              setGeoAddress(res.results[0].address_components);
              setFormattedAddress(res.results[0].formatted_address);
            }
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));

    scrollToContentTop(mainContent);
  }, []);

  useEffect(() => {
    // TO-DO implement a query validation
    if (query && query.insuranceType && query.coverageType) {
      rateApi[
        camelCase(
          `get_${query.insuranceType}${
            query.insuranceType === 'student' ? '' : '_' + query.coverageType
          }_rates`
        )
      ]({
        coverageType: query.coverageType,
        plan: query.plan,
        deductible: query.deductible,
        age1: query.age1,
        age2: query.age2,
        dob1: query.dob1,
        dob2: query.dob2 ? query.dob2 : '',
        startDate1: query.startDate1 ? query.startDate1 : '',
        startDate2: query.startDate2 ? query.startDate2 : '',
        endDate1: query.endDate1 ? query.endDate1 : '',
        endDate2: query.endDate2 ? query.endDate2 : '',
        days1: query.days1,
        days2: query.days2,
        coverage1: query.coverage1,
        coverage2: query.coverage2,
        havePreConditions1: query.havePreConditions1,
        havePreConditions2: query.havePreConditions2,
        dependents: query.dependents
      })
        .then((rates) => {
          let rate =
            query.insuranceType === 'student'
              ? getRateStudent(rates)
              : getRate(rates, query.deductible);
          setRate(rate);

          if (rate.plan.planDetailsId) {
            // get plan details
            planDetailApi
              .getPlanDetail(rate.plan.planDetailsId)
              .then((data) => setPlanDetails(data))
              .catch((err) => console.log(err));

            // get plan meta
            planMetaApi
              .getPlanMeta(
                query.plan,
                query.insuranceType === 'student' ? 'student' : 'visitor'
              )
              .then((data) => {
                setPlanMeta(data);
              })
              .catch((err) => {
                console.log(err);
                toast.error(
                  'Network error: could not retrieve plan meta, please try again or contact support!'
                );
              });
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(
            'Network error: could not retrieve rates, please try again or contact support!'
          );
        });

      // get client details
      clientApi
        .getClient()
        .then((client) => {
          setClient(client);
        })
        .catch((err) => {
          console.log(err);
          toast.error(
            'Network error: could not retrieve client, please try again or contact support!'
          );
        });
    }
  }, [query]);

  return (
    <div ref={mainContent}>
      <div className={classes.root}>
        <div className={classes.sendQuoteRoot}>
          <SendQuote
            onSendQuote={handleSendQuote}
            title="Email/Text Details"
            client={client}
          />
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            {rate && rate.plan && (
              <div className={clsx(classes.quoteClm, 'quote-clm')}>
                {rate.plan.name && (
                  <>
                    <IconButton onClick={handleClickEdit} color="secondary">
                      <EditIcon />
                    </IconButton>
                    <div className={clsx(classes.brandImg, 'brand-img')}>
                      <img
                        src={`${STORAGE_URL}/images/partners/${rate.plan.slug}.png`}
                        alt={rate.plan.name}
                        className={classes.planImg}
                      />
                    </div>
                  </>
                )}

                <div className={clsx('quote-cta-wrap', classes.quoteCtaWrap)}>
                  <div className="quote-cost">
                    <Quote
                      cost={rate.cost}
                      cost1={rate.cost1}
                      cost2={rate.cost2}
                      costMonthly={rate.costMonthly}
                      coverageType={query.coverageType}
                      coupleDiscount={rate.plan.coupleDiscount}
                      days1={query.days1}
                      days2={query.days2}
                      deductible={rate.deductible}
                      haveMonthlyPlans={rate.plan.haveMonthlyPlans}
                      insuranceType={query.insuranceType}
                      slug={rate.plan.slug}
                    />
                  </div>

                  {client &&
                    client.affiliates &&
                    client.affiliates &&
                    client.affiliates.length && (
                      <AffiliateUrl
                        affiliates={client.affiliates}
                        plan={query.plan}
                        classes={classes}
                      />
                    )}
                </div>

                {client && client.cellNo && (
                  <div className={clsx(classes.callUsBtn, 'call-us-btn')}>
                    <Button
                      variant="outlined"
                      clsx={'cta-client-phone'}
                      href={`tel:${client.cellNo}`}
                      startIcon={<PhoneIphoneIcon />}>
                      <PhoneFormat number={client.cellNo} />
                    </Button>
                  </div>
                )}
              </div>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <div className="planSubmit planSubmitForm">
              <Typography variant="body2" className={classes.formIntro}>
                To begin your application process, please enter your contact
                details
              </Typography>
              <Formik
                enableReinitialize
                initialValues={{
                  contact: '',
                  phone: '',
                  email: '',
                  ...query
                }}
                validationSchema={submitSchema}
                onSubmit={(values, { setSubmitting }) => {
                  const emailBody = Email({
                    ...values,
                    planName: rate && rate.plan && rate.plan.name,
                    location: formattedAddress,
                    website: window.location.origin,
                    search: window.location.href
                  });

                  Promise.all([
                    userApi.sendEmailAdmin({
                      subject: `step1 quote request from ${values.contact} ${values.phone}`,
                      message: emailBody,
                      messageType: 'html'
                    }),
                    userApi.sendSmsAdmin({
                      message: `step1 quote request from ${values.contact} ${values.phone}`
                    })
                  ])
                    .then(() => {
                      setSubmitting(false);
                      localStorage.setItem(
                        'contact',
                        JSON.stringify({
                          name: values.contact,
                          phone: values.phone,
                          email: values.email
                        })
                      );

                      const redirectURL = `/order/?${toQueryString({
                        ...values,
                        origin: geoAddress ? geoAddress[5].short_name : ''
                      })}`;

                      quoteRequestApi
                        .post({
                          clientId: client._id,
                          name: values.contact,
                          phone: values.phone.replace(/[^0-9]/g, ''),
                          email: values.email,
                          url: window.location.href
                        })
                        .then(() => {
                          window.location.href = redirectURL;
                        })
                        .catch((err) => {
                          console.error(err);
                          window.location.href = redirectURL;
                        });
                    })
                    .catch((err) => {
                      setSubmitting(false);
                      console.log(err);
                      toast.error(
                        'Network error: Request submission failed, please try again or contact support!'
                      );
                    });
                }}>
                {({ errors, touched, handleChange, values }) => (
                  <Form>
                    <div className={classes.formRow}>
                      <Field
                        component={TextField}
                        fullWidth
                        label="Your Name"
                        name="contact"
                        variant="outlined"
                      />
                    </div>

                    <div className={classes.formRow}>
                      <InputMask
                        error={errors.phone && touched.phone}
                        fullWidth
                        helperText={
                          errors.phone && touched.phone && errors.phone
                        }
                        label="Phone number"
                        mask={process.env.REACT_APP_PHONE_MASK_FORMAT}
                        maskPlaceholder={process.env.REACT_APP_PHONE_FORMAT}
                        id="phone"
                        name="phone"
                        onChange={handleChange}
                        value={values.phone}
                        variant="outlined">
                        <MuiTextField />
                      </InputMask>
                    </div>

                    <div className={classes.formRow}>
                      <Field
                        component={TextField}
                        fullWidth
                        label="Email address"
                        name="email"
                        variant="outlined"
                      />
                    </div>

                    <div className={classes.formRow}>
                      <Button
                        color="secondary"
                        disableElevation
                        size="large"
                        style={{ width: '100%' }}
                        type="submit"
                        variant="contained">
                        Continue
                      </Button>
                    </div>
                    <FormikErrorFocus
                      // See scroll-to-element for configuration options: https://www.npmjs.com/package/scroll-to-element
                      offset={0}
                      align={'top'}
                      focusDelay={200}
                      ease={'linear'}
                      duration={500}
                    />
                  </Form>
                )}
              </Formik>
            </div>
          </Grid>
        </Grid>

        <div className="planSubmit planDetails">
          {planDetails && (
            <PlanDetails planDetails={planDetails} planMeta={planMeta} />
          )}
        </div>
      </div>
    </div>
  );
};

export default PlanSubmit;
