import React from 'react';
import { Radio, RadioGroup, FormControlLabel } from '@material-ui/core';
import config from 'common/config';
import clsx from 'clsx';

const CoverMedicalConditions = ({ formik, fieldNo = '1', name }) => (
  <div className="fld-row">
    <div
      className={clsx(
        'fld-no',
        'fld-no-sm',
        `fld-no-${fieldNo}`,
        formik.values.coverageType === 'couple' && 'active'
      )}>
      {fieldNo}
    </div>
    <RadioGroup
      aria-label="havePreConditions"
      defaultValue="right"
      name={name}
      onChange={formik.handleChange}
      row
      value={
        formik.values[name] === true ||
        formik.values[name] === 'true' ||
        formik.values[name] === '1'
          ? '1'
          : '0'
      }>
      {Object.keys(config.YesNo).map((key) => (
        <FormControlLabel
          key={key}
          control={<Radio color="primary" />}
          label={config.YesNo[key]}
          value={key}
        />
      ))}
    </RadioGroup>
  </div>
);

export default CoverMedicalConditions;
