import { fade, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(3)
  },
  formRowCompact: {
    paddingBottom: theme.spacing(2)
  },
  formRow: {
    paddingBottom: theme.spacing(4)
  },
  formRowHelp: {
    paddingBottom: theme.spacing(5),
    paddingRight: theme.spacing(6),
    position: 'relative'
  },
  divider: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  dFlex: {
    display: 'flex'
  },
  helpBtn: {
    position: 'absolute',
    right: 0,
    top: theme.spacing(2)
  },
  helpContent: {
    padding: theme.spacing(1),
    backgroundColor: fade(theme.palette.primary.light, 0.4),
    color: theme.palette.black
  },
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2)
  }
}));

export default useStyles;
