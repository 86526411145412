import * as yup from 'yup';
import config from '../../common/config';
import subYears from 'date-fns/subYears';
import format from 'date-fns/format';
import addDays from 'date-fns/addDays';
import startOfDay from 'date-fns/startOfDay';
import isValid from 'date-fns/isValid';

const minDOBDate = subYears(new Date(), config.superVisa.age.lessThan);
const maxDOBDate = subYears(new Date(), config.superVisa.age.moreThan);
const minStartDate = startOfDay(new Date());

const single = yup.object().shape({
  coverageType: yup.string().required(),
  dob1: yup
    .date()
    .nullable()
    .min(
      minDOBDate,
      `must be later than ${format(minDOBDate, config.dateFormat)}`
    )
    .max(
      maxDOBDate,
      `must be less than ${format(maxDOBDate, config.dateFormat)}`
    )
    .typeError('Enter a valid date in DD-MM-YYYY format'),
  age1: yup
    .number()
    .required('age is required')
    .moreThan(config.superVisa.age.moreThan)
    .lessThan(config.superVisa.age.lessThan),
  startDate1: yup
    .date()
    .required()
    .min(
      minStartDate,
      `min date should be ${format(minStartDate, config.dateFormat)}`
    )
    .typeError('Enter a valid date in DD-MM-YYYY format'),
  endDate1: yup
    .date()
    .required('end date is required')
    .when('startDate1', (startDate1, schema) => {
      if (startDate1 && isValid(startDate1)) {
        let minEndDate = addDays(startDate1, config.superVisa.days.min - 1);
        return schema.min(
          minEndDate,
          `End date should be greater than or equal to ${format(
            minEndDate,
            config.dateFormat
          )}`
        );
      }
    })
    .typeError('Enter a valid date in DD-MM-YYYY format'),
  days1: yup.number().min(config.superVisa.days.min).typeError('Invalid days')
});

const person2 = yup.object().shape({
  dob2: yup
    .date()
    .nullable()
    .min(
      minDOBDate,
      `must be later than ${format(minDOBDate, config.dateFormat)}`
    )
    .max(
      maxDOBDate,
      `must be less than ${format(maxDOBDate, config.dateFormat)}`
    )
    .typeError('Enter a valid date in DD-MM-YYYY format'),
  age2: yup
    .number()
    .required('age(2) is required')
    .moreThan(config.superVisa.age.moreThan)
    .lessThan(config.superVisa.age.lessThan),
  startDate2: yup
    .date()
    .min(minStartDate)
    .required()
    .typeError('Enter a valid date in DD-MM-YYYY format'),
  endDate2: yup
    .date()
    .required()
    .when('startDate2', (startDate2, schema) => {
      if (startDate2 && isValid(startDate2)) {
        let minEndDate2 = addDays(startDate2, config.superVisa.days.min - 1);
        return schema.min(
          minEndDate2,
          `End date should be greater than or equal to ${format(
            minEndDate2,
            config.dateFormat
          )}`
        );
      }
    })
    .typeError('Enter a valid date in DD-MM-YYYY format'),
  days2: yup.number().min(config.superVisa.days.min).typeError('Invalid days')
});

const couple = single.concat(person2);

const schema = yup.lazy((values) => {
  if (values.coverageType === 'single') {
    return single;
  } else {
    return couple;
  }
});

export { single, couple, schema };
