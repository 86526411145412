const getController = (str) => {
  if (!str) {
    return;
  }

  const result = str.match(/^\/[\w-]*/g);

  if (Array.isArray(result) && result.length > 0) {
    return result[0];
  }
};

export default getController;
