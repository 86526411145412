import { handleResponse, handleError } from './apiUtils';

const baseUrl = process.env.REACT_APP_API_URL + '/plans';

export function getPlan(id) {
  return fetch(`${baseUrl}/${id}`).then(handleResponse).catch(handleError);
}

export function getPlanBySlug(slug) {
  return fetch(`${baseUrl}/slug/${slug}`)
    .then(handleResponse)
    .catch(handleError);
}
