import {
  singleSchema as superSingleSchema,
  coupleSchema as superCoupleSchema
} from './super';

import {
  singleSchema as visitorSingleSchema,
  coupleSchema as visitorCoupleSchema,
  familySchema as visitorFamilySchema
} from './visitor';
import { studentSchema } from './student';

import { coupleApplicant1Schema as superCoupleApplicant1Schema } from './super';
import { coupleApplicant2Schema as superCoupleApplicant2Schema } from './super';

import { coupleApplicant1Schema as visitorCoupleApplicant1Schema } from './visitor';
import { coupleApplicant2Schema as visitorCoupleApplicant2Schema } from './visitor';

import { familySchemaStep1 as visitorFamilySchemaStep1 } from './visitor';
import { familySchemaStep2 as visitorFamilySchemaStep2 } from './visitor';

export { superSingleSchema };
export { superCoupleSchema };
export { visitorSingleSchema };
export { visitorCoupleSchema };
export { visitorFamilySchema };
export { superCoupleApplicant1Schema };
export { superCoupleApplicant2Schema };
export { visitorCoupleApplicant1Schema };
export { visitorCoupleApplicant2Schema };
export { visitorFamilySchemaStep1 };
export { visitorFamilySchemaStep2 };
export { studentSchema };

export default {
  superSingleSchema,
  superCoupleSchema,
  visitorSingleSchema,
  visitorCoupleSchema,
  visitorFamilySchema,
  studentSchema
};
