import React from 'react';
import { Link } from '@material-ui/core';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutline from '@material-ui/icons/RemoveCircleOutline';

const DifferentDateBtn = ({ formik }) => {
  const { values, setFieldValue } = formik;

  return (
    <div className="frm-grp">
      {values.coverageType === 'couple' && values.differentDates && (
        <Link
          className="text-icon"
          href="#"
          onClick={(e) => {
            e.preventDefault();
            setFieldValue('differentDates', false);
            setFieldValue('startDate2', values.start_date);
            setFieldValue('endDate2', values.end_date);
            setFieldValue('days2', values.days);
          }}>
          <RemoveCircleOutline fontSize="small" /> Make dates same for all
          applicants
        </Link>
      )}
      {values.coverageType === 'couple' && !values.differentDates && (
        <Link
          className="text-icon"
          href="#"
          onClick={(e) => {
            e.preventDefault();
            setFieldValue('differentDates', true);
          }}>
          <AddCircleOutline fontSize="small" /> Enter different dates per
          applicant
        </Link>
      )}
    </div>
  );
};

export default DifferentDateBtn;
