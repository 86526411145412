import React from 'react';
import {
  SuperSingle,
  SuperCouple,
  VisitorSingle,
  VisitorCouple,
  VisitorFamily
} from './components';

const quoteModules = {
  super_single: SuperSingle,
  super_couple: SuperCouple,
  visitor_single: VisitorSingle,
  visitor_couple: VisitorCouple,
  visitor_family: VisitorFamily,
  student_single: VisitorSingle,
  student_couple: VisitorSingle,
  student_family: VisitorSingle
};

const Quote = (props) => {
  const { insuranceType, coverageType } = props;

  if (quoteModules[`${insuranceType}_${coverageType}`] !== undefined) {
    return React.createElement(
      quoteModules[`${insuranceType}_${coverageType}`],
      props
    );
  } else {
    return <>invalid Module</>;
  }
};

export default Quote;
