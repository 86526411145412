import format from 'date-fns/format';
import isValid from 'date-fns/isValid';

const datesToQS = (data, dates = [], pattern = 'yyyy-MM-dd') => {
  for (let key in data) {
    if (data.hasOwnProperty(key) && dates.includes(key) && isValid(data[key])) {
      data[key] = format(data[key], pattern);
    }
  }
  return data;
};

export default datesToQS;
