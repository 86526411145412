import { handleResponse, handleError } from './apiUtils';

const baseUrl = process.env.REACT_APP_API_URL + '/plan-meta';

export function getPlanMeta(planId, type) {
  return fetch(`${baseUrl}/${type}/${planId}`)
    .then(handleResponse)
    .catch(handleError);
}

export function getPlansMeta(planIds, type) {
  return fetch(`${baseUrl}/${type}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(planIds)
  })
    .then(handleResponse)
    .catch(handleError);
}
