import queryString from 'query-string';

const getQS = (cb = null) => {
  let data = queryString.parse(window.location.search);

  if (cb) {
    return cb(data);
  } else {
    return data;
  }
};

export default getQS;
