import React from 'react';
import { STORAGE_URL } from 'common/Constants';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#eee',
    padding: theme.spacing(1),
    position: 'fixed',
    zIndex: '2000',
    left: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap'
  },
  list: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    listStyle: 'none',
    justifyContent: 'center',
    padding: 0,
    margin: 0,

    '& > li': {
      padding: theme.spacing(0, '4px')
    }
  },
  img: {
    maxWidth: '100px',
    height: 'auto'
  }
}));

const CompareList = ({ items, onCompareClick }) => {
  const classes = useStyles();

  return (
    <>
      {items.length ? (
        <div className={clsx(classes.root, 'compare-list')}>
          <ul className={clsx(classes.list)}>
            {items.map((item) => (
              <li key={item.plan._id}>
                <img
                  src={`${STORAGE_URL}/images/partners/${item.plan.slug}.png`}
                  alt={item.plan.name}
                  className={classes.img}
                />
              </li>
            ))}
          </ul>
          <Button
            color="primary"
            onClick={() => {
              onCompareClick(true);
            }}
            variant="outlined">
            Compare
          </Button>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default CompareList;
