import React from 'react';
import { Amount } from 'components';
import useStyles from '../style';

const VisitorFamily = (props) => {
  const classes = useStyles();

  return (
    <>
      <Amount number={props.cost} classes={classes} />
    </>
  );
};

export default VisitorFamily;
